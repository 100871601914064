import { RouteInterface } from "globals";
import { HomeLayout } from "layouts";
import FacilitiesProfile from "../FacilitiesProfileDetailEdit";
import Profile from "../ProfileDetailEdit";
import { ProfileRoutePath, ProfileEditRoutePath, FacilitiesProfileRoutePath, FacilitiesProfileEditRoutePath } from "./profile-route-path";
export const ProfileRoutesList: RouteInterface[] = [
    {
        component: Profile,
        exact: true,
        layout: HomeLayout,
        path: ProfileRoutePath.path,
        uniqueKey: ProfileRoutePath.key,
    },
    {
        component: Profile,
        exact: true,
        layout: HomeLayout,
        path: ProfileEditRoutePath.path,
        uniqueKey: ProfileEditRoutePath.key,
    },
    {
        component: FacilitiesProfile,
        exact: true,
        layout: HomeLayout,
        path: FacilitiesProfileRoutePath.path,
        uniqueKey: FacilitiesProfileRoutePath.key,
    }, {
        component: FacilitiesProfile,
        exact: true,
        layout: HomeLayout,
        path: FacilitiesProfileEditRoutePath.path,
        uniqueKey: FacilitiesProfileEditRoutePath.key,
    },
];
