import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import AppTextMarker from "components/Labels/AppTextMarker";
import { COLOR_SECONDARY } from "theme";
import React from "react";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        personalTitle: {
            marginRight: theme.spacing(1),
        },
    }),
);
export interface AppTitleWithMarkerProps {
    firstTitle: string;
    secondTitle: string;
    markerPosition?: "first" | "second";
}
export const AppTitleWithMarker: React.FC<AppTitleWithMarkerProps> = ({
    firstTitle,
    secondTitle,
    markerPosition = "second",
}) => {
    const classes = useStyles();
    return (
        <div style={{ display: "flex" }}>
            {markerPosition === "first" ? (
                <AppTextMarker
                    padding="0px 5px 0px 5px"
                    style={{ marginRight: "5px" }}
                    bgClr={COLOR_SECONDARY}>
                    <Typography variant="h5">{firstTitle}</Typography>
                </AppTextMarker>
            ) : (
                <Typography className={classes.personalTitle} variant="h5">
                    {firstTitle}
                </Typography>
            )}
            {markerPosition === "second" ? (
                <AppTextMarker
                    padding="0px 5px 0px 5px"
                    bgClr={COLOR_SECONDARY}>
                    <Typography variant="h5">{secondTitle}</Typography>
                </AppTextMarker>
            ) : (
                <Typography variant="h5">{secondTitle}</Typography>
            )}
        </div>
    );
};

export default AppTitleWithMarker;
