import { Gender } from "globals/Types";
import { KindergartenRegistrationStatus } from "./KindergartenRegistrationList";

export interface KindergartenRequestModal {
    ChildId: number;
    DateOfCreation: string;
    FirstNameChild: string;
    LastNameChild: string;
    Age: number;
    Gender: Gender | null;
    SupportStart: string;
    Status: KindergartenRegistrationStatus;
}

export interface ChangeStatus {
    kindergartenRequestId: number,
    status: number,

}

export interface ChildIdAndKindergarten {
    childId: number;
    kindergartens: number[];
}

export const getInitialChildIdAndKindergarten = (): ChildIdAndKindergarten => {
    return {
        childId: 0,
        kindergartens: [0],
    };
};

export const getInitialKindergartenRequest = (): KindergartenRequestModal => {
    return {
        ChildId: 0,
        DateOfCreation: "",
        FirstNameChild: "",
        LastNameChild: "",
        Age: 0,
        Gender: null,
        SupportStart: "",
        Status: KindergartenRegistrationStatus.PENDING,
    };
};

export const ListOfKindergartenRequests: KindergartenRequestModal[] = [
    {
        ChildId: 1,
        DateOfCreation: "01.03.2021",
        FirstNameChild: "William",
        LastNameChild: "Hutchinson",
        Age: 5,
        Gender: Gender.MALE,
        SupportStart: "01.05.2021",
        Status: KindergartenRegistrationStatus.ACCEPTED,
    },
    {
        ChildId: 2,
        DateOfCreation: "01.04.2021",
        FirstNameChild: "Will",
        LastNameChild: "Hutchinson",
        Age: 4,
        Gender: Gender.MALE,
        SupportStart: "01.05.2021",
        Status: KindergartenRegistrationStatus.PENDING,
    },
    {
        ChildId: 3,
        DateOfCreation: "01.01.2021",
        FirstNameChild: "William5",
        LastNameChild: "Hutchinson",
        Age: 5,
        Gender: Gender.MALE,
        SupportStart: "05.25.2021",
        Status: KindergartenRegistrationStatus.REJECTED,
    },
    {
        ChildId: 4,
        DateOfCreation: "06.02.2021",
        FirstNameChild: "William4",
        LastNameChild: "Hutchinson",
        Age: 5,
        Gender: Gender.MALE,
        SupportStart: "11.05.2021",
        Status: KindergartenRegistrationStatus.ACCEPTED,
    },
    {
        ChildId: 5,
        DateOfCreation: "07.02.2021",
        FirstNameChild: "William3",
        LastNameChild: "Hutchinson",
        Age: 5,
        Gender: Gender.MALE,
        SupportStart: "10.05.2021",
        Status: KindergartenRegistrationStatus.ACCEPTED,
    },
    {
        ChildId: 6,
        DateOfCreation: "08.02.2021",
        FirstNameChild: "William2",
        LastNameChild: "Hutchinson",
        Age: 5,
        Gender: Gender.MALE,
        SupportStart: "10.05.2021",
        Status: KindergartenRegistrationStatus.ACCEPTED,
    },

];