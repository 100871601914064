import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { AppControlProps, SimpleOption } from "globals";
import React from "react";
import ProfileFieldsRendering from "screens/Profile/Partials/ProfileFieldsRendering";

const useStyles = makeStyles(() =>
    createStyles({
        root: { maxWidth: "400px" },
        isEditAbleSection: {
            minHeight: "60px",
            marginTop: "10px",
        },
    }),
);
export interface ProfileFiledOrTextContainerProps extends AppControlProps {
    title: string;
    value?: string;
    isEdit?: boolean;
    name: string;
    errorMessage?: string;
    fieldType: "text" | "select" | "date";
    options?: SimpleOption[];
}
export const ProfileFiledOrTextContainer: React.FC<ProfileFiledOrTextContainerProps> =
    ({
        title,
        isEdit,
        value,
        name,
        control,
        errorMessage,
        fieldType,
        options,
    }) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                <Typography
                    variant="button"
                    color={isEdit ? "textPrimary" : "textSecondary"}>
                    {title + (isEdit ? " *" : ":")}
                </Typography>
                <div className={classes.isEditAbleSection}>
                    {isEdit ? (
                        <ProfileFieldsRendering
                            name={name}
                            control={control}
                            rules={{ required: errorMessage }}
                            fieldType={fieldType}
                            options={options}
                        />
                    ) : (
                        <Typography variant="h6">
                            {value ? value : ""}
                        </Typography>
                    )}
                </div>
            </div>
        );
    };

export default ProfileFiledOrTextContainer;
