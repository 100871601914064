import { Grid, makeStyles } from "@material-ui/core";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { COLOR_BOX_SHADOW, COLOR_PRIMARY_DARK, COLOR_WHITE } from "theme";
import { toNumber } from "lodash";
import React from "react";
import { ReactComponent as DoubleArrowBlue } from "assets/images/icons/double-forward-arrow-blue.svg";
const useStyle = makeStyles({
    root: {
        backgroundColor: COLOR_WHITE,
        boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
        zIndex: 1,
        color: COLOR_PRIMARY_DARK,
        width: 50,
        height: 50,
        display: "flex",
        fontSize: "1.5rem",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        marginTop: 4,
    },
    active: {
        marginTop: 0,
        width: 60,
        height: 60,
        backgroundColor: COLOR_PRIMARY_DARK,
        color: COLOR_WHITE,
        boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
        borderRadius: "10px 10px 0px 0px /10px 10px 0px 0px",
    },
    lastElement: {
        marginTop: 0,
        width: 60,
        height: 60,
        backgroundColor: COLOR_PRIMARY_DARK,
        color: COLOR_WHITE,
        boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
        borderRadius: "10px 10px 10px 0px /10px 10px 10px 0px",
    },
    firstElement: {
        width: 60,
        marginTop: 0,
        height: 60,
        backgroundColor: COLOR_PRIMARY_DARK,
        color: COLOR_WHITE,
        boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
        borderRadius: "10px 10px 0px 10px /10px 10px 0px 10px",
    },
    doubleArrowImage: { width: "1rem", height: "1rem", marginTop: "1rem" },
});

export interface AppStepperIconProps extends StepIconProps {}
export const AppStepperIcon = React.memo<AppStepperIconProps>(
    ({ icon, active, completed, id }) => {
        const classes = useStyle();
        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Grid
                    item
                    className={`${classes.root}
                    
                   ${
                       icon === 1
                           ? classes.firstElement
                           : icon === toNumber(id) && (active || completed)
                           ? classes.lastElement
                           : active
                           ? classes.active
                           : completed
                           ? classes.active
                           : ""
                   }`}>
                    {icon}
                </Grid>
                {active && (
                    <Grid item>
                        <DoubleArrowBlue className={classes.doubleArrowImage} />
                    </Grid>
                )}
            </Grid>
        );
    },
);

export default AppStepperIcon;
