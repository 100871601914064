import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import SelectAllBg from "assets/images/bg/select-all-results-bg.png";
import { ReactComponent as SelectAllIcon } from "assets/images/icons/select-all.svg";
import { AppButton, AppContainerWithStripeBorder } from "components";
import { AppDialog } from "components/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    SelectAllKindergartenCountWithText,
    SelectAllResultsServices,
} from "./Partials";

import {
    COLOR_PRIMARY,
    COLOR_SECONDARY,
    FULL_HEIGHT_OF_DIALOG_WITHOUT_HEADER,
} from "theme";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "100%",
            overflowX: "hidden",
            minHeight: FULL_HEIGHT_OF_DIALOG_WITHOUT_HEADER,
        },
        selectAllIcon: { width: "40px", height: "40px", objectFit: "contain" },

        dottedSection: {
            backgroundColor: "#fafdff",
            marginTop: theme.spacing(9),
            [theme.breakpoints.down("lg")]: {
                marginTop: theme.spacing(4),
            },
        },
        dottedContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            paddingTop: theme.spacing(7),
            paddingBottom: theme.spacing(7),
        },
        dottedContainerContent: {
            textAlign: "center",
            //mobile
        },
        dottedContainerBody: {
            maxWidth: "320px",
            textAlign: "center",
            marginTop: theme.spacing(5),
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        stripBorder: {
            display: "flex",
            justifyContent: "center",
        },
        goldUserText: { paddingTop: theme.spacing(5) },
        continueWithPaymentAlert: {
            paddingTop: theme.spacing(5),
            [theme.breakpoints.down("lg")]: {
                paddingTop: theme.spacing(3),
            },
        },
        ourServicesContainer: {
            marginTop: theme.spacing(5),
        },
        buttonsContainer: {
            marginTop: theme.spacing(5),
            minWidth: "700px",
            maxWidth: "700px",
        },
        button: {
            minWidth: "273px",
            minHeight: "66px",
            borderRadius: "10px",
        },
        stripeIcon: {
            display: "flex",
        }
    }),
);
export interface SelectAllResultsDialogProps {
    open: boolean;
    onClose: () => void;
    totalKindergartenSelected: number;
    continueWithoutPayment: () => void;
    payWithStripe: () => void;
}
export const SelectAllResultsDialog: React.FC<SelectAllResultsDialogProps> = ({
    onClose,
    open,
    totalKindergartenSelected,
    continueWithoutPayment,
    payWithStripe,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const is4k = useMediaQuery(theme.breakpoints.up("xll"));

    return (
        <AppDialog
            open={open}
            handleClose={onClose}
            withBackgroundImage={true}
            bgImageUrl={SelectAllBg}>
            <div className={classes.root}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}>
                    <Grid item>
                        <SelectAllIcon className={classes.selectAllIcon} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4">
                            {t("selectAllResults.title")}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.stripBorder}>
                    <AppContainerWithStripeBorder
                        containerWidth="760px"
                        borderRadius={is4k ? 13 : 10}
                        strokeWidth={is4k ? 3 : 2}
                        className={classes.dottedSection}>
                        <div className={classes.dottedContainer}>
                            <div className={classes.dottedContainerContent}>
                                <Typography variant="h5">
                                    {t("selectAllResults.inYourRange") + ":"}
                                </Typography>
                                <div className={classes.dottedContainerBody}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <SelectAllKindergartenCountWithText
                                                count={
                                                    totalKindergartenSelected
                                                }
                                                text={t(
                                                    "selectAllResults.kindergartensHaveBeenFound",
                                                )}
                                                markerColor={COLOR_SECONDARY}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SelectAllKindergartenCountWithText
                                                count={5}
                                                text={
                                                    t(
                                                        "selectAllResults.areFreeToRegistrate",
                                                    ) + " *"
                                                }
                                                markerColor={COLOR_PRIMARY}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <div className={classes.goldUserText}>
                                <Typography
                                    variant="body1"
                                    color="textSecondary">
                                    {"* " + t("selectAllResults.goldUser")}
                                </Typography>
                            </div>
                        </div>
                    </AppContainerWithStripeBorder>
                </div>
                <div className={classes.ourServicesContainer}>
                    <SelectAllResultsServices />
                </div>
                <div className={classes.buttonsContainer}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <AppButton
                                variant="outlined"
                                color="primary"
                                onClick={continueWithoutPayment}
                                className={classes.button}>
                                <Typography variant="body1" color="primary">
                                    {t(
                                        "selectAllResults.continueWithoutPayment",
                                    ) + " *"}
                                </Typography>
                            </AppButton>
                        </Grid>
                        <Grid item>
                            <AppButton
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={payWithStripe}>
                                <Grid
                                    container
                                    spacing={1}
                                    justifyContent="center"
                                    alignItems="center">
                                    <Grid item>
                                        <Typography variant="body1">
                                            {t("selectAllResults.payWith")}
                                        </Typography>
                                    </Grid>
                                    <Grid item className="stripeIcon">
                                        <svg viewBox="0 0 60 25" xmlns="http://www.w3.org/2000/svg" width="60" height="25"><path fill="#0A2540" d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z"></path></svg>
                                    </Grid>
                                </Grid>
                            </AppButton>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.continueWithPaymentAlert}>
                    <Typography variant="body1" color="textSecondary">
                        {"* " +
                            t("selectAllResults.continueWithoutPaymentAlert")}
                    </Typography>
                </div>
            </div>
        </AppDialog>
    );
};

export default SelectAllResultsDialog;
