import {
    createStyles,
    Grid,
    makeStyles,
    StandardProps,
    Theme,
} from "@material-ui/core";
import VectorLine from "assets/images/vector-line-vertical.png";
import { StyleClassKey } from "globals/Types";
import React from "react";
interface LineSeparationComponentProps {
    component: React.ReactNode;
}
const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(8),
        },
        vectorImage: {
            backgroundRepeat: "repeat-y",
            marginTop: "60px",
            background: `url("${VectorLine}")`,
        },
    });

type AppContainerWithLineSeparationClassKey = StyleClassKey<typeof styles>;
const useStyles = makeStyles(styles);
export interface AppContainerWithLineSeparationProps
    extends StandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >,
        AppContainerWithLineSeparationClassKey
    > {
    components: LineSeparationComponentProps[];
}
export const AppContainerWithLineSeparation =
    React.memo<AppContainerWithLineSeparationProps>(({ components }) => {
        const classes = useStyles();

        return (
            <div>
                <Grid container>
                    <Grid xs={6} item>
                        {components[0].component}
                    </Grid>

                    <Grid xs={1} item className={classes.vectorImage} />
                    <Grid xs={5} item>
                        {components[1].component}
                    </Grid>
                </Grid>
            </div>
        );
    });

export default AppContainerWithLineSeparation;
