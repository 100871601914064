import { AppTableExpandableIcon } from "components";
import { AppTableActionMenu } from "components/Table/AppTableActionMenu";
import memoizeOne from "memoize-one";
import moment from "moment-timezone";
import { TableColumn } from "react-data-table-component";
import { TFunction } from "react-i18next";
import { RegistrationListModal } from "sdk";

export const RegistrationListColumns = memoizeOne(
    (
        t: TFunction<"translation">,
        onRowExpandClick: (id: number) => void,
        onDeleteItem: (id: number) => void,
    ): TableColumn<RegistrationListModal>[] => {
        return [
            {
                selector: () => "Date",
                name: t("registrationList.date"),
                grow: 1,
                minWidth: "200px",
                sortable: true,
                cell: (row) => (
                    <div>
                        {t("dates.dateTimeFormat", {
                            date: moment(row.Date),
                        })}
                    </div>
                ),
            },
            {
                selector: (row) => row.FirstNameChild,
                name: t("registrationList.lastName"),
                minWidth: "150px",
                grow: 1,
            },
            {
                selector: (row) => row.LastNameChild,
                name: t("registrationList.firstName"),
                grow: 1,
                minWidth: "150px",
            },
            {
                selector: (row) => row.Age,
                maxWidth: "150px",
                name: t("registrationList.age"),
                grow: 1,
                cell: (row) => <div>{row.Age + " " + t("common.years")}</div>,
            },
            {
                selector: (row) => "Gender",
                name: t("registrationList.gender"),
                width: "150px",
                cell: (row, index, column) => (
                    <div>{t(`common.genderTable.${row.Gender?.toString()}`)}</div>
                ),
            },
            {
                selector: (row) => row.Start,
                sortable: true,
                grow: 1,
                minWidth: "120px",
                name: t("registrationList.start"),
                cell: (row) => (
                    <div>
                        {t("dates.dateFormat", {
                            date: moment(row.Start),
                        })}
                    </div>
                ),
            },

            {
                sortable: false,
                width: "70px",
                cell: (params, index, column) => {
                    return <AppTableActionMenu hideEdit={true} id={params.Id as number} onDeleteItem={onDeleteItem} />;
                },
            },
            {
                width: "80px",
                cell: (row, index, column) => (
                    <AppTableExpandableIcon
                        id={row.Id as number}
                        toggle={row.RowExpandedToggle === true}
                        onRowExpandClick={onRowExpandClick}
                    />
                ),
            },
        ];
    },
);
