import { ExpandableRow } from "globals";

export enum KindergartenRegistrationStatus {
    ACCEPTED = "Accepted",
    PENDING = "Pending",
    REJECTED = "Rejected",
}

export interface MenuChildListItem {
    Firstname: string;
    Lastname: string;
    Id: number;
    Selected: boolean;
}

export enum KindergartenStatus {
    PENDING = "Pending",
    REVIEWED = "Reviewed"
}

export const getInitialMenuItem = (): MenuChildListItem => {
    return {
        Id: 0,
        Firstname: "All",
        Lastname: "",
        Selected: false
    };
};

export interface MenuStatusListItem {
    Id: number;
    Status: string;
}

export const MenuStatusListItemData: MenuStatusListItem[] = [
    {
        Id: 0,
        Status: "All"
    },
    {
        Id: 1,
        Status: "Pending"
    },
    {
        Id: 2,
        Status: "Accepted"
    },
    {
        Id: 3,
        Status: "Rejected"
    }
];

export interface ChildsListWithKindergartensRequest {
    Firstname: string;
    Lastname: string;
    Id: number;
    KindergatensList: KindergartenRegistrationListModal[];
}

export interface KindergartenRegistrationListModal extends ExpandableRow {
    Date: string;
    Kindergarten: string;
    Location: string;
    Status: KindergartenRegistrationStatus;
    Name: string;
    Email: string;
    City: string;
    PhoneNumber: string;
    Address: string;
    Info: string;
    IndexNumber: number;
    ChildId?: number;
    KindergartenId: number;

}
export const KindergartenRegistrationData: KindergartenRegistrationListModal[] =
    [
        {
            Id: 77011900,
            Date: "20-06-2021",
            Kindergarten: "Kindergarten Name",
            Location: "Hauptstraße 112, Berlin",
            Info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            Status: KindergartenRegistrationStatus.ACCEPTED,
            Name: "Kindergarten Name",
            Email: "kindergarten@mail.com",
            City: "Berlin",
            PhoneNumber: "+ 700 000 00 00",
            Address: "HauptstraBe, 112",
            IndexNumber: 12345,
            KindergartenId: 1234,
        },
        {
            Id: 71011900,
            Date: "20-06-2021",
            Kindergarten: "Kindergarten Name",
            Location: "Hauptstraße 112, Berlin",
            Info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            Status: KindergartenRegistrationStatus.PENDING,
            Name: "Kindergarten Name",
            Email: "kindergarten@mail.com",
            City: "Berlin",
            PhoneNumber: "+ 700 000 00 00",
            Address: "HauptstraBe, 112",
            IndexNumber: 12345,
            KindergartenId: 1234,
        },
        {
            Id: 77411900,
            Date: "20-06-2021",
            Kindergarten: "Kindergarten Name",
            Location: "Hauptstraße 112, Berlin",
            Info: "Description",
            Status: KindergartenRegistrationStatus.PENDING,
            Name: "Kindergarten Name",
            Email: "kindergarten@mail.com",
            City: "Berlin",
            PhoneNumber: "+ 700 000 00 00",
            Address: "HauptstraBe, 112",
            IndexNumber: 12345,
            KindergartenId: 1234,
        },
        {
            Id: 70011900,
            Date: "20-06-2021",
            Kindergarten: "Kindergarten Name",
            Location: "Hauptstraße 112, Berlin",
            Info: "Description",
            Status: KindergartenRegistrationStatus.PENDING,
            Name: "Kindergarten Name",
            Email: "kindergarten@mail.com",
            City: "Berlin",
            PhoneNumber: "+ 700 000 00 00",
            Address: "HauptstraBe, 112",
            IndexNumber: 12345,
            KindergartenId: 1234,
        },
        {
            Id: 78411900,
            Date: "20-06-2021",
            Kindergarten: "Kindergarten Name",
            Location: "Hauptstraße 112, Berlin",
            Info: "Description",
            Status: KindergartenRegistrationStatus.PENDING,
            Name: "Kindergarten Name",
            Email: "kindergarten@mail.com",
            City: "Berlin",
            PhoneNumber: "+ 700 000 00 00",
            Address: "HauptstraBe, 112",
            IndexNumber: 12345,
            KindergartenId: 1234,
        },
        {
            Id: 79411900,
            Date: "20-06-2021",
            Kindergarten: "Kindergarten Name",
            Location: "Hauptstraße 112, Berlin",
            Info: "Description",
            Status: KindergartenRegistrationStatus.PENDING,
            Name: "Kindergarten Name",
            Email: "kindergarten@mail.com",
            City: "Berlin",
            PhoneNumber: "+ 700 000 00 00",
            Address: "HauptstraBe, 112",
            IndexNumber: 12345,
            KindergartenId: 1234,
        },
    ];
