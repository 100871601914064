import { Grid } from "@material-ui/core";
import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FacilitiesProfileModel} from "sdk";
import { ProfileContainerHeader } from "./ProfileContainerHeader";
import { ProfileFiledOrTextContainer } from "./ProfileFiledOrTextContainer";
export interface FacilitiesProfileAddressInfoProps {
    control?: Control<FacilitiesProfileModel>;
    isEdit?: boolean;
    value?: FacilitiesProfileModel;
}

export const FacilitiesProfileAddressInfo: React.FC<FacilitiesProfileAddressInfoProps> = ({
    control,
    isEdit,
    value,
}) => {
    const { t } = useTranslation();
    return (
        <ProfileContainerHeader
            firstTitle={t("companyProfile.company")}
            secondTitle={t("companyProfile.location")}>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("profile.land.title")}
                    value={value?.Country}
                    fieldType="text"
                    isEdit={isEdit}
                    control={control}
                    name="Country"
                    errorMessage={t("profile.land.missing")}
                />
            </Grid>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("profile.address.title")}
                    value={value?.Address}
                    fieldType="text"
                    isEdit={isEdit}
                    control={control}
                    name="Address"
                    errorMessage={t("profile.address.missing")}
                />
            </Grid>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("profile.city.title")}
                    value={value?.City}
                    fieldType="text"
                    isEdit={isEdit}
                    control={control}
                    name="City"
                    errorMessage={t("profile.city.missing")}
                />
            </Grid>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("profile.postalCode.title")}
                    value={value?.PostalCode}
                    isEdit={isEdit}
                    fieldType="text"
                    control={control}
                    name="PostalCode"
                    errorMessage={t("profile.postalCode.missing")}
                />
            </Grid>
        </ProfileContainerHeader>
    );
};

export default FacilitiesProfileAddressInfo;
