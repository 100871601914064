import { Divider, Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {
    AppControlledDatePicker,
    AppLabelWrapper,
    AppStepperFooter,
    AppTextField
} from "components";
import { PHONE_NUMBER_MASK } from "globals/constants";
import { Moment } from "moment-timezone";
import { useUserContext } from "providers";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { appRoutesForContext } from "routing/react-route-paths";
import { MultiStepRegistrationData } from "screens/MapSearch/Partials/MapSearchSideBar";
import {
    RegistrationForParentStep3Modal,
    RegistrationForParentStepperCommonProps
} from "sdk";
import { findCareHour, findGender } from "sdk/models/MultiStepRegistration";
import { AuthService } from "services/AuthService";
import { CHILDREN_REGISTRATION_MAX_WIDTH } from "theme";


const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
        },
        fullWidth: { width: "100%" },
        body: {
            width: "100%",
            maxWidth: CHILDREN_REGISTRATION_MAX_WIDTH,
            minWidth: "1200px",
            display: "flex",
            justifyContent: "center",
        },
        fieldsContainer: {
            marginTop: theme.spacing(4),
            maxWidth: "600px",
            display: "flex",
            justifyContent: "center",
        },
        footer: {
            maxWidth: CHILDREN_REGISTRATION_MAX_WIDTH,
            minWidth: "1100px",
        },
        divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    }),
);

const service = new AuthService();

export interface RegistrationForParentStep3Props
    extends RegistrationForParentStepperCommonProps {
    handleFormCompleted: () => void;
    mainState: MultiStepRegistrationData;
    openStripe: () => void;
    open: boolean;

}
export const RegistrationForParentStep3: React.FC<RegistrationForParentStep3Props> =
    ({
        handleNext,
        parentState,
        handleStateSet,
        handleFormCompleted,
        mainState,
        openStripe,
        open,
        ...rest
    }) => {
        const classes = useStyle();
        const { t } = useTranslation();
        const { lng, setLanguage } = useUserContext();
        const history = useHistory();

        const { control, handleSubmit } =
            useForm<RegistrationForParentStep3Modal>();
        const submit = handleSubmit((data) => {
            handleStateSet({
                ...parentState,
                ...(data as RegistrationForParentStep3Modal),
            });
            console.log(mainState);
            console.log(data);
            if (rest.activeStep !== rest.stepsLength - 1) {
                handleNext();
            } else {

                const registerRequest = new FormData();

                for (let i = 0; i < mainState.kindergartens.length; i++) {
                    registerRequest.append(
                        `kindergartens[${i}]`,
                        mainState.kindergartens[i].Id.toString()
                    );
                }

                for (let i = 0; i < mainState.childrensInfo.children.length; i++) {
                    registerRequest.append(
                        `childrenData[${i}].careHour`,
                        findCareHour(mainState.childrensInfo.children[i].CareHours).toString()
                    );
                    registerRequest.append(
                        `childrenData[${i}].childBirthday`,
                        mainState.childrensInfo.children[i].BirthdayOfTheChild.format('YYYY-MM-DDTHH:mm:ss')
                    );
                    registerRequest.append(
                        `childrenData[${i}].childFirstname`,
                        mainState.childrensInfo.children[i].FirstNameChild
                    );
                    registerRequest.append(
                        `childrenData[${i}].childLastname`,
                        mainState.childrensInfo.children[i].LastNameChild
                    );
                    registerRequest.append(
                        `childrenData[${i}].childGender`,
                        findGender(mainState.childrensInfo.children[i].Gender).toString()
                    );
                    registerRequest.append(
                        `childrenData[${i}].supportStart`,
                        mainState.childrensInfo.children[i].SupportStart.format('YYYY-MM-DDTHH:mm:ss')
                    );
                    registerRequest.append(
                        `childrenData[${i}].message`,
                        mainState.childrensInfo.children[i].Message
                    );
                    const k = mainState.childrensInfo.children[i]
                    if (k!.AttachedFile) {
                        registerRequest.append(
                            `childrenData[${i}].VoucherFile`,
                            k.AttachedFile[0]
                        );
                    }
                }

                registerRequest.append('parentInfo.firstname', data.FirstNameParent);
                registerRequest.append('parentInfo.lastname', data.LastNameParent);
                registerRequest.append('parentInfo.dateOfBirth', (data.DateOfBirth as Moment).format('YYYY-MM-DDTHH:mm:ss'));
                registerRequest.append('parentInfo.phone', data.PhoneNumber);
                registerRequest.append('parentInfo.country', data.Country);
                registerRequest.append('parentInfo.city', data.City);
                registerRequest.append('parentInfo.address', data.Address);
                registerRequest.append('parentInfo.postalCode', data.PostalCode.toString());
                registerRequest.append('email', mainState.registrationForParent.Email);
                registerRequest.append('password', mainState.registrationForParent.Password);
                registerRequest.append('confirmPassword', mainState.registrationForParent.ConfirmPassword);
                //registerRequest.append('ClientConfirmationEmailURI', 'http://localhost:3000/registerSuccess/redirectPath=-home');
                registerRequest.append('ClientConfirmationEmailURI', 'https://kitaplatz-demo.azurewebsites.net/registerSuccess/redirectPath=-home');
                registerRequest.append('langCode', lng);

                service.multiStepRegistration(registerRequest)
                    .then(function (response) {
                        console.log(response);
                        if (open) {
                            openStripe();
                        } else {
                            history.push(
                                appRoutesForContext()
                                    .auth()
                                    .registerSuccessBeforeEmail
                                    .setRegisterSuccessBeforeEmailPathParams("home"),
                            );
                            handleFormCompleted();
                        }
                    })
                    .catch(function (error) {
                        console.log("API ERROR:");
                        console.log(error);
                        toast.error("Either the mail already exists or the password is not strong enough!");
                    })

            }
        });

        return (
            <div className={classes.root}>
                <form onSubmit={submit} noValidate={true}>
                    <div className={classes.body}>
                        <Grid
                            container
                            spacing={2}
                            className={clsx(
                                classes.fullWidth,
                                classes.fieldsContainer,
                            )}>
                            <Grid item xs={12}>
                                <AppLabelWrapper
                                    label={t(
                                        "registrationForParent.step3.yourFirstName.title",
                                    )}
                                    required>
                                    <AppTextField
                                        control={control}
                                        name="FirstNameParent"
                                        fullWidth
                                        placeholder={t(
                                            "registrationForParent.step3.yourFirstName.placeHolder",
                                        )}
                                        rules={{
                                            required: t(
                                                "registrationForParent.step3.yourFirstName.missing",
                                            ) as string,
                                        }}
                                    />
                                </AppLabelWrapper>
                            </Grid>
                            <Grid item xs={12}>
                                <AppLabelWrapper
                                    label={t(
                                        "registrationForParent.step3.yourLastName.title",
                                    )}
                                    required>
                                    <AppTextField
                                        control={control}
                                        name="LastNameParent"
                                        fullWidth
                                        placeholder={t(
                                            "registrationForParent.step3.yourLastName.placeHolder",
                                        )}
                                        rules={{
                                            required: t(
                                                "registrationForParent.step3.yourLastName.missing",
                                            ) as string,
                                        }}
                                    />
                                </AppLabelWrapper>
                            </Grid>
                            <Grid item xs={12}>
                                <AppLabelWrapper
                                    label={t(
                                        "registrationForParent.step3.dataOfBirth.title",
                                    )}
                                    required>
                                    <AppControlledDatePicker
                                        control={control}
                                        name="DateOfBirth"
                                        fullWidth
                                        rules={{
                                            required: t(
                                                "registrationForParent.step3.dataOfBirth.missing",
                                            ) as string,
                                        }}
                                    /*   placeholder={t(
                                          "registrationForParent.step3.dataOfBirth.placeHolder",
                                      )} */
                                    />
                                </AppLabelWrapper>
                            </Grid>
                            <Grid item xs={12} className={classes.divider}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <AppLabelWrapper
                                    label={t(
                                        "registrationForParent.step3.phoneNumber.title",
                                    )}
                                    required>
                                    <AppTextField
                                        control={control}
                                        name="PhoneNumber"
                                        fullWidth
                                        rules={{
                                            required: t(
                                                "registrationForParent.step3.phoneNumber.missing",
                                            ) as string,
                                        }}
                                        //showNumberFormat={true}
                                        numberFormatProps={{
                                            format: PHONE_NUMBER_MASK,

                                            allowEmptyFormatting: true,
                                        }}
                                    />
                                </AppLabelWrapper>
                            </Grid>
                            <Grid item xs={12}>
                                <AppLabelWrapper
                                    label={t(
                                        "registrationForParent.step3.country.title",
                                    )}
                                    required>
                                    <AppTextField
                                        control={control}
                                        name="Country"
                                        fullWidth
                                        rules={{
                                            required: t(
                                                "registrationForParent.step3.country.missing",
                                            ) as string,
                                        }}
                                    />
                                </AppLabelWrapper>
                            </Grid>
                            <Grid item xs={12}>
                                <AppLabelWrapper
                                    label={t(
                                        "registrationForParent.step3.address.title",
                                    )}
                                    required>
                                    <AppTextField
                                        control={control}
                                        name="Address"
                                        fullWidth
                                        rules={{
                                            required: t(
                                                "registrationForParent.step3.address.missing",
                                            ) as string,
                                        }}
                                    />
                                </AppLabelWrapper>
                            </Grid>
                            <Grid item xs={12}>
                                <AppLabelWrapper
                                    label={t(
                                        "registrationForParent.step3.city.title",
                                    )}
                                    required>
                                    <AppTextField
                                        control={control}
                                        name="City"
                                        fullWidth
                                        rules={{
                                            required: t(
                                                "registrationForParent.step3.city.missing",
                                            ) as string,
                                        }}
                                    />
                                </AppLabelWrapper>
                            </Grid>
                            <Grid item xs={12}>
                                <AppLabelWrapper
                                    label={t(
                                        "registrationForParent.step3.postalCode.title",
                                    )}
                                    required>
                                    <AppTextField
                                        control={control}
                                        name="PostalCode"
                                        fullWidth
                                        rules={{
                                            required: t(
                                                "registrationForParent.step3.postalCode.missing",
                                            ) as string,
                                        }}
                                    />
                                </AppLabelWrapper>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.footer}>
                        <AppStepperFooter {...rest} />
                    </div>
                </form>
            </div>
        );
    };

export default RegistrationForParentStep3;
