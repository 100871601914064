import { RoutingContext } from "globals";
import { MapSearchRequest } from "sdk/models/MapSearch";
import { BaseRoutingContext } from "./BaseRoutingContext";

export function appRoutesForContext(
    context: RoutingContext = new BaseRoutingContext(false),
) {
    return {
        public: () => {
            return {
                homePath: "/home",
                contactUsPath: "/contact",
                imprintPath: "/imprint",
            };
        },
        auth: () => {
            return {
                login: {
                    loginPath: "/login/redirectPath=:redirectPath",
                    setLoginPathParams: (redirectPath: string) =>
                        `/login/redirectPath=${redirectPath.replaceAll(
                            "/",
                            "-",
                        )}`,
                },
                registration: {
                    registrationPath:
                        "/registration/redirectPath=:redirectPath",
                    setRegistrationPathParams: (redirectPath: string) =>
                        `/registration/redirectPath=${redirectPath.replaceAll(
                            "/",
                            "-",
                        )}`,
                },
                passwordForgotten: {
                    passwordForgottenPath:
                        "/passwordForgotten/redirectPath=:redirectPath",
                    setpasswordForgottenPathParams: (redirectPath: string) =>
                        `/passwordForgotten/redirectPath=${redirectPath.replaceAll(
                            "/",
                            "-",
                        )}`,
                },
                resetPassword: {
                    resetPasswordPath:
                        "/resetPassword/redirectPath=:redirectPath",
                    setResetPasswordPathParams: (redirectPath: string) =>
                        `/resetPassword/redirectPath=${redirectPath.replaceAll(
                            "/",
                            "-",
                        )}`,
                },
                registerSuccess: {
                    registerSuccessPath:
                        "/registerSuccess/redirectPath=:redirectPath",
                    setRegisterSuccessPathParams: (redirectPath: string) =>
                        `/registerSuccess/redirectPath=${redirectPath.replaceAll(
                            "/",
                            "-",
                        )}`,
                },
                registerSuccessBeforeEmail: {
                    registerSuccessBeforeEmailPath:
                        "/registerSuccessBeforeEmail/redirectPath=:redirectPath",
                    setRegisterSuccessBeforeEmailPathParams: (redirectPath: string) =>
                        `/registerSuccessBeforeEmail/redirectPath=${redirectPath.replaceAll(
                            "/",
                            "-",
                        )}`,
                },
            };
        },
        mapSearch: () => {
            return {
                mapSearchWithParamsPath:
                    "/home/mapSearch/:address/:zipCode/:lat/:lng",
                mapSearchSetParams: (values: MapSearchRequest) =>
                    `/home/mapSearch/${values.address}/${values.zipCode}/${values.lat}/${values.lng}`,
                mapSearchPath: "/home/mapSearch",
            };
        },
        profile: () => {
            return {
                profilePath: "/profile/:id",
                profileEditPath: "/profile/edit/:id",
                setEditPathParams: (id: number) => `/profile/edit/${id}`,
                setProfilePathParams: (id: number) => `/profile/${id}`,
            };
        },
        facilitiesProfile: () => {
            return {
                facilitiesPath: "/facilities",
                facilitiesProfilePath: "/facilitesProfile",
                facilitiesProfileEditPath: "/facilitiesProfile/edit",
                addPasswordView: {
                    addPasswordViewPath:
                        "/addPasswordView/redirectPath=:redirectPath",
                    setAddPasswordViewPathParams: (redirectPath: string) =>
                        `/addPasswordView/redirectPath=${redirectPath.replaceAll(
                            "/",
                            "-",
                        )}`,
                },
                addNewFacilitiesView: {
                    addNewFacilitiesViewPath:
                        "/addNewFacilitiesView/redirectPath=:redirectPath",
                    setAddNewFacilitiesViewPathParams: (redirectPath: string) =>
                        `/addNewFacilitiesView/redirectPath=${redirectPath.replaceAll(
                            "/",
                            "-",
                        )}`,
                },
            };
        },
        registrationList: () => {
            return {
                registrationListPath: "/registrationList",
                kindergartenRegistrationListPath:
                    "/kindergartenRegistrationList",
                childsRequestsListPath: "/childsRequestsList",

            };
        },
        selectAllResults: () => {
            return {
                selectAllResultsPath: "/selectAllResults",
            };
        },
        parent: () => {
            return {
                kindergartenRegistrationListPath: "/kindergartenRegistrationList",
            };
        },
        superAdmin: () => {
            return {
                allKindergartens: "/allKindergartens",
                allParents: "/allParents",
                kindergartensRequests: {
                    kindergartensRequestsPath:
                        "/kindergartensRequests/redirectPath=:redirectPath",
                    setKindergartensRequestsPathParams: (redirectPath: string) =>
                        `/kindergartensRequests/redirectPath=${redirectPath.replaceAll(
                            "/",
                            "-",
                        )}`,
                },
            };
        },
    };
}
