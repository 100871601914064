import { MapStyle } from "components/GoogleMap/Partials/MapStyler";
import { BERLIN_LAT, BERLIN_LNG } from "globals/constants";
import GoogleMapReact, { Props } from "google-map-react";
import React, { useEffect } from "react";
import { COLOR_PRIMARY } from "theme";
//Markers must be pass as the children rode
export interface AppGoogleMapProps extends Props {
    center?: { lat: number; lng: number };
    zoom?: number;
    radiusInKm?: number;
    showRadiusCircle?: boolean;
    children?: React.ReactNode;
}
let circle: any;

export const AppGoogleMap: React.FC<AppGoogleMapProps> = ({
    center,
    zoom = 11,
    radiusInKm,
    children,
    showRadiusCircle,
}) => {
    const apiIsLoaded = (map: any, maps: any, center: any) => {
        if (radiusInKm) {
            circle = new maps.Circle({
                strokeColor: COLOR_PRIMARY,
                strokeOpacity: 0.1,
                strokeWeight: 1,
                fillColor: COLOR_PRIMARY,
                fillOpacity: 0.1,
                map,
                center: center,
                radius: radiusInKm * 1000,
            });
        }
    };
    useEffect(() => {
        if (circle && radiusInKm && showRadiusCircle) {
            circle.setRadius(Number(radiusInKm * 1000));
        }
    }, [radiusInKm, showRadiusCircle]);

    useEffect(() => {
        if (circle) circle.setCenter(center);
    }, [center]);

    return (
        <GoogleMapReact
            defaultCenter={{ lat: BERLIN_LAT, lng: BERLIN_LNG }}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals={true}
            options={function (maps) {
                return {
                    fullscreenControl: false,
                    styles: MapStyle,
                };
            }}
            center={center}
            zoom={zoom}
            onGoogleApiLoaded={({ map, maps }) => {
                showRadiusCircle && apiIsLoaded(map, maps, center);
            }}>
            {children}
        </GoogleMapReact>
    );
};

export default AppGoogleMap;
