export const CommonDeTranslations = {
    common: {
        continue: "Weiter",
        back: "Zurück",
        submit: "Absenden",
        reset: "Zurücksetzen",
        chooseFile: "Datei auswählen",
        noFileChosen: "Keine Datei ausgewählt",
        fileChosen: "Datei ausgewählt",
        filesChosen: "ausgewählte Dateien",
        fileUploadWarning:
            "Bitte laden Sie keine Familienbilder oder Bilder von Ihren Kindern hoch.",
        edit: "Bearbeiten",
        delete: "Löschen",
        more: "Mehr",
        clear: "Klar",
        noOptions: "Keine Optionen",
        open: "Öffnen Sie",
        findDaycareCentersNearMe: "Kitas in meiner Nähe finden",
        address: {
            missing: "Adresse fehlt",
        },
        zipCode: {
            missing: "PLZ fehlt",
        },
        save: "Speichern Sie",
        successfullySaved: "Erfolgreich gerettet",
        month: "Month",
        years: "Years",
        gender: {
            MALE: "Männlich",
            FEMALE: "Weiblich",
        },
        genderTable: {
            1: "Männlich",
            2: "Weiblich",
        },
        days: {
            saturday: "Samstag",
            sunday: "Sonntag",
        },
        closed: "geschlossen",
        careHours: {
            FOUR_TO_FIVE: "4-5 Stunden",
            FIVE_TO_SEVEN: "5-7 Stunden",
            SEVEN_T0_NINE: "7-9 Stunden",
            NINE_TO_TWELVE: "9-12 Stunden",
        },
        careHoursTable: {
            1: "4-5 Stunden",
            2: "5-7 Stunden",
            3: "7-9 Stunden",
            4: "9-12 Stunden",
        },
    },
};
export default CommonDeTranslations;
