import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { AppDialog } from "components/Dialog";
import AppStepper from "components/Stepper/AppStepper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiStepRegistrationData } from "screens";
import {
    getInitialValuesOfParentRegistration, RegistrationForParentModal
} from "sdk";
import { BG_GRADIENT } from "theme";
import {
    RegistrationForParentStep1, RegistrationForParentStep2,
    RegistrationForParentStep3
} from "./Partials";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: "100vh",
            width: "100%",
            overflow: "auto",
            background: BG_GRADIENT,
        },
        body: { width: "100%", marginTop: theme.spacing(10) },
        title: {
            marginBottom: theme.spacing(2),
        },
        stepperContainer: {
            marginTop: theme.spacing(2),
            paddingRight: theme.spacing(10),
            paddingLeft: theme.spacing(10),
            paddingBottom: theme.spacing(10),
            display: "flex",
            justifyContent: "center",
            width: "100%",
        },
        stepperBody: {
            width: "100%",
            maxWidth: "700px",
        },
        container: {
            width: "100%",
            // marginTop: theme.spacing(5),
            //marginBottom: theme.spacing(5),
        },
        stepperLabel: {
            width: "100%",
            maxWidth: "800px",
        },
    }),
);
export interface RegistrationForParentDialogProps {
    open: boolean;
    onClose: () => void;
    handleFormCompleted: () => void;
    handleStateSet: (value: MultiStepRegistrationData) => void;
    parentState: MultiStepRegistrationData;
    openStripe: () => void;
    openResult: boolean;
}
export const RegistrationForParentDialog: React.FC<RegistrationForParentDialogProps> =
    ({ onClose, open, handleFormCompleted, parentState, handleStateSet, openStripe, openResult }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const totalSteps = 3;
        const [state, setState] = useState<RegistrationForParentModal>(
            getInitialValuesOfParentRegistration(),
        );
        const onStateSetHandler = (value: RegistrationForParentModal) => {
            setState(value);
        };

        useEffect(() => {
            handleStateSet({
                ...parentState,
                registrationForParent: state
            });
        }, [state]);

        const onSubmitHandler = (value: RegistrationForParentModal) => {
            setState(value);
        };
        const [activeStep, setActiveStep] = React.useState(0);

        const handleNext = () => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };

        const handleBack = () => {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };

        const steps: React.ReactNode[] = [
            <RegistrationForParentStep1
                handleNext={handleNext}
                activeStep={activeStep}
                handleBack={handleBack}
                stepsLength={totalSteps}
                handleStateSet={onStateSetHandler}
                parentState={state}
                mainState={parentState}
            />,
            <RegistrationForParentStep2
                handleNext={handleNext}
                activeStep={activeStep}
                handleBack={handleBack}
                stepsLength={totalSteps}
                handleStateSet={onStateSetHandler}
                parentState={state}
            />,
            <RegistrationForParentStep3
                handleNext={handleNext}
                activeStep={activeStep}
                handleBack={handleBack}
                stepsLength={totalSteps}
                handleStateSet={onStateSetHandler}
                parentState={state}
                mainState={parentState}
                handleFormCompleted={handleFormCompleted}
                openStripe={openStripe}
                open={openResult}
            />,
        ];

        const renderSubtitle = (): string => {
            if (activeStep === 0) {
                return t("registrationForParent.step1.title");
            } else if (activeStep === 1) {
                return t("registrationForParent.step2.title");
            } else {
                return t("registrationForParent.step3.title");
            }
        };
        return (
            <AppDialog
                open={open}
                className={classes.root}
                handleClose={onClose}
                scroll="body"
                classes={{
                    root: classes.root,
                    container: classes.container,
                }}>
                <div className={classes.body}>
                    <Grid
                        container
                        justifyContent="center"
                        direction="column"
                        alignItems="center">
                        <Grid item className={classes.title}>
                            <Typography variant="h4">
                                {t("registrationForParent.title")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" color="textSecondary">
                                {renderSubtitle()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.stepperContainer}>
                        <div className={classes.stepperBody}>
                            <AppStepper
                                components={steps}
                                className={classes.stepperLabel}
                                activeStep={activeStep}
                            />
                        </div>
                    </div>
                </div>
            </AppDialog>
        );
    };

export default RegistrationForParentDialog;
