import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { AppTitleWithMarker, AppTitleWithMarkerProps } from "components";
import React from "react";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        title: {
            marginBottom: theme.spacing(3),
        },
    }),
);
export interface ProfileContainerHeaderProps extends AppTitleWithMarkerProps {
    children: React.ReactNode;
}
export const ProfileContainerHeader: React.FC<ProfileContainerHeaderProps> = ({
    children,
    ...rest
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                spacing={2}>
                <Grid item className={classes.title}>
                    <AppTitleWithMarker {...rest} />
                </Grid>
                {children}
            </Grid>
        </div>
    );
};

export default ProfileContainerHeader;
