import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { AppCircleWithCounter } from "components";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        detailsContainer: {
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        grayText: {
            fontWeight: 400,
        },
    }),
);
export interface ContactsFacilityDetailsProps {
    count: number;
    text1: string;
    text2: string;
    text3: string;
}
export const ContactsFacilityDetails: React.FC<ContactsFacilityDetailsProps> =
    ({ count, text1, text2, text3 }) => {
        const classes = useStyles();
        return (
            <Grid container>
                <Grid item>
                    <AppCircleWithCounter
                        height="60px"
                        width="60px"
                        textProps={{
                            variant: "h5",
                            color: "textSecondary",
                        }}
                        text={count.toString()}
                    />
                </Grid>
                <Grid item className={classes.detailsContainer}>
                    <Typography
                        className={classes.grayText}
                        variant="h6"
                        color="textSecondary">
                        {text1}
                    </Typography>
                    <Typography
                        className={classes.grayText}
                        variant="h6"
                        color="textSecondary">
                        {text2}
                    </Typography>
                    <Typography
                        className={classes.grayText}
                        variant="h6"
                        color="textSecondary">
                        {text3}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

export default ContactsFacilityDetails;
