import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import loginBg from "assets/images/bg/login-sign-up-bg.png";
import {
    AppControlledRadioButton,
    AppPrimaryButtonWithForwardIcon, AppTextField
} from "components";
import { AppDialog } from "components/Dialog";
import { MapAutoCompleteField } from "components/Inputs/MapSearchField";
import { EMAIL_VALIDATION_PATTERN } from "globals/constants";
import { TypePerson } from "globals/Types";
import { useUserContext } from "providers";
import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { AddNewFacilitiesViewForm, getAddNewFacilitiesViewFormInitialValue } from "sdk/models";
import { FacilitiesService } from "services/FacilitiesService";
import { SuperAdminService } from "services/SuperAdminService";
import usePlacesAutocomplete from "use-places-autocomplete";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        title: {
            marginBottom: theme.spacing(3),
            textAlign: "center",
        },

        iconButtonsContainer: {
            marginTop: theme.spacing(1),
        },
        loginButtonContainer: {
            marginTop: theme.spacing(1),
        },
        dialogContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
        },
        dialogBody: {
            height: "80vh",

            maxWidth: "400px",
            [theme.breakpoints.up("xll")]: {
                maxWidth: "600px",
            },
        },
        fullWidth: { width: "100%" },
        fieldsContainer: {
            marginTop: theme.spacing(4),
            maxWidth: "400px",
        },
        iconLocation: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    }),
);
const service = new FacilitiesService();
const serviceAdmin = new SuperAdminService();

export interface AddNewFacilitiesViewDialogProps {
    open: boolean;
    handleClose: () => void;
    redirectPath: string;
}

export const AddNewFacilitiesViewDialog: React.FC<AddNewFacilitiesViewDialogProps> = ({
    handleClose,
    open,
    redirectPath,

}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { control, handleSubmit, } = useForm<AddNewFacilitiesViewForm>();
    const { user, setUser } = useUserContext();

    const {
        suggestions: { data, loading },
        setValue,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: "DE" },
        },
        debounce: 300,
        cache: 24 * 60 * 60,
    });


    const submit = handleSubmit((data: AddNewFacilitiesViewForm) => {

        let addNewFacilitiesViewForm: AddNewFacilitiesViewForm = getAddNewFacilitiesViewFormInitialValue();
        addNewFacilitiesViewForm.firstname = data.firstname;
        addNewFacilitiesViewForm.lastname = data.lastname;
        addNewFacilitiesViewForm.kindergartenName = data.kindergartenName;
        addNewFacilitiesViewForm.address = data.address;
        addNewFacilitiesViewForm.postalCode = data.postalCode;
        addNewFacilitiesViewForm.city = data.city;
        addNewFacilitiesViewForm.phone = data.phone;
        addNewFacilitiesViewForm.country = data.country;
        addNewFacilitiesViewForm.email = data.email;
        // addNewFacilitiesViewForm.registerPerson = data.registerPerson;
        if (data.registerPerson as unknown as string === "1") {
            addNewFacilitiesViewForm.registerPerson = 1;
        } else {
            addNewFacilitiesViewForm.registerPerson = 0;
        }

        let street = data.address + ", " + data.postalCode;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': street }, (res, status) => {
            console.log(res, status)
            if (status === google.maps.GeocoderStatus.OK) {
                let latitude = res[0].geometry.location.lat();
                let longitude = res[0].geometry.location.lng();

                addNewFacilitiesViewForm.longitude = longitude.toString();
                addNewFacilitiesViewForm.latitude = latitude.toString();

                console.log(addNewFacilitiesViewForm.latitude as string);

                if (user && user.Type === "SuperAdministrator") {
                    serviceAdmin.adminAddNewFacilities(addNewFacilitiesViewForm)
                        .then(function (response) {
                            console.log("SUCCESS");
                            console.log(response);
                            history.push(redirectPath.replaceAll("-", "/"));
                            toast.success(t("addNewKindergarten.toastSuccess"));
                        })
                        .catch(function (error) {
                            console.log("API ERROR");
                            console.log(error);
                        })
                } else {
                    service.addNewFacilitiesView(addNewFacilitiesViewForm)
                        .then(function (response) {
                            console.log("SUCCESS");
                            console.log(response);
                            history.push(redirectPath.replaceAll("-", "/"));
                            toast.success(t("addNewKindergarten.toastSuccess"));
                        })
                        .catch(function (error) {
                            console.log("API ERROR");
                            console.log(error);
                        })
                }

            } else {
                toast.error(t("addNewKindergarten.toastError"));
            }
        });
    });

    const addressWatching = useWatch({
        control,
        name: "address",
    });

    useEffect(() => {
        if (addressWatching) {
            setValue(addressWatching);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressWatching]);

    return (
        <div className={classes.root}>
            <AppDialog
                withBackgroundImage={true}
                open={open}
                closeAfterTransition={true}
                handleClose={handleClose}
                bgImageUrl={loginBg}
                bgImageHeight={"100vh"}>
                <div className={classes.dialogContainer}>
                    <form noValidate={true} onSubmit={submit}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.dialogBody}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item className={classes.title}>
                                    <Typography
                                        variant="h4"
                                        color="textPrimary">
                                        {t("addNewKindergarten.title1")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.title}>
                                    <Typography variant="body1" color="textSecondary">
                                        {t("addNewKindergarten.title2")}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <AppTextField
                                        control={control}
                                        name="firstname"
                                        rules={{
                                            required: t(
                                                "addNewKindergarten.firstName.missing",
                                            ) as string,
                                        }}
                                        placeholder={t("addNewKindergarten.firstName.title")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppTextField
                                        control={control}
                                        name="lastname"
                                        rules={{
                                            required: t(
                                                "addNewKindergarten.lastName.missing",
                                            ) as string,
                                        }}
                                        placeholder={t("addNewKindergarten.lastName.title")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppTextField
                                        control={control}
                                        name="kindergartenName"
                                        rules={{
                                            required: t(
                                                "addNewKindergarten.kindergartenName.missing",
                                            ) as string,
                                        }}
                                        placeholder={t("addNewKindergarten.kindergartenName.title")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppTextField
                                        control={control}
                                        name="postalCode"
                                        rules={{
                                            required: t(
                                                "addNewKindergarten.postalCode.missing",
                                            ) as string,
                                        }}
                                        placeholder={t("addNewKindergarten.postalCode.title")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppTextField
                                        control={control}
                                        name="city"
                                        rules={{
                                            required: t(
                                                "addNewKindergarten.city.missing",
                                            ) as string,
                                        }}
                                        placeholder={t("addNewKindergarten.city.title")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppTextField
                                        control={control}
                                        name="phone"
                                        rules={{
                                            required: t(
                                                "addNewKindergarten.phone.missing",
                                            ) as string,
                                        }}
                                        placeholder={t("addNewKindergarten.phone.title")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppTextField
                                        control={control}
                                        name="country"
                                        rules={{
                                            required: t(
                                                "addNewKindergarten.country.missing",
                                            ) as string,
                                        }}
                                        placeholder={t("addNewKindergarten.country.title")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppTextField
                                        control={control}
                                        name="email"
                                        type="email"
                                        rules={{
                                            required: t(
                                                "addNewKindergarten.email.missing",
                                            ) as string,
                                            pattern: {
                                                value: EMAIL_VALIDATION_PATTERN,
                                                message: t(
                                                    "addNewKindergarten.email.invalid",
                                                ) as string,
                                            },
                                        }}
                                        placeholder={t("addNewKindergarten.email.title")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MapAutoCompleteField
                                        data={data}
                                        control={control}
                                        iconLocationClass={classes.iconLocation}
                                        name="address"
                                        loading={loading}
                                        rules={{
                                            required: t(
                                                "addNewKindergarten.address.missing",
                                            ) as string,
                                        }}
                                    //  placeholder="Address"
                                    //      fullWidth={true}
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <AppControlledRadioButton
                                        defaultValue={TypePerson?.toString()}
                                        name="registerPerson"
                                        control={control}
                                        rules={{
                                            required: t(
                                                "addNewKindergarten.registerPerson.missing",
                                            ) as string,
                                        }}
                                        gridProps={{ xs: 6 }}
                                        checkBoxes={[
                                            {
                                                value: TypePerson.OWNER,
                                                label: t("addNewKindergarten.registerPerson.title.owner"),

                                            },
                                            {
                                                value: TypePerson.MANAGER,
                                                label: t("addNewKindergarten.registerPerson.title.manager"),
                                            },
                                        ]}
                                    />

                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    className={classes.loginButtonContainer}
                                    spacing={5}>
                                    <Grid item>
                                        <AppPrimaryButtonWithForwardIcon
                                            type="submit"
                                            text={t("addNewKindergarten.submit")}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </AppDialog>
        </div>
    );
};

export default AddNewFacilitiesViewDialog;
