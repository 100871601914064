import { Grid, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import memoizeOne from "memoize-one";
import { TableColumn } from "react-data-table-component";
import { FacilitiesDataList } from "sdk";
import { COLOR_GRAY } from "theme";

export const FacilitiesListColumns = memoizeOne(
    (
        getFacilities: (isRegistered: boolean, id: number) => void,
    ): TableColumn<FacilitiesDataList>[] => {
        return [
            {
                minWidth: "600px",
                grow: 1,
                style: { height: "75px" },
                cell: (row: any) => {
                    return (
                        <div onClick={() => { getFacilities(row.IsRegistered, row.Id) }}>
                            <div>{row.Name}</div>
                            <Grid style={{ margin: 2, textAlign: "left" }}>
                                <Typography variant="body1" color="textSecondary">
                                    {row.ZipCode}, {row.Address}, {row.City}
                                </Typography>
                            </Grid>
                        </div>
                    )
                },
            },

            {
                width: "80px",
                style: { marginTop: "auto" },
                cell: (row: any) => {
                    return (
                        <div
                            style={{ fontSize: "1rem", color: COLOR_GRAY }}
                            onClick={() => { getFacilities(row.IsRegistered, row.Id) }}>
                            <ArrowForwardIosIcon style={{ fontSize: "0.9rem" }} />
                        </div>
                    );
                },
            },
        ];
    },
);
