import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { appRoutesForContext } from "routing/react-route-paths";
import { FacilitiesDataList, getSearchFacilitiesInitialValue } from "sdk";
import { FacilitiesStepsDialog } from "./Partials/FacilitiesSteps/FacilitiesStepsDialog";

export interface FacilitiesProps {
    open: boolean;
    onClose: () => void;
    handleFormCompleted: () => void;
}

export const Facilities = React.memo<FacilitiesProps>(
    ({ onClose, open, handleFormCompleted }) => {

        const [openFacilitiesSteps, setOpenFacilitiesSteps] = useState(true);
        let history = useHistory();
        onClose = () => {
            history.replace(
                appRoutesForContext()
                    .public().homePath
            );
        };

        return (
            <>
                {openFacilitiesSteps && (
                    <FacilitiesStepsDialog
                        open={openFacilitiesSteps}
                        onClose={onClose}
                        handleFormCompleted={() => { }}
                        handleStateSet={function (): void {
                            throw new Error("Function not implemented.");
                        }}
                    />
                )}
            </>
        );

    }
);


export default Facilities;
