import { RoutingContext } from "globals";
import { BaseRoutingContext } from "./BaseRoutingContext";

export function apiRoutesForContext(
    context: RoutingContext = new BaseRoutingContext(true),
) {
    return {
        server: {
            root: () => context.getApiBaseUrl()
        },
        auth: () => {
            return {
                login: () => "/account/token",
                register: () => "/account/registration",
                multiStepRegistration: () => "/KindergartenRequests/Registration",
                socialLogin: () => "/account/externalLogin",
                passwordForgotten: () => "/account/forgotpassword",
                resetPassword: () => "/account/resetpassword",
                getEmailComfirmationUrl: () => "/account/emailconfirmation",
                stripeSubscribe: () => "/account/stripe-subscribe",
            };
        },
        map: () => {
            return {
                getKindergartens: () => "/kindergartens"
            };
        },
        parent: () => {
            return {
                getChildLit: () => "/children",
                getRequestsByChildId: () => "/KindergartenRequests?takeAll=true&ChildId=",
                updateChild: () => "/children",
                deleteChild: () => "/children/",
                getKindergartenRequests: () => "/KindergartenRequests/Parent",
                deleteKindergartenRequests: () => "/KindergartenRequests/",
                getProfile: () => "/parents/getbycurrentuser",
                updateProfile: () => "/parents",
                addChildsRequest: () => "/KindergartenRequests/New",
                addNewKindergartensToChild: () => "/KindergartenRequests/only-request",
                stripeSubscribe: () => "/account/stripe-subscribe"
            };
        },
        facilities: () => {
            return {
                registerFacilities: () => "/account/kindergartenRegistration",
                getChildrenFromSameFacilities: () => "/KindergartenRequests/Kindergarten?takeAll=true",
                changeStatus: () => "/KindergartenRequests/ChangeStatus",
                getFacilitiesProfile: () => "/kindergartens/GetByCurrentUser",
                updateFacilitiesProfile: () => "/kindergartens",
                updateFacilitiesProfileImage: () => "/kindergartens/updateprofileimage",
                confirmationAndSetPassword: () => "account/ConfirmationAndSetPassword",
                addNewFacilitiesView: () => "/kindergartens/pending",
            };
        },

        superAdmin: () => {
            return {
                allKindergartens: () => "/kindergartens/admin",
                updateKindergartens: () => "/kindergartens/admin",
                adminAddNewFacilities: () => "/kindergartens/admin",
                allParents: () => "/parents",
                kindergartenRequests: () => "/kindergartenrequests/admin",
                deleteKindergarten: () => "/kindergartens",
            };
        },
    };
}
