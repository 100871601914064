import { ExpandableRow } from "./Types/General";

//toggling the expand of the table
export function handleRowExpand<Type extends ExpandableRow>(
    Id: string | number,
    props: Type[],
): Type[] {
    return props.map((x) => {
        if (Id === x.Id) {
            return { ...x, RowExpandedToggle: !x.RowExpandedToggle };
        }
        return x;
    });
}
