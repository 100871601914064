import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { User } from "sdk";

interface UserContextType {
    user: User | null;
    setUser: ((user: User | null) => void) | undefined;
    lng: string;
    setLanguage: ((lng: string) => void) | undefined;
}

export const UserContext = createContext<UserContextType>({
    user: null,
    setUser: undefined,
    lng: "de",
    setLanguage: undefined,
});

export const useUserContext = () => useContext(UserContext);

export const UserProvider: React.FC = ({ children }) => {
    const [user, setUserState] = useState<User | null>(null);
    const [lng, setLanguageState] = useState<string>("de");
    const { i18n } = useTranslation();
    const setUser = useCallback(() => {
        return (user: User | null) => setUserState(user);
    }, [setUserState]);

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const setLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setLanguageState(lng);
    };
    useEffect(() => {}, []);
    return (
        <UserContext.Provider
            value={{
                user: user,
                setUser: setUser(),
                lng: lng,
                setLanguage: setLanguage,
            }}>
            {children}
        </UserContext.Provider>
    );
};
