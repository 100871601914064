import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { AppTable } from "components";
import { handleRowExpand } from "globals/general-helper";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AllParentsModal, AllParentsRequest, getAllParentsRequestInitialValue } from "sdk";
import { SuperAdminService } from "services/SuperAdminService";
import { COLOR_BOX_SHADOW, COLOR_LIGTHEST_BLUE, COLOR_PRIMARY, COLOR_WHITE, FULL_VIEW_HEIGHT_WITHOUT_HEADER } from "theme";
import { AllParentsColumns, AllParentsExpendedRow } from "./Partials";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			minHeight: FULL_VIEW_HEIGHT_WITHOUT_HEADER,
			overflow: "auto",
			background: `linear-gradient(180deg, ${COLOR_WHITE} 10%, ${COLOR_LIGTHEST_BLUE} 100%)`,
			display: "flex",
			flexDirection: "column",
			paddingTop: theme.spacing(8),
			paddingBottom: theme.spacing(10),
			alignItems: "center",
			paddingRight: theme.spacing(25),
			paddingLeft: theme.spacing(25),
			[theme.breakpoints.down("lg")]: {
				paddingRight: theme.spacing(15),
				paddingLeft: theme.spacing(15),
			},
		},
		title: {
			marginBottom: theme.spacing(10),
		},
	}),
);

const conditionalRowStyles = [
	{
		when: (row: AllParentsModal) => row.RowExpandedToggle === true,
		style: {
			backgroundColor: COLOR_WHITE,
			color: COLOR_PRIMARY,
			boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
			borderRadius: "10px",
		},
	},
];

const service = new SuperAdminService();

export const AllParents = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	let history = useHistory();

	const [state, setState] = useState<AllParentsModal[]>([]);
	const [loading, setLoading] = useState(false);
	const [paginationResetDefaultPage, setPaginationResetDefaultPage] = useState(false);
	const [totalRows, setTotalRows] = useState(10000);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);

	const fetchUsers = async (page: any) => {
		setLoading(true);
		let parents: AllParentsRequest = getAllParentsRequestInitialValue();
		parents.page = page;
		parents.perPage = perPage;

		service.getAllParents(parents)
			.then(function (response) {
				//setTotalRows(response.data.totalFilteredCount);
				setTotalRows(response.data.count);
				console.log(response);
				const u: AllParentsModal[] = [];
				response.data.data.forEach((element: any) => {
					let date = moment(element.dateOfBirth).format('DD.MM.YYYY');
					let allParents: AllParentsModal = {
						id: element.id,
						firstName: element.firstName,
						lastName: element.lastName,
						dateOfBirth: date,
						phoneNumber: element.phoneNumber,
						country: element.country,
						city: element.city,
						address: element.address,
						postalCode: element.postalCode,
						userId: element.userId,
						imageUrl: element.imageUrl,
						imageFile: element.imageFile,
						Id: element.id,
					};
					u.push(allParents);
				});
				setState(u);
			})
			.catch(function (error) {
				console.log("API ERROR");
				console.log(error);
			})
		setLoading(false);
		setPaginationResetDefaultPage(false);

	};

	useEffect(() => {
		fetchUsers(page); // fetch page 1 of users
	}, []);

	const handlePageChange = (pageNew: any) => {
		fetchUsers(pageNew);
	};

	const handlePerRowsChange = async (newPerPage: any, page: any) => {
		setLoading(true);
		let parents: AllParentsRequest = getAllParentsRequestInitialValue();
		parents.page = page;
		parents.perPage = newPerPage;

		service.getAllParents(parents)
			.then(function (response) {
				console.log(response);
				const u: AllParentsModal[] = [];
				response.data.data.forEach((element: any) => {
					let date = moment(element.dateOfBirth).format('DD.MM.YYYY');
					let allParents: AllParentsModal = {
						id: element.id,
						firstName: element.firstName,
						lastName: element.lastName,
						dateOfBirth: date,
						phoneNumber: element.phoneNumber,
						country: element.country,
						city: element.city,
						address: element.address,
						postalCode: element.postalCode,
						userId: element.userId,
						imageUrl: element.imageUrl,
						imageFile: element.imageFile,
						Id: element.id,
					};
					u.push(allParents);
				});
				setState(u);
			})
			.catch(function (error) {
				console.log("API ERROR");
				console.log(error);
			})
		setPerPage(newPerPage);
		setLoading(false);
	};



	////////////////
	const onRowExpandClick = (id: number) => {
		console.log(id);
		setState([...handleRowExpand(id, state)]);
	};

	/////////////////////// Style Pagination
	const paginationComponentOptions = {
		rowsPerPageText: t("superAdmin.kindergartenList.rowsPerPage"),
		rangeSeparatorText: t("superAdmin.kindergartenList.of"),
		selectAllRowsItem: false,
	};

	return (
		<div className={classes.root}>
			<Typography className={classes.title} variant="h4">
				Parent Liste
			</Typography>

			<AppTable
				progressPending={loading}
				pagination
				paginationServer
				paginationTotalRows={totalRows}
				paginationResetDefaultPage={paginationResetDefaultPage}
				onChangeRowsPerPage={handlePerRowsChange}
				pointerOnHover={true}
				onChangePage={handlePageChange}
				paginationComponentOptions={paginationComponentOptions}
				columns={AllParentsColumns(t, onRowExpandClick)}
				data={state}
				paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
				///////////////////////////
				expandableRows
				expandableRowExpanded={(row) => row.RowExpandedToggle === true}
				conditionalRowStyles={conditionalRowStyles}
				expandOnRowClicked
				onRowExpandToggled={(
					expanded: boolean,
					row: AllParentsModal,
				) => onRowExpandClick(row.id as number)}
				expandableRowsComponent={(t) => (
					<AllParentsExpendedRow
						data={t.data}
					/>
				)}
			/>
		</div>
	);
}
