import { createStyles, makeStyles } from "@material-ui/styles";
import AppHeader from "layouts/Header/AppHeader";
import React from "react";
const useStyles = makeStyles(() =>
    createStyles({
        root: {
            minHeight: "100vh",
            maxHeight: "100vh",
            overflowY: "overlay" as any,
        },
    }),
);
export const HomeLayout = React.memo(({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppHeader />
            {children}
        </div>
    );
});

export default HomeLayout;
