import {
    Checkbox,
    CheckboxProps,
    FormControlLabel,
    withStyles,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ReactComponent as Check } from "assets/images/icons/check.svg";
import { COLOR_BOX_SHADOW } from "theme";
import React from "react";

import { ReactComponent as CheckDisable } from "assets/images/icons/check-disable.svg";
export interface AppCheckBoxProps extends CheckboxProps {
    label?: string;
}
const useStyles = makeStyles(() =>
    createStyles({
        checkIcon: {
            width: "2rem",
            height: "2rem",
            objectFit: "contain",
        },
        label: {
            paddingLeft: "10px",
        },
    }),
);
export const AppCheckBox = React.memo<AppCheckBoxProps>(
    ({ disabled, label = "", ...rest }) => {
        const GreenCheckbox = withStyles({
            root: {
                width: "3.5rem",
                height: "3.5rem",
                borderRadius: "10px",
                WebkitTouchCallout: "none" /* iOS Safari */,
                WebkitUserSelect: "none",
                KhtmlUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
                userSelect: "none",
                boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
            },
        })((props: CheckboxProps) => <Checkbox {...props} />);
        const classes = useStyles();
        return (
            <>
                {label ? (
                    <FormControlLabel
                        disabled={disabled}
                        classes={{ label: classes.label }}
                        control={
                            <GreenCheckbox
                                icon={<></>}
                                disabled={disabled}
                                checkedIcon={
                                    disabled ? (
                                        <CheckDisable
                                            className={classes.checkIcon}
                                        />
                                    ) : (
                                        <Check className={classes.checkIcon} />
                                    )
                                }
                                {...rest}
                            />
                        }
                        label={label}
                    />
                ) : (
                    <GreenCheckbox
                        icon={<></>}
                        disabled={disabled}
                        checkedIcon={
                            disabled ? (
                                <CheckDisable className={classes.checkIcon} />
                            ) : (
                                <Check className={classes.checkIcon} />
                            )
                        }
                        {...rest}
                    />
                )}
            </>
        );
    },
);

export default AppCheckBox;
