import { CircularProgress, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ReactComponent as BabySquare } from "assets/images/icons/baby-square.svg";
import { AppGoogleMap, AppMapSearchField, MapMarker } from "components";
import { BERLIN_LAT, BERLIN_LNG, DEFAULT_POSTAL_CODE } from "globals/constants";
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useLocation, useParams } from "react-router";
import { MapSearchSideBar } from "screens";
import { KindergartenRegistrationListModal, ProfileModel } from "sdk";
import {
    KindergartenMapDataList, MapSearchParams,
    MapSearchRequest
} from "sdk/models/MapSearch";
import { MapService } from "services/MapService";
import {
    COLOR_PRIMARY,
    COLOR_SECONDARY,
    FULL_HEIGHT_OF_MAP_SEARCH_SIDEBAR_SCREEN
} from "theme";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { useUserContext } from "providers";
import { ParentService } from "services/ParentService";

const service = new MapService();
const parentService = new ParentService();

const useStyles = makeStyles(() =>
    createStyles({
        root: { overflow: "auto" },
        mapSearchField: {
            width: "100%",
            height: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        mapLoader: {
            display: "flex",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
            width: "100%",
        },
        markerTooltipIcon: { width: "35px", height: "35px" },
    }),
);
export interface MapSearchState {
    searchField: MapSearchRequest;
    kindergartenList: KindergartenMapDataList[];
    sliderValue: number;
    loadingMap: boolean;
    maxSliderLength: number;
}

export const MapSearch = () => {
    let { address, lat, lng, zipCode } = useParams<MapSearchParams>();
    const classes = useStyles();
    const location = useLocation();
    const { user } = useUserContext();

    //Checking if user search from dashboard then bind the params to the state
    //else ask for location and set the lng, lat of the current location of user

    const [state, setState] = useState<MapSearchState>({
        loadingMap: true,
        searchField: {
            lat: lat ? +lat : null,
            lng: lng ? +lng : null,
            address: address ? address : null,
            zipCode: zipCode ? +zipCode : null,
            distance: 100,
        },
        kindergartenList: [],

        sliderValue: 1,
        maxSliderLength: 30,

    });

    const [oldKindergartens, setOldKindergartens] = useState<KindergartenMapDataList[]>([]);
    const [kindergartens, setKindergartens] = useState<KindergartenMapDataList[]>([]);
    const [oldChildIdAndKindergartens, setOldChildIdAndKindergartens] = useState<KindergartenRegistrationListModal[]>([]);
    const [distance, setDistance] = useState<number>(0);

    const selectFirst5kindergartens = () => {
        setState({
            ...state,
            kindergartenList: state.kindergartenList.map((x, index) => {
                if (index > 4) {
                    x.IsSelected = false;
                }
                return x;
            }),
        });
    };

    useEffect(() => {
        if (!address && !lat && !lng) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    let postalCode: string | undefined;
                    getGeocode({
                        location: {
                            lat: BERLIN_LAT,
                            lng: BERLIN_LNG,
                        },
                    })
                        .then((results) => {
                            postalCode = results.find((x) =>
                                x.types.includes("postal_code"),
                            )?.address_components[0].long_name;

                            setState({
                                ...state,
                                kindergartenList: [],
                                searchField: {
                                    ...state.searchField,
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude,
                                    zipCode: postalCode
                                        ? Number(postalCode)
                                        : undefined,
                                },
                                loadingMap: false,
                            });
                        })

                        .catch((error) => {
                            console.log(error);
                        });
                },
                function (error) {
                    setState({
                        ...state,
                        kindergartenList: [],
                        searchField: {
                            ...state.searchField,
                            lat: BERLIN_LAT,
                            lng: BERLIN_LNG,
                            zipCode: DEFAULT_POSTAL_CODE,
                        },
                        loadingMap: false,
                    });
                },
            );
        }
        else {
            service.getKindergartensNew(state.searchField)
                .then(function (response) {
                    console.log(state.searchField);
                    console.log(response);

                    let x: KindergartenMapDataList[] = [];
                    response.data.data.forEach((element: any) => {
                        let kindergartenItem: KindergartenMapDataList = {
                            Address: element.address,
                            Name: element.name,
                            lng: parseFloat(element.longitude),
                            lat: parseFloat(element.latitude),
                            Id: element.kindergartenId,
                            PostalCode: parseInt(element.postalCode)
                        };
                        x.push(kindergartenItem);
                        setState({
                            ...state,
                            loadingMap: false,
                            maxSliderLength: response.data.sliderRangeKm
                        });
                    });
                   
                    setKindergartens(x);
                    console.log(x);
                    console.log(state);


                })
                .catch(function (error) {
                    console.log(error);
                })
        }
        if (location.state !== undefined) {
            setOldChildIdAndKindergartens(location.state as KindergartenRegistrationListModal[]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

    }, [oldChildIdAndKindergartens]);
    useEffect(() => {

        if (location.state === undefined) {
            setKindergartens(oldKindergartens);
        } else {
            let id: number[] = [0];
            let arrayOfKindergartens: KindergartenMapDataList[] = [];

            oldChildIdAndKindergartens.map((x) => id = [...id, x.KindergartenId]);
            oldKindergartens.map((x) => (arrayOfKindergartens = [...arrayOfKindergartens, x]));

            for (let i = 0; i < arrayOfKindergartens.length; i++) {
                arrayOfKindergartens = arrayOfKindergartens.filter((x) => x.Id !== id[i]);
            }
            setKindergartens(arrayOfKindergartens);
        }
    }, [oldKindergartens]);

    useEffect(() => {
        setState({
            ...state,
            kindergartenList: kindergartens,
            searchField: {
                ...state.searchField
            },
        });
    }, [kindergartens]);

    // useEffect(() => {
    //     console.log("state.searchField")

    //     console.log(state.searchField)
    //     service.getKindergartensNew(state.searchField)
    //             .then(function (response) {
    //                 console.log(response);

    //                 let x: KindergartenMapDataList[] = [];
    //                 response.data.forEach((element: any) => {
    //                     let kindergartenItem: KindergartenMapDataList = {
    //                         Address: element.address,
    //                         Name: element.name,
    //                         lng: parseFloat(element.longitude),
    //                         lat: parseFloat(element.latitude),
    //                         Id: element.kindergartenId,
    //                         PostalCode: parseInt(element.postalCode)
    //                     };
    //                     x.push(kindergartenItem);
    //                 });
    //                 setKindergartens(x);
    //                 console.log("distance array");

    //                 console.log(x);

    //             })
    //             .catch(function (error) {
    //                 console.log(error);
    //             })
    //             console.log("distance");
    //             console.log(distance);


    //     console.log(kindergartens);
    // }, [distance]);

    const handleSliderChange = (newValue: number) => {
        setState({ ...state, sliderValue: newValue as number });
    };

    const onCheckBoxClickHandler = (
        value: KindergartenMapDataList,
        checked: boolean,
        selectAll?: boolean,
    ) => {
        setState({
            ...state,
            kindergartenList: state.kindergartenList.map((x) => {
                if (selectAll) {
                    x.IsSelected = true;
                } else {
                    if (x.Id === value.Id) {
                        x.IsSelected = checked;
                    }
                }
                return x;
            }),
        });
    };
    const onSelectAllCheckBoxes = (checked: boolean) => {
        setState({
            ...state,
            kindergartenList: state.kindergartenList.map((x) => {
                x.IsSelected = checked;
                return x;
            }),
        });
    };
    const onSearchValueClick = (value: MapSearchRequest) => {

        console.log(value)
        service.getKindergartensNew(value)
            .then(function (response) {
                console.log(response)
                setState({ ...state, searchField: value, maxSliderLength: response.data.sliderRangeKm });
                
                let x: KindergartenMapDataList[] = [];
                response.data.data.forEach((element: any) => {
                    let kindergartenItem: KindergartenMapDataList = {
                        Address: element.address,
                        Name: element.name,
                        lng: parseFloat(element.longitude),
                        lat: parseFloat(element.latitude),
                        Id: element.kindergartenId,
                        PostalCode: parseFloat(element.postalCode)
                    };
                    if (!oldKindergartens.some(e => e.Id === kindergartenItem.Id)) {
                        x.push(kindergartenItem);
                      
                    }
                   
                });
                let myArray = [x];
                setOldKindergartens(x);
                console.log(state)
            })
            .catch(function (error) {
                console.log(error);
            })
    };

    return (
        <div className={classes.root}>
            <div className={classes.mapSearchField}>
            {!state.loadingMap ? (
                <AppMapSearchField
                    values={state.searchField}
                    onSearchButtonClick={onSearchValueClick}
                />
            ) : (
                <div></div>
            )}
            </div>
            <Grid container>
                <Grid item sm={6} md={5} lg={4} xl={3}>
                    <MapSearchSideBar
                        onSelectAllCheckBoxes={onSelectAllCheckBoxes}
                        kindergartenList={state.kindergartenList}
                        handleSliderChange={handleSliderChange}
                        sliderValue={state.sliderValue}
                        onCheckBoxClickHandler={onCheckBoxClickHandler}
                        searchField={state.searchField}
                        selectFirst5kindergartens={selectFirst5kindergartens}
                        max={Math.ceil(state.maxSliderLength)}

                    />
                </Grid>
                <Grid item sm={6} md={7} lg={8} xl={9}>
                    {state.loadingMap ? (
                        <div className={classes.mapLoader}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div
                            style={{
                                minHeight:
                                    FULL_HEIGHT_OF_MAP_SEARCH_SIDEBAR_SCREEN,
                                maxHeight:
                                    FULL_HEIGHT_OF_MAP_SEARCH_SIDEBAR_SCREEN,
                                height: FULL_HEIGHT_OF_MAP_SEARCH_SIDEBAR_SCREEN,
                                overflow: "auto",
                                width: "100%",
                            }}>
                            <AppGoogleMap
                                showRadiusCircle={true}
                                center={
                                    state.searchField.lng &&
                                        state.searchField.lat
                                        ? {
                                            lat: state.searchField.lat,
                                            lng: state.searchField.lng,
                                        }
                                        : undefined
                                }
                                zoom={13}
                                radiusInKm={state.sliderValue}>
                                {state.kindergartenList.map((marker, index) => (
                                    <MapMarker
                                        key={index}
                                        tooltipProps={{
                                            address: marker.Address,
                                            name: marker.Name,
                                            icon: (
                                                <BabySquare
                                                    className={
                                                        classes.markerTooltipIcon
                                                    }
                                                />
                                            ),
                                        }}
                                        bgColor={
                                            marker.IsSelected
                                                ? COLOR_SECONDARY
                                                : COLOR_PRIMARY
                                        }
                                        lat={marker.lat}
                                        lng={marker.lng}
                                        text={(index + 1).toString()}
                                    />
                                ))}
                            </AppGoogleMap>
                        </div>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default MapSearch;
