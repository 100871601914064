import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import KindergartenRequestsDialog from "./KindergartenRequestsDialog";

export interface KindergartenRequestsParams {
    redirectPath: string;
}

export const KindergartenRequests = () => {
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const { redirectPath } = useParams<KindergartenRequestsParams>();
    const location = useLocation();
    
useEffect(()=>{
    console.log(location.state);

},[]);

    const handleClose = () => {
        setOpen(false);
        history.push(redirectPath.replaceAll("-", "/"));
    };

    return (
        <KindergartenRequestsDialog
            open={open}
            handleClose={handleClose}
            redirectPath={redirectPath}
            kindergartenId={location.state as number}
        />
    );
};

export default KindergartenRequests;
