import { IconButton } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as MenuIcon } from "assets/images/icons/menu.svg";
import { COLOR_DARK_BLUE, COLOR_WHITE } from "theme";
import React from "react";
import { useTranslation } from "react-i18next";

export interface AppTableActionMenuProps {
    hideDelete?: boolean;
    hideEdit?: boolean;
    id?: number;
    onDeleteItem?: (id: number) => void;
}

const StyledMenu = withStyles({
    paper: {
        color: COLOR_WHITE,
        borderRadius: "12px",
        background: COLOR_DARK_BLUE,
        overflow: "visible",
        marginTop: 5,
        mt: 1.5,
        "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 2,
            right: 14,
            width: 13,
            height: 10,
            background: COLOR_DARK_BLUE,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
        },
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        borderRadius: "10px",
        marginRight: "10px",
        marginLeft: "10px",
        width: "150px",
        "&:focus": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export const AppTableActionMenu: React.FC<AppTableActionMenuProps> = ({
    hideDelete,
    hideEdit,
    id,
    onDeleteItem,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteItem = () => {
        if(onDeleteItem) onDeleteItem(id as number);
    };

    return (
        <div style={{ width: "100%" }}>
            <IconButton
                color="primary"
                aria-label="upload picture"
                onClick={handleClick}
                component="span">
                <MenuIcon style={{ width: 20, height: 20 }} />
            </IconButton>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                PaperProps={{
                    elevation: 0,
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                onClose={handleClose}>
                {!hideEdit && (
                    <StyledMenuItem>
                        <ListItemText primary={t("common.edit")} />
                    </StyledMenuItem>
                )}

                {!hideDelete && (
                    <StyledMenuItem>
                        <ListItemText primary={t("common.delete")} onClick={deleteItem}/>
                    </StyledMenuItem>
                )}
            </StyledMenu>
        </div>
    );
};

export default AppTableActionMenu;
