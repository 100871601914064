import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import ContactsBg from "assets/images/bg/contacts-bg.png";
import { ReactComponent as BabuSquare } from "assets/images/icons/baby-square.svg";
import { ReactComponent as LocationMaker } from "assets/images/icons/pin-location-marker.svg";
import {
    AppContainerWithBackground,
    AppContainerWithLineSeparation,
    AppGoogleMap,
    MapMarker,
} from "components";
import { CONTACT_MAP_LAT, CONTACT_MAP_LNG } from "globals/constants";
import { AppFooter } from "layouts";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    COLOR_PRIMARY,
    FULL_VIEW_HEIGHT_WITHOUT_HEADER,
    MIN_HEIGHT_OF_CONTACTS,
} from "theme";
import { ContactsFirstContainer, ContactsSecondContainer } from "./Partials";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: FULL_VIEW_HEIGHT_WITHOUT_HEADER,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "50px",
        },
        body: {
            marginTop: theme.spacing(2),
            width: "100%",
            maxWidth: "1000px",
        },

        headerContainer: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(8),
        },
        locationMaker: { width: "20px", height: "20px" },
        mapSection: {
            minHeight: "400px",
            maxHeight: "400px",
            height: "400px",
            overflow: "auto",
            width: "100%",
        },
        tooltipIcon: { width: "35px", height: "35px" },
    }),
);
export const Contacts = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <AppContainerWithBackground
                style={{
                    height: "1000px",
                    minHeight: MIN_HEIGHT_OF_CONTACTS,
                }}
                bgUrl={ContactsBg}>
                <div className={classes.root}>
                    <div className={classes.body}>
                        <Grid
                            container
                            alignItems="center"
                            direction="column"
                            className={classes.headerContainer}>
                            <Grid item>
                                <Typography variant="h4">
                                    {t("contacts.title")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <AppContainerWithLineSeparation
                            components={[
                                {
                                    component: (
                                        <ContactsFirstContainer key="ContactsFirstContainer" />
                                    ),
                                },
                                {
                                    component: (
                                        <ContactsSecondContainer key="ContactsSecondContainer" />
                                    ),
                                },
                            ]}
                        />
                    </div>
                </div>
            </AppContainerWithBackground>
            <div className={classes.mapSection}>
                <AppGoogleMap
                    center={{
                        lat: CONTACT_MAP_LAT,
                        lng: CONTACT_MAP_LNG,
                    }}
                    zoom={13}>
                    <MapMarker
                        styleProps={{
                            arrowColor: COLOR_PRIMARY,
                            showHoverEffect: false,
                            borderRadius: "6px",
                        }}
                        lat={CONTACT_MAP_LAT}
                        lng={CONTACT_MAP_LNG}
                        bgColor={COLOR_PRIMARY}
                        tooltipProps={{
                            address:
                                "Gotlindestrasse 54 10365 Berlin-Lichtenberg",
                            name: t("contacts.familyAndCare"),
                            icon: (
                                <BabuSquare className={classes.tooltipIcon} />
                            ),
                        }}>
                        <LocationMaker className={classes.locationMaker} />
                    </MapMarker>
                </AppGoogleMap>
            </div>
            <AppFooter></AppFooter>
        </>
    );
};

export default Contacts;
