export const RegistrationForChildrenDeTranslations = {
    registrationForChildren: {
        title: "Anmeldung für Ihr Kind",
        childNo: "Kind {{count}}",
        addNewChild: "Weiteres Kind hinzufügen",
        step1: {
            title: "Informationen über das Kind",
            firstNameChild: {
                title: "Vorname Kind",
                placeHolder: "Vorname eingeben",
                missing: "Vornamen eingeben fehlt",
            },
            lastNameChild: {
                title: "Nachname Kind",
                placeHolder: "Nachname eingeben",
                missing: "Nachname fehlt",
            },
            gender: {
                title: "Geschlecht",
                missing: "Das Geschlecht fehlt",
            },
            birthdayOfTheChild: {
                title: "Geburtstag des Kindes",
                missing: "Geburtstag fehlt",
            },
            supportStart: {
                title: "Betreuungsstart",
                missing: "Start der Unterstützung fehlt",
            },
            careHours: {
                title: "Betreuungsstunden",
                missing: "Betreuungsstunden fehlen",
            },
        },
        step2: {
            title: "Weitere Informationen",
            message: {
                title: "Nachricht",
                placeHolder: "Ihre Nachricht",
            },
            attachFile: {
                title: "Datei anhänge \n (z.Bsp. Kitagutschein)",
                attachFileWarning:
                    "Bitte laden Sie keine Familienbilder oder Bilder Ihrer Kinder hoch",
            },
        },
    },
};
export default RegistrationForChildrenDeTranslations;
