import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { AppPasswordTextField, AppStepperFooter } from "components";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    RegistrationForParentStep2Modal,
    RegistrationForParentStepperCommonProps
} from "sdk";
import { CHILDREN_REGISTRATION_MAX_WIDTH } from "theme";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
        },
        fullWidth: { width: "100%" },
        body: {
            width: "100%",
            maxWidth: CHILDREN_REGISTRATION_MAX_WIDTH,
            minWidth: "1200px",
            display: "flex",
            justifyContent: "center",
        },
        fieldsContainer: {
            marginTop: theme.spacing(4),
            maxWidth: "400px",
        },
        footer: {
            maxWidth: CHILDREN_REGISTRATION_MAX_WIDTH,
            minWidth: "1100px",
        },
    }),
);

export interface RegistrationForParentStep2Props
    extends RegistrationForParentStepperCommonProps { }
export const RegistrationForParentStep2: React.FC<RegistrationForParentStep2Props> =
    ({ handleNext, parentState, handleStateSet, ...rest }) => {
        const classes = useStyle();
        const { t } = useTranslation();
        const { control, handleSubmit, watch } =
            useForm<RegistrationForParentStep2Modal>({
                defaultValues: {
                    ConfirmPassword: parentState.ConfirmPassword,
                    Password: parentState.Password,
                },
            });
        const submit = handleSubmit((data) => {
            handleStateSet({
                ...parentState,
                ...(data as RegistrationForParentStep2Modal),
            });
            handleNext();
        });
        let passwordWatcher = watch("Password");
        return (
            <div className={classes.root}>
                <form onSubmit={submit} noValidate={true}>
                    <div className={classes.body}>
                        <Grid
                            container
                            spacing={2}
                            className={clsx(
                                classes.fullWidth,
                                classes.fieldsContainer,
                            )}>
                            <Grid item xs={12}>
                                <AppPasswordTextField
                                    autoComplete="off"
                                    control={control}
                                    name="Password"
                                    type="password"
                                    rules={{
                                        required: t(
                                            "auth.password.missing",
                                        ) as string,
                                    }}
                                    placeholder={t("auth.password.placeHolder")}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AppPasswordTextField
                                    autoComplete="off"
                                    control={control}
                                    name="ConfirmPassword"
                                    type="password"
                                    rules={{
                                        required: t(
                                            "auth.confirmPassword.missing",
                                        ) as string,
                                        validate: (value: string) =>
                                            value === passwordWatcher ||
                                            (t(
                                                "auth.confirmPassword.notMatched",
                                            ) as string),
                                    }}
                                    placeholder={t(
                                        "auth.confirmPassword.placeHolder",
                                    )}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.footer}>
                        <AppStepperFooter {...rest} />
                    </div>
                </form>
            </div>
        );
    };

export default RegistrationForParentStep2;
