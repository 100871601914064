import { withStyles } from "@material-ui/styles";
import SliderVectorLine from "assets/images/slider-vector-line.png";
import StepConnector from "@material-ui/core/StepConnector";
export const AppStepperConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& $line": {
            backgroundImage: `url("${SliderVectorLine}")`,
        },
    },
    completed: {
        "& $line": {
            backgroundImage: `url("${SliderVectorLine}")`,
        },
    },
    line: {
        opacity: 1,
        height: 2,
        marginTop: 5,
        backgroundRepeat: "repeat-x",
        backgroundImage: `url("${SliderVectorLine}")`,
    },
})(StepConnector);
