export const RegistrationForParentDeTranslations = {
    registrationForParent: {
        title: "Ihre Registrierung",
        orRegistrationWith: "Oder registrieren mit:",
        ifYouContinue: "Wenn do fortfährst akzeptierst du damit unsere ",
        terms: "Nutzungsbedingungen",
        privacy: "Datenschutzbestimmungen",
        step1: {
            title: "Anmeldung per E-Mail oder über soziale Netzwerke",
            email: {
                title: "E-Mail-Adresse",
                missing: "E-Mail-Adresse is missing",
                invalid: "E-Mail-Adresse is invalid",
            },
        },
        step2: {
            title: "Ein Passwort erstellen",
        },
        step3: {
            title: "Bitte geben Sie Ihre persönlichen Daten an.",
            yourFirstName: {
                title: "Ihr Vorname",
                placeHolder: "Vorname eingeben",
                missing: "Vorname fehlt",
            },
            dataOfBirth: {
                title: "Geburtsdatum",
                placeHolder: "Geburtsdatum eingeben",
                missing: "Das Geburtsdatum fehlt",
            },
            yourLastName: {
                title: "Ihr Nachname",
                placeHolder: "Nachname eingeben",
                missing: "Nachname fehlt",
            },
            address: {
                title: "Address",
                missing: "Adresse fehlt",
            },
            country: {
                title: "Country",
                missing: "Land fehlt",
            },
            street: {
                title: "Straße",
                missing: "Straße fehlt",
            },
            houseNumber: {
                title: "Hausnummer",
                missing: "Die Hausnummer fehlt",
            },
            postalCode: {
                title: "Postleitzahl",
                missing: "Die Postleitzahl fehlt",
            },
            city: {
                title: "Stadt",
                missing: "Die Stadt fehlt",
            },
            phoneNumber: {
                title: "Telefonnummer",
                placeHolder: "Telefonnummer eingeben",
                missing: "Die Telefonnummer fehlt",
            },
        },
    },
};
export default RegistrationForParentDeTranslations;
