import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { ReactComponent as HomeSection2Image1 } from "assets/images/home-section-2-image-1.svg";
import { ReactComponent as HomeSection2Image2 } from "assets/images/home-section-2-image-2.svg";
import { ReactComponent as HomeSection2Image3 } from "assets/images/home-section-2-image-3.svg";

import React from "react";
import { useTranslation } from "react-i18next";
import { HomeSection2Card } from "screens";
export interface HomeSection2Props {
    height: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (value: HomeSection2Props) => ({
            height: value.height,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "5px",
        }),
        lastSection: {
            marginTop: theme.spacing(10),
        },
        gridDisplayCenter: {
            display: "flex",
            justifyContent: "center",
        },
        icon: {
            textAlign: "center",
        },
        image: {
            objectFit: "contain",
            objectPosition: "center",
            [theme.breakpoints.down("md")]: { width: "280px", height: "280px" },

            [theme.breakpoints.up("lg")]: { width: "600px" },
        },
        image3: {
            [theme.breakpoints.down("md")]: { width: "100px" },
            [theme.breakpoints.down("xl")]: { width: "350px" },
            [theme.breakpoints.up("xl")]: { width: "500px" },
            objectFit: "contain",
        },
    }),
);
// eslint-disable-next-line react-hooks/rules-of-hooks

export const HomeSection2: React.FC<HomeSection2Props> = (props) => {
    const classes = useStyles(props);
    const { t } = useTranslation();
    return (
        <div className={classes.root}>
            <Grid
                justifyContent="space-between"
                alignItems="center"
                direction="column"
                container
                className={classes.root}>
                <Grid item>
                    <HomeSection2Card
                        title={t("home.overviewTitle")}
                        body1={t("home.overviewBody1")}
                        body2={t("home.overviewBody2")}
                        imageSrc={
                            <HomeSection2Image1 className={classes.image} />
                        }
                        className={classes.gridDisplayCenter}
                        imageClassName={classes.image}
                        count={1}
                    />
                </Grid>
                <Grid item>
                    <HomeSection2Card
                        position="right"
                        title={t("home.createApplicationTitle")}
                        body1={t("home.createApplicationBody1")}
                        body2={t("home.createApplicationBody2")}
                        count={2}
                        imageSrc={
                            <HomeSection2Image2 className={classes.image} />
                        }
                        className={classes.gridDisplayCenter}
                        imageClassName={classes.image}
                    />
                </Grid>
                <Grid item>
                    <HomeSection2Card
                        title={t("home.applicationStatus")}
                        body1={t("home.applicationStatusBody1")}
                        body2={t("home.applicationStatusBody2")}
                        count={3}
                        imageSrc={
                            <HomeSection2Image3 className={classes.image} />
                        }
                        className={classes.gridDisplayCenter}
                        imageClassName={classes.image}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                alignItems="center"
                spacing={1}
                alignContent="center"
                className={classes.lastSection}
                justifyContent="center">
                <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", marginBottom: "20px" }}>
                    <Typography variant="h4" color="textPrimary">
                        {t("home.compareToTheAlternative")}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="body1" color="textSecondary">
                        {t("home.compareToBody1")}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="body1" color="textSecondary">
                        {t("home.compareToBody2")}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default HomeSection2;
