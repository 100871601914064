import { Divider, Theme, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { AppTextWithIcon } from "components";
import React, { ReactElement } from "react";
import { BOX_SHADOW } from "theme";

export interface MapToolTipProps {
    name: string;
    address: string;
    icon?: React.ReactNode;
    children: ReactElement<any, any>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            minWidth: 320,
            maxWidth: 320,

            paddingLeft: theme.spacing(3),
            left: 10,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            borderRadius: "10px",
            boxShadow: BOX_SHADOW,
        },
        arrow: {
            color: theme.palette.common.white,
            boxShadow: BOX_SHADOW,
        },
        icon: { width: "35px", height: "35px" },
    }),
);

export const MapToolTip: React.FC<MapToolTipProps> = ({
    address,
    icon,
    name,
    children,
}) => {
    const classes = useStyles();
    return (
        <Tooltip
            placement="right-start"
            arrow
            enterDelay={300}
            enterTouchDelay={300}
            enterNextDelay={300}
            classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
            }}
            title={
                <React.Fragment>
                    <AppTextWithIcon text={name} svg={icon} />
                    <Divider
                        style={{
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}></Divider>
                    <Typography variant="body1" color="textSecondary">
                        {address}
                    </Typography>
                </React.Fragment>
            }>
            {children}
        </Tooltip>
    );
};

export default MapToolTip;
