import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ReactComponent as AddNewChildIcon } from "assets/images/icons/add-new-child.svg";
import { AppButton, AppStepperFooter } from "components";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import RegistrationForChildStep1Fields from "screens/RegistrationForChild/Partials/RegistrationForChildStep1Fields";
import {
    getInitialChild,
    RegistrationForChildrenStep1,
    RegistrationForChildrenStepperCommonProps
} from "sdk";

const useStyles = makeStyles(() =>
    createStyles({
        addNewChildIcon: {
            width: "25px",
            height: "25px",
        },
    }),
);

export interface RegistrationForChildStep1Props
    extends RegistrationForChildrenStepperCommonProps { }

export const RegistrationForChildStep1: React.FC<RegistrationForChildStep1Props> =
    ({ handleNext, parentState, handleStateSet, handleFormCompleted, ...rest }) => {
        const { control, handleSubmit, reset } = useForm<{
            children: RegistrationForChildrenStep1[];
        }>({
            defaultValues: {
                children: [...parentState.children],
            },
        });

        const { t } = useTranslation();
        const classes = useStyles();

        const submit = (data: any) => {
            handleStateSet({
                ...parentState,
                children: data.children as RegistrationForChildrenStep1[],
            });
            if (rest.activeStep !== rest.stepsLength - 1) {
                handleNext();
            } else {
                handleFormCompleted(data);
            }
        };
        const submitForAddNew = (data: any) => {
            reset({
                children: [
                    ...(data.children as RegistrationForChildrenStep1[]),
                    getInitialChild(),
                ],
            });
            handleStateSet({
                ...parentState,
                children: [
                    ...(data.children as RegistrationForChildrenStep1[]),
                    getInitialChild(),
                ],
            });
        };

        const removeChild = (index: number) => {
            reset({
                children: parentState.children.filter(
                    (value, i) => i !== index,
                ),
            });
            handleStateSet({
                ...parentState,
                children: parentState.children.filter(
                    (value, i) => i !== index,
                ),
            });
        };

        return (
            <form noValidate={true} onSubmit={handleSubmit(submit)}>
                {parentState.children.map((state, index) => (
                    <div key={index}>
                        <RegistrationForChildStep1Fields
                            control={control}
                            index={index}
                            removeChild={removeChild}
                            state={state}
                        />
                    </div>
                ))}

                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="space-between">
                    <Grid item>
                        <AppButton
                            endIcon={
                                <AddNewChildIcon
                                    className={classes.addNewChildIcon}
                                />
                            }
                            text={t("registrationForChildren.addNewChild")}
                            variant="outlined"
                            color="primary"
                            onClick={(e) => {
                                handleSubmit(submitForAddNew)();
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <AppStepperFooter {...rest} />
                    </Grid>
                </Grid>
            </form>
        );
    };

export default RegistrationForChildStep1;
