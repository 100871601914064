export const CommonEeTranslations = {
    common: {
        continue: "Continue",
        back: "Back",
        submit: "Submit",
        reset: "Reset",
        chooseFile: "Choose file",
        noFileChosen: "No file Chosen",
        fileChosen: "file chosen",
        filesChosen: "files chosen",
        fileUploadWarning:
            "Please do not upload family pictures or pictures of your children",
        edit: "Edit",
        delete: "Delete",
        more: "More",
        clear: "Clear",
        noOptions: "No Options",
        open: "Open",
        findDaycareCentersNearMe: "Find daycare centers near me",
        address: {
            missing: "Address is missing",
        },
        zipCode: {
            missing: "ZipCode is missing",
        },
        gender: {
            MALE: "Male",
            FEMALE: "Female",
        },
        genderTable: {
            1: "Male",
            2: "Female",
        },
        careHours: {
            FOUR_TO_FIVE: "4-5 Hours",
            FIVE_TO_SEVEN: "5-7 Hours",
            SEVEN_T0_NINE: "7-9 Hours",
            NINE_TO_TWELVE: "9-12 Hours",
        },
        careHoursTable: {
            1: "4-5 Hours",
            2: "5-7 Hours",
            3: "7-9 Hours",
            4: "9-12 Hours",
        },
        years: "Years",
        save: "Save",
        successfullySaved: "Successfully Saved",
        month: "Monat",
        days: {
            saturday: "Saturday",
            sunday: "Sunday",
        },
        closed: "closed",
    },
};
export default CommonEeTranslations;
