import {
    createStyles,
    Divider,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { ReactComponent as SelectAllIcon } from "assets/images/icons/select-all.svg";
import axios from "axios";
import { AppPrimaryButtonWithForwardIcon, AppSlider } from "components";
import { AppStripeDialog } from "components/StripeForm";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { appRoutesForContext } from "routing/react-route-paths";
import {
    RegistrationForChildDialog,
    RegistrationForParentDialog,
    SelectAllResultsDialog
} from "screens";
import { ChildIdAndKindergarten, ClientSecret, findCareHour, findGender, getClientSecretInitialValue, getInitialChildIdAndKindergarten, getInitialValuesOfParentRegistration, getMapSearchInitialValue, getRegistrationForChildrenInitialValue, getStripeSubscribeInitialValue, KindergartenMapDataList, KindergartenRegistrationListModal, MapSearchRequest, RegistrationForChildren, RegistrationForParentModal, StripeSubscribe } from "sdk/models";
import { ParentService } from "services/ParentService";
import {
    COLOR_BOX_SHADOW,
    COLOR_DARK_BLUE,
    COLOR_LIGTHEST_BLUE,
    FULL_HEIGHT_OF_MAP_SEARCH_SIDEBAR_SCREEN
} from "theme";
import KindergartensList from "./KindergartensList";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            boxShadow: `6px 6px 15px 1px ${COLOR_BOX_SHADOW}`,
            minHeight: FULL_HEIGHT_OF_MAP_SEARCH_SIDEBAR_SCREEN,
            borderRadius: "0px 10px 20px 0px / 0px 10px 20px 10px",
            position: "relative",
            background: `linear-gradient(180deg, ${theme.palette.common.white} 10%, ${COLOR_LIGTHEST_BLUE} 90%)`,
        },
        rootContent: {
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
        },
        titleText: {
            color: theme.palette.success.main,
            paddingTop: theme.spacing(3),
        },
        kindergartenListContainer: {
            paddingLeft: theme.spacing(4),
        },
        divider: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(6),
        },
        button: {
            width: "100%",
            borderRadius: "0px 10px 10px 0px / 0px 10px 10px 10px",
        },
        nextButtonContainer: { position: "absolute", bottom: 0, width: "100%" },
        selectAllContainer: {
            paddingRight: theme.spacing(5),
        },
        selectAllImage: {
            objectFit: "contain",
            width: "30px",
            height: "30px",
            paddingTop: theme.spacing(1),
        },
    }),
);

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const service = new ParentService();

export interface MultiStepRegistrationData {
    kindergartens: KindergartenMapDataList[];
    childrensInfo: RegistrationForChildren;
    registrationForParent: RegistrationForParentModal;
}

export interface MapSearchSideBarProps {
    kindergartenList: KindergartenMapDataList[];
    handleSliderChange: (value: number) => void;
    sliderValue: number;
    max: number;
    onCheckBoxClickHandler: (
        value: KindergartenMapDataList,
        checked: boolean,
        selectAll?: boolean,
    ) => void;
    onSelectAllCheckBoxes: (checked: boolean) => void;
    searchField: MapSearchRequest;
    selectFirst5kindergartens: () => void;
    //  parentState: MultiStepRegistrationData;
}

export const MapSearchSideBar = React.memo<MapSearchSideBarProps>(
    ({
        kindergartenList,
        handleSliderChange,
        sliderValue,
        onSelectAllCheckBoxes,
        onCheckBoxClickHandler,
        searchField,
        selectFirst5kindergartens,
        max,
    }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const location1 = useLocation();
        const history = useHistory();
        let query = useQuery();


        const [openRegistrationForChild, setOpenRegistrationForChild] =
            useState(false);
        const [openSelectAllResults, setSelectAllResults] = useState(false);
        const [openRegistrationForParent, setOpenRegistrationForParent] =
            useState(false);

        const [multiStepRegistration, setMultiStepRegistration] = useState<MultiStepRegistrationData>({
            kindergartens: [getMapSearchInitialValue()],
            childrensInfo: getRegistrationForChildrenInitialValue(),
            registrationForParent: getInitialValuesOfParentRegistration()
        });
        const [childIdAndKindergartens, setChildIdAndKindergartens] = useState<ChildIdAndKindergarten>(getInitialChildIdAndKindergarten());
        const [oldChildIdAndKindergartens, setOldChildIdAndKindergartens] = useState<KindergartenRegistrationListModal[]>([]);

        const [addKindergartens, setAddKindergartens] = useState(false);

        const [clientSecret, setClientSecret] = useState<ClientSecret>(getClientSecretInitialValue());
        const [total, setTotal] = useState<number>(0);
        const [stripeSubscribe, setStipeSubscribe] = useState<StripeSubscribe>(getStripeSubscribeInitialValue());


        const onStateSetHandler = (value: MultiStepRegistrationData) => {
            setMultiStepRegistration(value);
            console.log("mapp list");
            console.log(value);
            let fullname = multiStepRegistration.registrationForParent.FirstNameParent + " " + multiStepRegistration.registrationForParent.LastNameParent;

            setStipeSubscribe({
                name: fullname,
                email: multiStepRegistration.registrationForParent.Email,
            });

        };
        const [openStripeDialog, setOpenStripeDialog] = useState(false);

        const closeRegistration = () => {
            setOpenRegistrationForChild(false);
        };
        const closeSelectAll = () => {
            setSelectAllResults(false);
        };
        const closeRegistrationForParent = () => {
            setOpenRegistrationForParent(false);
        };
        const openRegistration = () => {
            setOpenRegistrationForChild(true);
        };
        const openSelectAll = () => {
            setSelectAllResults(true);
        };
        const continueWithoutPayment = () => {
            selectFirst5kindergartens();
            openRegistration();
            closeSelectAll();
        };
        const payWithStripe = () => {
            console.log('zzz');
            setOpenStripeDialog(true);
            const request = {
                email: '',
                name: '',
                token: ''
            }
            // closeSelectAll();
        };
        const closeStripeDialog = () => {
            setOpenStripeDialog(false);
        };

        const submitData = (data: RegistrationForChildren) => {
            var token = Cookies.get("JwtToken");
            if (token === undefined) {
                setOpenRegistrationForParent(true);
                setOpenRegistrationForChild(false);
            } else {
                const request = new FormData();

                for (let i = 0; i < multiStepRegistration.kindergartens.length; i++) {
                    multiStepRegistration.kindergartens[i].Id.toString();
                    request.append(
                        `kindergartens[${i}]`,
                        multiStepRegistration.kindergartens[i].Id.toString()
                    );
                }

                for (let i = 0; i < multiStepRegistration.childrensInfo.children.length; i++) {
                    request.append(
                        `childrenData[${i}].careHour`,
                        findCareHour(data.children[i].CareHours).toString()
                    );
                    request.append(
                        `childrenData[${i}].childBirthday`,
                        data.children[i].BirthdayOfTheChild.format('YYYY-MM-DDTHH:mm:ss')
                    );
                    request.append(
                        `childrenData[${i}].childFirstname`,
                        data.children[i].FirstNameChild
                    );
                    request.append(
                        `childrenData[${i}].childLastname`,
                        data.children[i].LastNameChild
                    );
                    request.append(
                        `childrenData[${i}].childGender`,
                        findGender(data.children[i].Gender).toString()
                    );
                    request.append(
                        `childrenData[${i}].supportStart`,
                        data.children[i].SupportStart.format('YYYY-MM-DDTHH:mm:ss')
                    );
                    request.append(
                        `childrenData[${i}].message`,
                        data.children[i].Message
                    );
                    const k = data.children[i];
                    if (k.AttachedFile) {
                        console.log(k);
                        request.append(
                            `childrenData[${i}].voucherFile`,
                            k.AttachedFile[0]
                        );
                    }
                }

                console.log(request);
                service.addChildsRequests(request)
                    .then(function (response) {
                        console.log(response);
                        toast.success(t("common.successfullySaved"));
                        if (totalKindergartenSelected > 5) {
                            openSelectAll();
                        } else {
                            setOpenRegistrationForChild(false);
                        }
                    })
                    .catch(function (error) {
                        console.log("API ERROR:");
                        console.log(error);
                    })
            }
        };

        const isAllChecked =
            kindergartenList.filter((x) => x.IsSelected).length ===
            kindergartenList.length;

        const totalKindergartenSelected =
            kindergartenList.filter((x) => x.IsSelected).length + oldChildIdAndKindergartens.length;

        const onRegistrationChildClick = () => {
            if (location1.state === undefined) {
                setAddKindergartens(false);
                setMultiStepRegistration({
                    ...multiStepRegistration,
                    kindergartens: kindergartenList.filter(
                        (x) => x.IsSelected === true)
                });

                openRegistration();
            } else {
                kindergartenList = kindergartenList.filter((x) => x.IsSelected === true);
                let k: number[] = [];
                kindergartenList.map((x) => k = [...k, x.Id]);
                setChildIdAndKindergartens({
                    ...childIdAndKindergartens,
                    kindergartens: k
                });

                if (totalKindergartenSelected > 5) {
                    setTotal(totalKindergartenSelected);
                    setAddKindergartens(false);
                    openSelectAll();
                } else {
                    setAddKindergartens(true);
                }
            }
        };

        useEffect(() => {
            if (location1.state !== undefined) {
                setOldChildIdAndKindergartens(location1.state as KindergartenRegistrationListModal[]);
            }
        }, []);

        useEffect(() => {
            let id: number = 0;
            oldChildIdAndKindergartens.map((x) => id = x.ChildId as number);
            setChildIdAndKindergartens({
                ...childIdAndKindergartens,
                childId: id,
            });

        }, [oldChildIdAndKindergartens]);


        useEffect(() => {

            if (addKindergartens === true) {
                let addNewKindergartens: ChildIdAndKindergarten = getInitialChildIdAndKindergarten();
                addNewKindergartens.childId = childIdAndKindergartens.childId;
                addNewKindergartens.kindergartens = childIdAndKindergartens.kindergartens;

                console.log("addNewKindergartens");
                console.log(addNewKindergartens);

                service.addNewKindergartensToChild(addNewKindergartens)
                    .then(function (response) {
                        console.log("API RESPONSE:");
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log("API ERROR:");
                        console.log(error);
                    })
                history.push(
                    appRoutesForContext()
                        .parent()
                        .kindergartenRegistrationListPath,
                );
            }
        }, [childIdAndKindergartens]);

        useEffect(() => {
            var urlencoded = new URLSearchParams();
            urlencoded.append("amount", "590");
            urlencoded.append("currency", "eur");
            urlencoded.append("payment_method_types[]", "card");

            axios({
                method: "post",
                headers: { "Content-Type": "application/x-www-form-urlencoded", "Authorization": "Bearer sk_test_51L7HJUJTSiiZDbzeJiq8C5XFVXcTX8sQdNcaC0Ecjz4MGedk3ANjd1TnZuy8rGkuHbTCsZ3kpJ4ufJEF4Lac4wbZ00q4DCtUDG" },
                url: "https://api.stripe.com/v1/payment_intents",
                data: urlencoded
            }).then(result => setClientSecret({ clientSecret: result.data.client_secret }));

        }, [total]);

        useEffect(() => {
            console.log(clientSecret);
        }, [clientSecret]);

        return (
            <>
                {openSelectAllResults && (
                    <>
                        <SelectAllResultsDialog
                            open={openSelectAllResults}
                            totalKindergartenSelected={
                                totalKindergartenSelected
                            }
                            continueWithoutPayment={continueWithoutPayment}
                            onClose={closeSelectAll}
                            payWithStripe={payWithStripe}
                        />

                    </>
                )}
                {openRegistrationForChild && (
                    <RegistrationForChildDialog
                        open={openRegistrationForChild}
                        onClose={closeRegistration}
                        handleFormCompleted={submitData}
                        handleStateSet={onStateSetHandler}
                        parentState={multiStepRegistration}
                    />
                )}
                {openRegistrationForParent && (
                    <RegistrationForParentDialog
                        open={openRegistrationForParent}
                        onClose={closeRegistrationForParent}
                        handleFormCompleted={() => {
                            setOpenRegistrationForParent(false);
                        }}
                        handleStateSet={onStateSetHandler}
                        parentState={multiStepRegistration}
                        openStripe={() => {
                            openSelectAll();
                        }}
                        openResult={totalKindergartenSelected > 5}
                    />
                )}
                {openStripeDialog && (
                    <>
                        <AppStripeDialog
                            open={openStripeDialog}
                            onClose={closeStripeDialog}
                            clientSecret={clientSecret}
                            stripeSubscribe={stripeSubscribe}
                        />
                    </>
                )}

                <div className={classes.root}>
                    <Grid container direction="column">
                        <Grid item className={classes.rootContent}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography
                                        variant="h4"
                                        className={classes.titleText}>
                                        {t("mapSearch.kindergartenNearYou")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        color="textSecondary">
                                        {`${t("mapSearch.found")} ${kindergartenList.length} ${t(
                                            "mapSearch.kindergarten",
                                        )}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <AppSlider
                                        value={sliderValue}
                                        max={max}
                                        min={1}
                                        unit="km"
                                        onChange={handleSliderChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={classes.kindergartenListContainer}>
                            <KindergartensList
                                onCheckBoxClickHandler={onCheckBoxClickHandler}
                                kindergartenList={kindergartenList}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.nextButtonContainer}>
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}
                            className={classes.selectAllContainer}>
                            <Grid item>
                                <Typography
                                    onClick={() =>
                                        onSelectAllCheckBoxes(!isAllChecked)
                                    }
                                    variant="body1"
                                    style={{
                                        color: COLOR_DARK_BLUE,
                                        cursor: "pointer",
                                    }}>
                                    {isAllChecked
                                        ? t("mapSearch.unSelectAllResults")
                                        : t("mapSearch.selectAllResults")}
                                </Typography>
                            </Grid>
                            <Grid item style={{ cursor: "pointer" }}>
                                <SelectAllIcon
                                    onClick={() =>
                                        onSelectAllCheckBoxes(!isAllChecked)
                                    }
                                    className={classes.selectAllImage}
                                />
                            </Grid>
                        </Grid>
                        <AppPrimaryButtonWithForwardIcon
                            disabled={
                                kindergartenList.length <= 0 ||
                                kindergartenList.filter((x) => x.IsSelected)
                                    .length < 1
                            }
                            text={t("mapSearch.registerYourChild")}
                            classes={{ root: classes.button }}
                            onClick={onRegistrationChildClick}
                        />
                    </div>
                </div>
            </>
        );
    },
);

export default MapSearchSideBar;

