import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { AppLabelWrapper } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { KindergartenRequestsModal } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            marginRight: theme.spacing(30),

        },
        fullWidth: { width: "100%" },
    }),
);
export interface KindergartenRequestsExpendedRowFirstContainerProps {
    infoState: KindergartenRequestsModal;
}
export const KindergartenRequestsExpendedRowFirstContainer: React.FC<KindergartenRequestsExpendedRowFirstContainerProps> =
    ({ infoState }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        return (
            <Grid
                container
                spacing={2}
                justifyContent="center"
                className={classes.root}>

                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Child First Name:"
                        value={infoState.firstname}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Child Last Name:"
                        value={infoState.lastname}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Gender:"
                        value={infoState.gender}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Date of Birth:"
                        value={infoState.birthDate.toString()}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
            </Grid>
        );
    };

export default KindergartenRequestsExpendedRowFirstContainer;
