import MomentUtils from "@date-io/moment";
import {
    createStyles,
    makeStyles,
    StandardProps,
    withStyles,
} from "@material-ui/core";
import {
    KeyboardDatePickerProps,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ReactComponent as CalenderIcon } from "assets/images/icons/calendar.svg";
import { Locale, StyleClassKey } from "globals";
import { COLOR_BOX_SHADOW, COLOR_PRIMARY, COLOR_WHITE } from "theme";
import { GERMAN_DATE_FORMAT } from "globals/constants";
import moment from "moment";
import "moment/locale/de";
import React from "react";

moment.locale("de"); // it is required to select default locale manually

const styles = () =>
    createStyles({
        imgRoot: {
            width: "1.5rem",
            cursor: "pointer",
            objectFit: "contain",
            height: "2rem",
        },
        root: { display: "flex", alignItems: "center", width: "100%" },
        label: {
            minWidth: "180px",
        },
        textFieldContainer: {
            width: "100%",
        },
    });

type AppDatePickerClassKey = StyleClassKey<typeof styles>;

const useStyles = makeStyles(styles);

export interface AppDatePickerProps
    extends StandardProps<KeyboardDatePickerProps, AppDatePickerClassKey> {
    locale?: Locale;
    removeShadow?: boolean;
}
export const AppDatePicker = React.memo<AppDatePickerProps>(
    ({ locale, removeShadow, ...rest }) => {
        const classes = useStyles(rest);
        const CustomDatePicker = withStyles({
            root: {
                "& label.Mui-focused": {
                    color: COLOR_PRIMARY,
                },
                "& .MuiOutlinedInput-adornedEnd": {
                    borderBottomColor: COLOR_WHITE,
                },
                "& .MuiOutlinedInput-adornedStart": {
                    borderBottomColor: COLOR_WHITE,
                },
                "& .MuiInput-underline:after": {
                    borderBottomColor: COLOR_PRIMARY,
                },
                "& .MuiInputBase-input": {
                    background: COLOR_WHITE,
                    paddingRight: "30px",
                    paddingLeft: "30px",
                },
                "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    "& fieldset": {
                        borderColor: "transparent",
                        boxShadow: removeShadow
                            ? "none"
                            : `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
                        borderRadius: "10px",
                    },
                    "&:hover fieldset": {
                        borderColor: COLOR_PRIMARY,
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: COLOR_PRIMARY,
                    },
                },
            },
        })(KeyboardDatePicker);

        return (
            <div className={classes.root}>
                <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale={locale ? locale : "de"}>
                    <CustomDatePicker
                        disableToolbar
                        variant="inline"
                        format={GERMAN_DATE_FORMAT}
                        inputVariant="outlined"
                        InputProps={{
                            endAdornment: (
                                <CalenderIcon className={classes.imgRoot} />
                            ),
                        }}
                        {...rest}
                    />
                </MuiPickersUtilsProvider>
            </div>
        );
    },
);

export default AppDatePicker;
