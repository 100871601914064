import {
    createStyles,
    makeStyles,
    StandardProps,
    StandardTextFieldProps,
    TextField,
} from "@material-ui/core";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { AppControlProps, StyleClassKey } from "globals";
import {
    COLOR_BOX_SHADOW,
    COLOR_GRAY,
    COLOR_PRIMARY,
    COLOR_WHITE,
} from "theme";
import React from "react";
import { Controller } from "react-hook-form";

export interface StyleProps {
    removeShadow?: boolean;
    customBorderRadius?: string;
    startIcon?: boolean;
    endIcon?: boolean;
}
const styles = () =>
    createStyles({
        startIcon: { position: "absolute", top: 28, left: 10 },
        endIcon: { position: "absolute", top: 28, right: 10 },
        root: {},
        inputFieldRoot: (props: StyleProps) => {
            return {
                "& label.Mui-focused": {
                    color: COLOR_PRIMARY,
                },
                "& .MuiInput-underline:after": {
                    borderBottomColor: COLOR_PRIMARY,
                },
                "& .MuiInputBase-input": {
                    paddingRight: props.endIcon ? "60px" : "30px",
                    paddingLeft: props.startIcon ? "60px" : "30px",
                },

                "& .MuiOutlinedInput-root": {
                    color: COLOR_GRAY,
                    borderRadius: props.customBorderRadius
                        ? props.customBorderRadius
                        : "10px",
                    background: COLOR_WHITE,
                    "& fieldset": {
                        borderColor: "transparent",

                        boxShadow: props.removeShadow
                            ? "none"
                            : `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
                        borderRadius: props.customBorderRadius
                            ? props.customBorderRadius
                            : "10px",
                    },
                    "&:hover fieldset": {
                        borderColor: COLOR_PRIMARY,
                        marginRight: "-1px",
                        marginLeft: "-1px",
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: COLOR_PRIMARY,
                        marginRight: "-1px",
                        marginLeft: "-1px",
                    },
                },
            };
        },
    });

type AppTextFieldClassKey = StyleClassKey<typeof styles>;

const useStyles = makeStyles(styles);
export interface AppTextFieldProps
    extends StandardProps<
        StandardTextFieldProps & AppControlProps,
        AppTextFieldClassKey
    > {
    removeShadow?: boolean;
    customBorderRadius?: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    numberFormatProps?: NumberFormatProps;
    showNumberFormat?: boolean;
}
export const AppTextField: React.FC<AppTextFieldProps> = ({
    removeShadow = false,
    required,
    name,
    customBorderRadius,
    control,
    rules,
    shouldUnregister,
    startIcon,
    endIcon,
    numberFormatProps,
    showNumberFormat,
    ...rest
}) => {
    const classes = useStyles({
        ...rest,
        customBorderRadius: customBorderRadius,
        removeShadow: removeShadow,
        startIcon: startIcon !== undefined,
        endIcon: endIcon !== undefined,
    });
    //control , rules and name is required in order to use react form hook and validations
    return (
        <div className={classes.root}>
            {control ? (
                <Controller
                    name={name ? name : ""}
                    control={control}
                    rules={rules}
                    shouldUnregister={shouldUnregister}
                    render={({ field, fieldState }) => {
                        const { value, ...fieldAttr } = field;
                        if (showNumberFormat) {
                            return (
                                <NumberFormat
                                    customInput={TextField}
                                    classes={{ root: classes.inputFieldRoot }}
                                    onValueChange={(v) => {
                                        field.onChange(v.value);
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    {...fieldAttr}
                                    {...numberFormatProps}
                                />
                            );
                        } else {
                            return (
                                <TextField
                                    fullWidth
                                    classes={{ root: classes.inputFieldRoot }}
                                    variant="outlined"
                                    color={"primary"}
                                    value={field.value ? field.value : ""}
                                    {...fieldAttr}
                                    onChange={(e) => {
                                        if (!showNumberFormat) {
                                            field.onChange(e.target.value);
                                        }
                                    }}
                                    {...rest}
                                    error={fieldState.invalid}
                                    helperText={
                                        fieldState.error &&
                                        fieldState.error.message
                                    }
                                />
                            );
                        }
                    }}
                />
            ) : (
                <TextField
                    classes={{ root: classes.inputFieldRoot }}
                    required
                    fullWidth
                    variant="outlined"
                    color={"primary"}
                    {...rest}
                />
            )}
            {startIcon && <div className={classes.startIcon}>{startIcon}</div>}
            {endIcon && <div className={classes.endIcon}>{endIcon}</div>}
        </div>
    );
};

export default AppTextField;
