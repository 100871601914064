
export const KindergartenEnTranslations = {
    kindergarten: {
        step1: {
            title: "Welcome to Kitaplatz!",
            findYourFacility: "Find your Kindergarten",
            text: "Welcome !!! With “kitaplatz.de” you can centrally manage your registrations. In addition, you get a quick and clear overview of a possible allocation of places in your facility.",
        },
        step2: {
            title: "Register your facility on KitaPlatz",
            text: "Search for your facility via the address input. If your facility is not yet listed, simply add your kindergarten.",
            search: {
                posctcode: "Postal Code",
                facilityName: "Kindergarten Name",
                search: "Search",
            },
            table: {
                found: "Found",
                results: "results",
                noRecords: "There are no records to display",
                toastSuccess: "Already Registered",
            },
            addNewKindergarten: "Add Kindergarten",
        },
        step3: {
            title: "You are not registered yet",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            owner: "You are Owner",
            manager: "You are Manager",
        },
        step4: {
            title: "Registration",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum",
            fullName: {
                placeHolder: "Full Name",
                missing: "Full Name is missing",
            },
            email: {
                placeHolder: "E-mail",
                missing: "E-mail is missing",
            },
            phone: {
                placeHolder: "Phone",
                missing: "Phone is missing",
            },
            registration: "Registration",
            text2: "By submitting the form I accept the",
            text2Blue: "privacy policy",
        },
        step5: {
            title1: "You have successfully",
            title2Green: "Registered!",
            text: "Check your E-mail for account activation.",
            goToHome: "Go to Home!!",
        },
    },
    addNewKindergarten: {
        title1: "Add Kindergarten",
        title2: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi",
        firstName: {
            title: "First Name",
            missing: "First Name is missing",
        },
        lastName: {
            title: "Last Name",
            missing: "Last Name is missing",
        },
        kindergartenName: {
            title: "Kindergarten Name ",
            missing: "Kindergarten Name is missing",
        },
        postalCode: {
            title: "Postal Code",
            missing: "Postal Code is missing",
        },
        city: {
            title: "City",
            missing: "City is missing",
        },
        phone: {
            title: "Phone",
            missing: "Phone is missing",
        },
        companyId: {
            title: "Company Id",
            missing: "Company Id is missing",
        },
        country: {
            title: "Country ",
            missing: "Country  is missing",
        },
        email: {
            title: "E-Mail Address",
            missing: "E-Mail Addresse is missing",
            invalid: "E-Mail Addresse is invalid",
        },
        address: {
            title: "Address",
            missing: "Address is missing",
        },
        registerPerson: {
            title: {
                owner: "Owner",
                manager: "Manager",
            },
            missing: "Company Role is missing",
        },
        submit: "Save",
        toastSuccess: "New Kindergarten added!!",
        toastError: "Address is not associated with zip code",
    },
    addPasswordView: {
        toastSuccess: "Your are registered!!",
    }
};
export default KindergartenEnTranslations;
