import axios from "axios";
import { apiRoutesForContext } from "routing/react-api-route-paths";
import {
    KindergartenMapDataList,
    MapSearchRequest
} from "sdk";
import { BaseService } from "services/BaseService";

export interface IMapService {
    getKindergartens: (data: MapSearchRequest) => Promise<KindergartenMapDataList>;
}

const tmp = apiRoutesForContext();
export class MapService extends BaseService implements IMapService {
    public authRoutes: typeof tmp;

    constructor() {
        super();
        this.authRoutes = apiRoutesForContext();
    }

    getKindergartens(data: MapSearchRequest): Promise<any> {
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.map().getKindergartens() + "?takeAll=true&zip=" + data.zipCode,
        })
    }

    getKindergartensNew(data: MapSearchRequest): Promise<any> {
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.map().getKindergartens() + "?pagesize=100&page=1&CalculateRange=true&Latitude=" + data.lat + "&Longitude=" + data.lng + "&Distance=100",
        })
    }

}
