import { BaseService } from "services/BaseService";
import { apiRoutesForContext } from "routing/react-api-route-paths";
import axios from "axios";
import Cookies from "js-cookie";
import { AddChildsRequest, ChildIdAndKindergarten, ProfileUpdateRequest, UpdateChildRequest } from "sdk";

export interface IParentService {
    getChildsList: () => Promise<any>;
}

const tmp = apiRoutesForContext();
export class ParentService extends BaseService implements IParentService {
    public authRoutes: typeof tmp;

    constructor() {
        super();
        this.authRoutes = apiRoutesForContext();
    }

    getChildsList(): Promise<any> {
        var token = Cookies.get("JwtToken");
            return axios({
                method: "get",
                baseURL: this.routes.server.root(),
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                url: this.routes.parent().getChildLit(),
            })
    }

    getRequestsByChildId(id: number): Promise<any> {
        var token = Cookies.get("JwtToken");
            return axios({
                method: "get",
                baseURL: this.routes.server.root(),
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                url: this.routes.parent().getRequestsByChildId() + id,
            })
    }

    updateChild(data: UpdateChildRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
            return axios({
                method: "put",
                data: data,
                baseURL: this.routes.server.root(),
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                url: this.routes.parent().updateChild(),
            })
    }

    deleteChild(id: number): Promise<any> {
        var token = Cookies.get("JwtToken");
            return axios({
                method: "delete",
                baseURL: this.routes.server.root(),
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                url: this.routes.parent().deleteChild() + id,
            })
    }

    getKindergartenRequests(): Promise<any> {
        var token = Cookies.get("JwtToken");
            return axios({
                method: "get",
                baseURL: this.routes.server.root(),
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                url: this.routes.parent().getKindergartenRequests(),
            })
    }

    deleteKindergartenRequests(id: number): Promise<any> {
        var token = Cookies.get("JwtToken");
            return axios({
                method: "delete",
                baseURL: this.routes.server.root(),
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                url: this.routes.parent().deleteKindergartenRequests() + id,
            })
    }

    getProfile(): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.parent().getProfile(),
        })
    }

    updateProfile(data: FormData): Promise<any> {
        var token = Cookies.get("JwtToken");
        //const formData = new FormData();
            return axios({
                method: "put",
                data: data,
                baseURL: this.routes.server.root(),
                headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${token}` },
                url: this.routes.parent().updateProfile(),
            })
    }

    addChildsRequests(data: FormData): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${token}` },
            url: this.routes.parent().addChildsRequest(),
            data: data
        })
    }
    addNewKindergartensToChild(data: ChildIdAndKindergarten): Promise<any> {
        var token = Cookies.get("JwtToken");
            return axios({
                method: "post",
                data: data,
                baseURL: this.routes.server.root(),
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                url: this.routes.parent().addNewKindergartensToChild(),
            })
    }
    stripeSubscribe(data: any): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.parent().stripeSubscribe(),
            data: data
        });
    }
}
