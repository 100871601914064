import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import loginBg from "assets/images/bg/login-sign-up-bg.png";
import clsx from "clsx";
import { AppDialog, AppTable } from "components";
import { handleRowExpand } from "globals/general-helper";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CareHoursKindergartenRequests, GenderKindergartenRequests, KindergartenRegistrationStatus, KindergartenRequestsModal } from "sdk";
import { SuperAdminService } from "services/SuperAdminService";
import { COLOR_BOX_SHADOW, COLOR_PRIMARY, COLOR_WHITE } from "theme";
import { KindergartenRequestsColumns, KindergartenRequestsExpendedRow } from "../Partials";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        title: {
            marginBottom: theme.spacing(3),
            textAlign: "center",
        },
        dialogContainer: {
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            marginLeft: "250px",
        },
        dialogContainerEmpty: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        },
        dialogBody: {
            height: "80vh",

            maxWidth: "400px",
            [theme.breakpoints.up("xll")]: {
                maxWidth: "600px",
            },
        },
        fullWidth: { width: "100%" },


    }),
);

const conditionalRowStyles = [
    {
        when: (row: KindergartenRequestsModal) =>
            row.RowExpandedToggle === true,
        style: {
            backgroundColor: COLOR_WHITE,
            color: COLOR_PRIMARY,
            boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
            borderRadius: "10px",
        },
    },
];
const serviceAdmin = new SuperAdminService();

export interface KindergartenRequestsDialogProps {
    open: boolean;
    handleClose: () => void;
    redirectPath: string;
    kindergartenId: number;
}

export const KindergartenRequestsDialog: React.FC<KindergartenRequestsDialogProps> = ({
    handleClose,
    open,
    redirectPath,
    kindergartenId

}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [state, setState] = useState<KindergartenRequestsModal[]>([]);
    const onRowExpandClick = (id: number) => {
        setState([...handleRowExpand(id, state)]);
    };

    useEffect(() => {
        console.log("state")
        console.log(state)

    }, [state]);


    useEffect(() => {
        serviceAdmin.kindergartenRequests(kindergartenId)
            .then(function (response) {
                console.log(response);
                const u: KindergartenRequestsModal[] = [];
                response.data.forEach((element: any) => {
                    let date = moment(element.birthDate).format('DD.MM.YYYY');
                    let kindergartenRequests: KindergartenRequestsModal = {
                        Id: element.kindergartenRequestId,
                        childId: element.childId,
                        firstname: element.firstname,
                        lastname: element.lastname,
                        gender: element.gender === 1 ? GenderKindergartenRequests.MALE : GenderKindergartenRequests.FEMALE,
                        birthDate: date,
                        parentId: element.parentId,
                        parentFirstName: element.parentFirstName,
                        parentLastName: element.parentLastName,
                        supportStart: moment(element.supportStart).format('DD.MM.YYYY'),
                        careHour: element.careHour === 1 ? CareHoursKindergartenRequests.FOUR_TO_FIVE : (element.careHour === 2 ? CareHoursKindergartenRequests.FIVE_TO_SEVEN : (element.careHour === 3 ? CareHoursKindergartenRequests.SEVEN_T0_NINE : CareHoursKindergartenRequests.NINE_TO_TWELVE)),
                        message: element.mesasge,
                        kindergartenRequestId: element.kindergartenRequestId,
                        status: element.status === 1 ? KindergartenRegistrationStatus.ACCEPTED : (element.status === 2 ? KindergartenRegistrationStatus.PENDING : KindergartenRegistrationStatus.REJECTED),
                    };
                    u.push(kindergartenRequests);
                });
                setState(u);
            })
            .catch(function (error) {
                console.log("API ERROR");
                console.log(error);
            })
    }, []);


    return (
        <div className={classes.root}>
            <AppDialog
                withBackgroundImage={true}
                open={open}
                closeAfterTransition={true}
                handleClose={handleClose}
                bgImageUrl={loginBg}
                bgImageHeight={"100vh"}>
                <div className={clsx((state.length === 0) && classes.dialogContainerEmpty, (state.length !== 0) && classes.dialogContainer)}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.dialogBody}>
                        <Grid container direction="column" spacing={2}>
                            <div className={classes.root}>
                                <Typography className={classes.title} variant="h4">
                                    Kindergarten Request
                                </Typography>

                                <AppTable
                                    expandableRows
                                    columns={KindergartenRequestsColumns(t, onRowExpandClick)}
                                    expandableRowExpanded={(row) => row.RowExpandedToggle === true}
                                    data={state}
                                    conditionalRowStyles={conditionalRowStyles}
                                    expandOnRowClicked
                                    onRowExpandToggled={(
                                        expanded: boolean,
                                        row: KindergartenRequestsModal,
                                    ) => onRowExpandClick(row.kindergartenRequestId as number)}
                                    expandableRowsComponent={(t) => (
                                        <KindergartenRequestsExpendedRow
                                            data={t.data}
                                        />
                                    )}
                                />

                            </div>
                        </Grid>
                    </Grid>
                </div>
            </AppDialog >
        </div >
    );
};

export default KindergartenRequestsDialog;
