import { EditOutlined } from "@material-ui/icons";
import { ReactComponent as RectangleBlue } from "assets/images/icons/rectangle-blue.svg";
import { ReactComponent as RectangleGreen } from "assets/images/icons/rectangle-green.svg";
import { AppTableActionMenu, AppTableExpandableIcon } from "components";
import memoizeOne from "memoize-one";
import { TableColumn } from "react-data-table-component";
import { TFunction } from "react-i18next";
import Select, { components } from 'react-select';
import { AllKindergartensModal, KindergartenStatus } from "sdk";

//npm i --save react-select

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '""',
        display: 'block',
        marginRight: 0,
        height: 10,
        width: 30,

    },
});

const colourStyles = {
    control: (styles: any) => ({
        ...styles,
        backgroundColor: 'transparent',
        border: 0,
        boxShadow: 'none',
        minWidth: 200,
        textAlign: "left",
        marginLeft: 5,

    }),
    option: (styles: any) => {
        return {
            ...styles,
            backgroundColor: "white",
            color: "black",

            ':hover': {
                ...styles[':active'],
                backgroundColor: "white",
                color: "blue",
            },

            ':active': {
                ...styles[':active'],
                backgroundColor: "white",
                color: "blue",
            },
        };
    },
    menuPortal: (base: any) => ({ ...base, zIndex: '9999' }),
    menu: (base: any, styles: any) => ({
        ...base,
        width: "max-content",
        minWidth: "70%",
        color: "blue",
        textAlign: "left",
        justifyContent: "left",
        justifyItems: "left",
        border: 0,
        boxShadow: 'none',
    }),
    input: (styles: any) => ({ ...styles, ...dot() }),
};

export const optionsForKindergarten = [
    { value: KindergartenStatus.REVIEWED, label: KindergartenStatus.REVIEWED, color: <RectangleGreen /> },
    { value: KindergartenStatus.PENDING, label: KindergartenStatus.PENDING, color: <RectangleBlue /> },
]

export const AllKindergartensColumns = memoizeOne(
    (
        t: TFunction<"translation">,
        onRowExpandClick: (id: number) => void,
        onDeleteItem: (id: number) => void,
        inputChange: (input: KindergartenStatus, data: AllKindergartensModal) => void,

    ): TableColumn<AllKindergartensModal>[] => {

        return [

            {
                selector: (row) => row.kindergartenName,
                name: t("superAdmin.kindergartenList.kindergartenName.title"),
                minWidth: "300px",
                grow: 1,
            },
            {
                selector: (row) => row.address,
                name: t("superAdmin.kindergartenList.address.title"),
                minWidth: "300px",
                grow: 1,
            },
            {
                selector: (row) => row.requestsCount,
                name: t("superAdmin.column.requests"),
                minWidth: "30px",
                grow: 1,
            },
            {
                selector: (row) => row.isRegistered,
                name: t("superAdmin.column.registered"),
                minWidth: "30px",
                grow: 1,
            },
            {
                selector: (row) => row.kindergartenStatus,
                grow: 1,
                minWidth: "220px",
                name: t("superAdmin.column.status.status"),
                cell: (row) => {
                    return (
                        <Select
                            options={optionsForKindergarten}
                            value={{ value: row.kindergartenStatus, label: row.kindergartenStatus, color: <> </> }}
                            styles={colourStyles}
                            onChange={(input) => {
                                inputChange(input?.value as KindergartenStatus, row)
                            }}
                            components={{
                                IndicatorSeparator: () => null,
                                Control: ({ children, ...rest }) => (
                                    <components.Control {...rest} >
                                        <EditOutlined style={{ marginLeft: 5, marginRight: 5 }} /> {children}
                                    </components.Control>
                                )
                            }}
                            isSearchable={false}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}

                            formatOptionLabel={
                                ({ label, color }, { context }) => {
                                    if (context === "value") {
                                        return <div> {t(`superAdmin.column.kindergartenRequest.${label?.toString()}`)} </div>;
                                    } else if (context === "menu") {
                                        return (
                                            <>
                                                <div>
                                                    <div style={{ display: "inline-block" }}>
                                                        <div>
                                                            {color}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "inline-block", marginLeft: 15 }}>
                                                        <div>
                                                            {t(`superAdmin.column.kindergartenRequest.${label?.toString()}`)}
                                                        </div>
                                                    </div>

                                                </div>

                                            </>
                                        );
                                    }
                                }}
                        />
                    );
                },

            },
            {
                width: "50px",
                center: true,
                cell: (row, index, column) => {
                    return <AppTableActionMenu hideEdit={true} id={row.kindergartenId as number} onDeleteItem={onDeleteItem} />;
                },
            },
            {
                width: "50px",
                cell: (row, index, column) => (
                    <AppTableExpandableIcon
                        id={row.Id as number}
                        toggle={row.RowExpandedToggle === true}
                        onRowExpandClick={onRowExpandClick}
                    />
                ),
            },

        ];
    },
);









