import {
    Grid,
    GridJustification,
    GridSpacing,
    Typography,
    TypographyProps,
} from "@material-ui/core";
import React from "react";
export interface HomeDetailWithIconProps {
    iconUrl: React.ReactNode;
    text?: string;
    title?: string;
    minWidth?: string;
    iconPosition?: "top" | "left";
    gridSpacing?: GridSpacing;
    justifyContent?: GridJustification;
    textProps?: TypographyProps;
    titleProps?: TypographyProps;
    children?: React.ReactNode;
}

export const AppDetailsWithIcon: React.FC<HomeDetailWithIconProps> = ({
    iconUrl,
    text,
    title,
    gridSpacing = 1,
    iconPosition = "top",
    minWidth,
    justifyContent = "center",
    textProps,
    titleProps,
    children,
}) => {
    return (
        <Grid
            container
            direction={iconPosition === "top" ? "column" : "row"}
            justifyContent={justifyContent}
            style={{ minWidth: minWidth }}
            spacing={gridSpacing}>
            <Grid item>{iconUrl}</Grid>
            <Grid item>
                <Grid container direction="column" justifyContent="center">
                    {title && (
                        <Grid item>
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                {...titleProps}>
                                {title}
                            </Typography>
                        </Grid>
                    )}
                    {text && (
                        <Grid item>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                {...textProps}>
                                {text}
                            </Typography>
                        </Grid>
                    )}
                    {children && children}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AppDetailsWithIcon;
