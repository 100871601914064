import { FormLabel, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ReactComponent as FileUpload } from "assets/images/icons/FileUpload.svg";
import { AppBackButtonWithBackwardArrow } from "components";
import { AppControlProps } from "globals";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: "none",
        },
        image: {
            width: 25,
            height: 30,
            marginRight: theme.spacing(0.5),
        },
        label: {
            display: "flex",
            alignItems: "center",
            marginLeft: theme.spacing(4),
        },
    }),
);
export interface AppFileUploaderProps
    extends React.DetailedHTMLProps<
            React.InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
        >,
        AppControlProps {}
export const AppFileUploader = React.memo<AppFileUploaderProps>(
    ({ control, rules, name, shouldUnregister, ...rest }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const fileRef = React.useRef<any>(null);
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item>
                        <AppBackButtonWithBackwardArrow
                            text={t("common.chooseFile")}
                            onClick={() =>
                                fileRef &&
                                fileRef.current &&
                                fileRef.current.click &&
                                fileRef.current.click()
                            }
                            startIcon={<FileUpload className={classes.image} />}
                        />
                    </Grid>
                    <Grid item className={classes.label}>
                        <Controller
                            name={name ? name : ""}
                            rules={rules}
                            shouldUnregister={shouldUnregister}
                            control={control}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => {
                                return (
                                    <>
                                        <input
                                            className={classes.input}
                                            {...rest}
                                            type="file"
                                            ref={fileRef}
                                            onChange={(e) =>
                                                onChange(
                                                    e &&
                                                        e.target &&
                                                        e.target.files &&
                                                        e.target.files,
                                                )
                                            }
                                        />
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Typography variant="body1">
                                                    {value && value.length > 0
                                                        ? value.length === 1
                                                            ? `${
                                                                  value.length
                                                              } ${t(
                                                                  "common.fileChosen",
                                                              )}`
                                                            : `${
                                                                  value.length
                                                              } ${t(
                                                                  "common.filesChosen",
                                                              )}`
                                                        : t(
                                                              "common.noFileChosen",
                                                          )}
                                                </Typography>
                                            </Grid>
                                            {error && error.message && (
                                                <Grid item>
                                                    <FormLabel error>
                                                        {error.message}
                                                    </FormLabel>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </>
                                );
                            }}></Controller>
                    </Grid>
                </Grid>
            </div>
        );
    },
);
export default AppFileUploader;
