import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { AppLabelWrapper } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { KindergartenRequestsModal } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        fullWidth: { width: "100%" },
    }),
);
export interface KindergartenRequestsExpendedRowSecondContainerProps {
    infoState: KindergartenRequestsModal;
}
export const KindergartenRequestsExpendedRowSecondContainer: React.FC<KindergartenRequestsExpendedRowSecondContainerProps> =
    ({ infoState }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        return (
            <Grid container spacing={2} className={classes.root}>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Support Start:"
                        value={infoState.supportStart}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Care Hour:"
                        value={infoState.careHour.toString()}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Message:"
                        value={infoState.message}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
            </Grid>
        );
    };

export default KindergartenRequestsExpendedRowSecondContainer;
