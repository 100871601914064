import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { ReactComponent as AddNewChildIcon } from "assets/images/icons/add-new-child.svg";
import { AppButton, AppTable } from "components";
import { handleRowExpand } from "globals/general-helper";
import { CareHours, Gender } from "globals/Types";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { appRoutesForContext } from "routing/react-route-paths";
import { RegistrationListColumns } from "screens";
import { RegistrationListModal, UpdateChildRequest } from "sdk";
import { ParentService } from "services/ParentService";
import {
    COLOR_BOX_SHADOW,
    COLOR_LIGTHEST_BLUE,
    COLOR_PRIMARY,
    COLOR_WHITE,
    FULL_VIEW_HEIGHT_WITHOUT_HEADER
} from "theme";
import { RegistrationListExpendedRow } from "./Partials";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: FULL_VIEW_HEIGHT_WITHOUT_HEADER,
            overflow: "auto",
            background: `linear-gradient(180deg, ${COLOR_WHITE} 10%, ${COLOR_LIGTHEST_BLUE} 100%)`,
            display: "flex",
            flexDirection: "column",
            paddingTop: theme.spacing(8),
            alignItems: "center",
            paddingRight: theme.spacing(25),
            paddingLeft: theme.spacing(25),
            [theme.breakpoints.down("lg")]: {
                paddingRight: theme.spacing(15),
                paddingLeft: theme.spacing(15),
            },
        },
        title: {
            marginBottom: theme.spacing(5),
        },
        addNewChildIcon: {
            width: "25px",
            height: "25px",
        },
    }),
);
const conditionalRowStyles = [
    {
        when: (row: RegistrationListModal) => row.RowExpandedToggle === true,
        style: {
            backgroundColor: COLOR_WHITE,
            color: COLOR_PRIMARY,
            boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
            borderRadius: "10px",
        },
    },
];

const service = new ParentService();
export const RegistrationList = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    let history = useHistory();
    const [state, setState] =
        useState<RegistrationListModal[]>([]);
    const onRowExpandClick = (id: number) => {
        setState([...handleRowExpand(id, state)]);
    };

    const onDeleteItem = (id: number) => {
        service.deleteChild(id)
            .then(function (response) {
                setState([
                    ...state.filter((x) => {
                        return x.Id !== id
                    }),
                ]);
            })
            .catch(function (error) {
                console.log("API ERROR:");
                console.log(error);
            })
    };

    const onSubmit = (value: RegistrationListModal) => {
        let updateRequest: UpdateChildRequest = {
            ChildId: value.Id as number,
            Firstname: value.FirstNameChild,
            Lastname: value.LastNameChild,
            Gender: value.Gender === "1" ? 1 : 2,
            Start: moment(value.Start),
            CareHour: value.CareHours === "1" ? 1 : (value.CareHours === "2" ? 2 : (value.CareHours === "3" ? 3 : 4)),
            birthDate: moment(value.BirthdayOfTheChild),
            Message: value.Message
        }

        service.updateChild(updateRequest)
            .then(function (response) {
                console.log(response);
                setState([
                    ...state.map((x) => {
                        if (x.Id === value.Id) {
                            return { ...value };
                        }
                        return x;
                    }),
                ]);
            })
            .catch(function (error) {
                console.log("API ERROR:");
                console.log(error);
            })
    };

    useEffect(() => {
        service.getChildsList()
            .then(function (response) {
                console.log(response);
                let x: RegistrationListModal[] = [];
                response.data.forEach((element: any) => {
                    let childItem: RegistrationListModal = {
                        Id: element.childId,
                        Date: element.date,
                        FirstNameChild: element.firstname,
                        LastNameChild: element.lastname,
                        Age: element.age,
                        Gender: element.gender === 1 ? Gender.MALE : Gender.FEMALE,
                        Start: element.start,
                        BirthdayOfTheChild: element.birthDate,
                        CareHours: element.careHour === 1 ? CareHours.FOUR_TO_FIVE : (element.careHour === 2 ? CareHours.FIVE_TO_SEVEN : (element.careHour === 3 ? CareHours.SEVEN_T0_NINE : CareHours.NINE_TO_TWELVE)),
                        SupportStart: element.start,
                        Message: element.message,
                    };
                    if (!state.some(e => e.Id === childItem.Id)) {
                        x.push(childItem);
                    }
                });
                let myArray = [...state, ...x];
                setState(myArray);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);

    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h4">
                {t("registrationList.title")}
            </Typography>
            <Grid
                style={{ marginBottom: 30, textAlign: "end", justifyContent: "end", justifyItems: "end" }}
                container
                alignItems="flex-end"
                justifyContent="space-between">
                <Grid item>
                    <AppButton
                        endIcon={
                            <AddNewChildIcon
                                className={classes.addNewChildIcon}
                            />
                        }
                        text={t("registrationForChildren.addNewChild")}
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                            history.replace(
                                appRoutesForContext().mapSearch()
                                    .mapSearchPath,
                            )
                        }
                    />
                </Grid>
            </Grid>
            <AppTable
                expandableRows
                columns={RegistrationListColumns(t, onRowExpandClick, onDeleteItem)}
                expandableRowExpanded={(row) => row.RowExpandedToggle === true}
                data={state}
                conditionalRowStyles={conditionalRowStyles}
                expandOnRowClicked
                onRowExpandToggled={(
                    expanded: boolean,
                    row: RegistrationListModal,
                ) => onRowExpandClick(row.Id as number)}
                expandableRowsComponent={(t) => (
                    <RegistrationListExpendedRow
                        data={t.data}
                        onSubmit={onSubmit}
                    />
                )}
            />
        </div>
    );
};

export default RegistrationList;
