import { RouteInterface } from "globals";
import { HomeLayout } from "layouts";
import { MapSearch } from "screens/MapSearch/MapSearch";
import {
    MapSearchRoutePath,
    MapSearchRouteWithParamsPath,
} from "./map-search-route-path";
export const MapSearchRoutesList: RouteInterface[] = [
    {
        component: MapSearch,
        exact: true,
        layout: HomeLayout,
        path: MapSearchRoutePath.path,
        uniqueKey: MapSearchRoutePath.key,
    },
    {
        component: MapSearch,
        exact: true,
        layout: HomeLayout,
        path: MapSearchRouteWithParamsPath.path,
        uniqueKey: MapSearchRouteWithParamsPath.key,
    },
];
