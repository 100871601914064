import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { AppLabelWrapper, AppTextField } from "components";
import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AllKindergartensModal } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        fullWidth: { width: "100%" },
    }),
);
export interface AllKindergartensExpendedRowSecondContainerProps {
    readonly: boolean;
    control: Control<AllKindergartensModal>;
    infoState: AllKindergartensModal;
}
export const AllKindergartensExpendedRowSecondContainer: React.FC<AllKindergartensExpendedRowSecondContainerProps> =
    ({ readonly, control, infoState }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        return (
            <Grid container spacing={2} className={classes.root}>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.email.title")}
                        value={infoState.email}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="email"
                            />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.address.title")}
                        value={infoState.address}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="address"
                           />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.country.title")}
                        value={infoState.country}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="country"
                            />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.city.title")}
                        value={infoState.city}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="city"
                            />
                    </AppLabelWrapper>
                </Grid>

                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.postalCode.title")}
                        value={infoState.postalCode}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="postalCode"
                            />
                    </AppLabelWrapper>
                </Grid>

                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.longitude.title")}
                        value={infoState.longitude}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="longitude"
                            />
                    </AppLabelWrapper>
                </Grid>

                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.latitude.title")}
                        value={infoState.latitude}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="latitude"
                            />
                    </AppLabelWrapper>
                </Grid>

            </Grid>
        );
    };

export default AllKindergartensExpendedRowSecondContainer;
