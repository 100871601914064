import { Theme } from "@material-ui/core";
import { TableStyles } from "react-data-table-component";
import {
    COLOR_GRAY,
    COLOR_LIGHT_BLUE_TABLE_HEADER_BG,
    COLOR_TEXT_DARK
} from "theme";
export const DefaultThemeForReactTableFacilities = (theme: Theme): TableStyles => ({
    table: {
        style: {
            background: "transparent",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },

    headRow: {
        style: {
            "&:nth-of-type(1)": {
                paddingLeft: theme.spacing(2),
            },
            color: COLOR_GRAY,
            fontSize: "1rem",
            minHeight: "30px !important",
            height: "35px !important",
            maxHeight: "35px !important",
            backgroundColor: COLOR_LIGHT_BLUE_TABLE_HEADER_BG,
            borderRadius: "10px",
            border: 0,
            marginBottom: theme.spacing(2),
        },
    },

    rows: {
        style: {
            backgroundColor: "transparent",
          //  color: 'white',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.2)"

            },
            borderBottomWidth: "0px !important",
            color: COLOR_TEXT_DARK,
            paddingLeft: theme.spacing(2),
        },
    },

    expanderRow: {
        style: {
            backgroundColor: "transparent",
        },
    },

    cells: {
        style: {
            // "&:nth-of-type(1)": {
            //     paddingLeft: theme.spacing(4),
            // },
            paddingLeft: theme.spacing(2),
            height: "60px",
            fontSize: "1rem",

            border: 0,
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
            "&:active": {
                border: 0,
            },
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
        },
    },
});
