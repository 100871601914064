export const RegistrationForParentEnTranslations = {
    registrationForParent: {
        title: "Your Registration",
        orRegistrationWith: "Or register with:",
        ifYouContinue: "If you continue you accept our",
        terms: "Terms of Use",
        privacy: "Privacy Policy",
        step1: {
            title: "Register via mail or social networks",
            email: {
                title: "E-Mail-Adresse",
                missing: "E-Mail-Adresse is missing",
                invalid: "E-Mail-Adresse is invalid",
            },
        },
        step2: {
            title: "Create a password",
        },
        step3: {
            title: "Please enter your personal data.",
            yourFirstName: {
                title: "Your First Name",
                placeHolder: "Enter First Name",
                missing: "First Name is missing",
            },
            yourLastName: {
                title: "Your Last Name",
                placeHolder: "Enter Last Name",
                missing: "Last Name is missing",
            },
            dataOfBirth: {
                title: "Date of Birth",
                placeHolder: "Enter Date of Birth",
                missing: "Date of Birth is missing",
            },
            address: {
                title: "Address",
                missing: "Address is missing",
            },
            country: {
                title: "Country",
                missing: "Country is missing",
            },
            street: {
                title: "Street",
                missing: "Street is missing",
            },
            houseNumber: {
                title: "House number",
                missing: "House number is missing",
            },
            postalCode: {
                title: "Postal Code",
                missing: "Postal Code is missing",
            },
            city: {
                title: "City",
                missing: "City is missing",
            },
            phoneNumber: {
                title: "Phone Number",
                placeHolder: "Enter phone number",
                missing: "Phone number is missing",
            },
        },
    },
};
export default RegistrationForParentEnTranslations;
