export const HomeDeTranslations = {
    home: {
        home: "Startseite",
        yourKitaplatz: "kitaplatz.",
        stressFree: "Stressfrei & Transparent",
        findingPlace:
            "Einen geeigneten Kitaplatz zu finden war für Eltern bisher ein frustrierender Prozess.",
        weAreChangingThat: "Wir ändern das.",
        zipCode: "Postleitzahl",
        address: "Strasse - Hausnummer",
        compileKitaWishlist: "01.Kita-Wunschliste zusammenstellen",
        applyDirectlyOnline: "02.Direkt Online bewerben",
        ViewApplicationStatus: "03.Bewerbungsstatus einsehen",
        overviewTitle: "Übersicht aller Kitas und Wunschliste zusammenstellen",
        overviewBody1:
            "Wir durchsuchen alle gemeldeten Kitas für dich. Nur wenige Angaben zu dir und deinem Kind liefern passende Kita Vorschläge.",
        overviewBody2:
            "Du kannst dabei Kitas nach deinen Vorlieben filtern (z.B. Größe, Konzept, Entfernung, etc.). Zusätzlich erhältst du aktuelle Medlungen zu deinen Wunschkitas (z.B. Tag der offenen Tür).",
        createApplicationTitle:
            "Bewerbung einmalig anlegen & n ausgewählte Kitas abschicken",
        createApplicationBody1:
            "Anstelle individuelle Anmeldeformulare jeder Kita einzeln auszufüllen und abzuschicken brauchst du von nun an nur noch deine Bewerbungsunterlagen einmalig anlegen.",
        createApplicationBody2:
            "Wir verschicken deine Unterlagen an die von dir ausgewählten Kitas. Vollkommen automatisiert.",
        applicationStatus: "Bewerbungsstatus zentralisiert einsehen",
        applicationStatusBody1:
            "Nachdem deine Bewerbungen abgeschickt wurden, siehst du ob sich Kitas für dich entschieden haben oder ob du auf Wartelisten stehst.",
        applicationStatusBody2:
            "Auch wenn du keinen Platz bekommen hast: Sobald eine Kita wieder einen Platz frei hat, schicken wir automatisch eine Erinnerung an die Kita mit deinen Unterlagen.",
        compareToTheAlternative: "Im Vergleich zur Alternative",
        compareToBody1: "Es herscht ein Mangel an Alternativen.",
        compareToBody2:
            "Im Normalfall man darf sich durch den langwierigen und manuellen Prozess der Kitasuche quälen.",
        centralized: "Zentralisiert",
        centralizedBody:
            "Auf Kitaplatz.de hast kannst du deine Bewerbungen an alle Wunsch-Kitas einsehen und verwalten.",
        lowEffort: "Geringer Aufwand",
        lowEffortBody:
            "Kitas suchen, Wunschliste zusammenstellen und direkt online Bewerben war noch nie so einfach.",
        history: "History",
        historyBody:
            "Da alles bei zentralisiert ist, ist es sehr einfach für dich, nachzuverfolgen wann du dich wo beworben hast.",
        feedback: "Rückmeldungen",
        feedbackBody:
            "Das Kitaplatz.de Online-Bewerbungsverfahren macht es für Kitas einfach dir schnelle Rückmeldung zu geben.",
        weCantConjure: "Wir können keine Kitaplätze herbeizaubern.",
        everyThingElseDose: "Alles andere schon.",
        weCantConjureBody:
            "Auch wenn du keinen Platz bekommen hast: Sobald eine Kita wieder einen Platz frei hat, schicken wir automatisch eine Erinnerung an die Kita mit deinen Unterlagen.",
        areaSearch: "Umkreissuche"
    },
};
export default HomeDeTranslations;
