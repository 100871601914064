import React from "react";
import { useHistory, useParams } from "react-router-dom";
import AddNewFacilitiesViewDialog from "./AddNewFacilitiesViewDialog";

export interface AddNewFacilitiesViewParams {
    redirectPath: string;
}

export const AddNewFacilitiesView = () => {
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const { redirectPath } = useParams<AddNewFacilitiesViewParams>();

    const handleClose = () => {
        setOpen(false);
        history.push(redirectPath.replaceAll("-", "/"));
    };

    return (
        <AddNewFacilitiesViewDialog
            open={open}
            handleClose={handleClose}
            redirectPath={redirectPath}
        />
    );
};

export default AddNewFacilitiesView;
