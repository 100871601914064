import { RouteInterface } from "globals/ReactRouter";
import { EmptyLayout } from "layouts";
import { Login } from "screens/Auth/Login";
import { PasswordForgotten, ResetPassword } from "screens/Auth/PasswordForgotten";
import { Register, RegisterSuccess, RegisterSuccessBeforeEmail } from "screens/Auth/Register";
import { LoginPath, PasswordForgottenPath, RegisterPath, RegisterSuccessBeforeEmailPath, RegisterSuccessPath, ResetPasswordPath } from "./auth-routes-path";

export const AuthRoutesList: RouteInterface[] = [
    {
        component: Login,
        exact: true,
        layout: EmptyLayout,
        path: LoginPath.path,
        uniqueKey: LoginPath.key,
    },
    {
        component: Register,
        exact: true,
        layout: EmptyLayout,
        path: RegisterPath.path,
        uniqueKey: RegisterPath.key,
    },
    {
        component: PasswordForgotten,
        exact: true,
        layout: EmptyLayout,
        path: PasswordForgottenPath.path,
        uniqueKey: PasswordForgottenPath.key,
    },
    {
        component: ResetPassword,
        exact: true,
        layout: EmptyLayout,
        path: ResetPasswordPath.path,
        uniqueKey: ResetPasswordPath.key,
    },
    {
        component: RegisterSuccess,
        exact: true,
        layout: EmptyLayout,
        path: RegisterSuccessPath.path,
        uniqueKey: RegisterSuccessPath.key,
    },
    {
        component: RegisterSuccessBeforeEmail,
        exact: true,
        layout: EmptyLayout,
        path: RegisterSuccessBeforeEmailPath.path,
        uniqueKey: RegisterSuccessBeforeEmailPath.key,
    },
];
