import {
    makeStyles,
    StandardProps,
    Typography,
    TypographyProps,
} from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { AppTextMarker, AppTextMarkerProps } from "components";
import { StyleClassKey } from "globals";
import React from "react";
import { COLOR_SECONDARY } from "theme";
import {
    AppCircleWithCounterProps,
    AppCircleWithCounter,
} from "./AppCircleWithCounter";

const styles = () =>
    createStyles({
        root: { display: "flex" },
        detailsContainer: { marginTop: "52px", marginLeft: "-25px" },
    });

type AppCircleWithCounterAndDetailsClassKey = StyleClassKey<typeof styles>;
const useStyles = makeStyles(styles);
export interface AppCircleWithCounterAndDetailsProps
    extends StandardProps<
        AppCircleWithCounterProps,
        AppCircleWithCounterAndDetailsClassKey
    > {
    title: string;
    children?: React.ReactNode;
    titleProps?: TypographyProps;
    titleMarkerProps?: AppTextMarkerProps;
}
export const AppCircleWithCounterAndDetails: React.FC<AppCircleWithCounterAndDetailsProps> =
    ({ title, children, titleProps, titleMarkerProps, ...rest }) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                <div>
                    <AppCircleWithCounter {...rest} />
                </div>
                <div className={classes.detailsContainer}>
                    <AppTextMarker
                        padding="10px 5px 5px 5px"
                        bgClr={COLOR_SECONDARY}
                        {...titleMarkerProps}>
                        <Typography
                            variant="h6"
                            component="span"
                            {...titleProps}>
                            {title}
                        </Typography>
                    </AppTextMarker>
                    {children}
                </div>
            </div>
        );
    };

export default AppCircleWithCounterAndDetails;
