import { createStyles, makeStyles, Theme } from "@material-ui/core";
import img1 from "assets/images/bg/select-all-results-bg.png";
import img2 from "assets/images/bg/login-sign-up-bg.png";
import { AppDialog, AppDialogBack } from "components";
import AppStepperFacilitiesMain from "components/Stepper/AppStepperFacilitiesMain";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { appRoutesForContext } from "routing/react-route-paths";
import { getMultiStepRegistrationFacilitiesInitialValue, MultiStepRegistrationFacilities } from "sdk";
import FacilitiesStep1 from "./FacilitiesStep1";
import FacilitiesStep2 from "./FacilitiesStep2";
import FacilitiesStep3 from "./FacilitiesStep3";
import FacilitiesStep4 from "./FacilitiesStep4";
import FacilitiesStep5 from "./FacilitiesStep5";



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: "100vh",
            width: "100%",
            overflow: "auto",
        },
        body: { width: "100%", marginTop: theme.spacing(0) },

        title: {
            marginBottom: theme.spacing(2),
        },
        stepperContainer: {
            marginTop: theme.spacing(0),
            paddingRight: theme.spacing(10),
            paddingLeft: theme.spacing(10),
            paddingBottom: theme.spacing(10),
            display: "flex",
            justifyContent: "center",
            width: "100%",
        },
        stepperBody: {
            width: "100%",
            maxWidth: "700px",
        },
        container: {
            width: "100%",
            //marginTop: theme.spacing(0),
           // marginBottom: theme.spacing(0),
        },
        stepperLabel: {
            width: "100%",
            maxWidth: "800px",
        },
    }));

export interface FacilitiesStepsDiapogProps {
    open: boolean;
    onClose: () => void;
    handleFormCompleted: () => void;
    handleStateSet: () => void;
}

export const FacilitiesStepsDialog: React.FC<FacilitiesStepsDiapogProps> =
    ({ onClose, open, handleFormCompleted, handleStateSet }) => {

        const classes = useStyles();
        const { t } = useTranslation();
        const totalSteps = 5;
        const [state, setState] = useState<MultiStepRegistrationFacilities>(
            getMultiStepRegistrationFacilitiesInitialValue()
        );
        const onStateSetHandler = (value: MultiStepRegistrationFacilities) => {
            setState(value);
            console.log("Facilities");
            console.log(value);
        };

        const [activeStep, setActiveStep] = React.useState(0);
        const [img, setImg] = React.useState(img1);
        const [selected, setSelected] = React.useState(true);
        let history = useHistory();
        const close = () => {
            history.replace(
                appRoutesForContext()
                    .public().homePath
            );
        };
        const changePitcture = () => {
            setImg(img1);
        }
        const handleNext = () => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };

        const handleBack = () => {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };

        useEffect(() => {
            if (activeStep === 0 || activeStep === 3) {
                setImg(img1);
                activeStep === 0 ? setSelected(true) : setSelected(false);

            } else if (activeStep === 1 || activeStep === 2 || activeStep === 4) {
                setImg(img2);
            }
        }, [activeStep]);



        const steps: React.ReactNode[] = [
            <FacilitiesStep1
                activeStep={activeStep}
                handleNext={() => {
                    handleNext();
                    setSelected(false);
                }}
                handleBack={handleBack}
                stepsLength={totalSteps}
            />,

            <FacilitiesStep2
                activeStep={activeStep}
                handleNext={handleNext}
                handleBack={handleBack}
                stepsLength={totalSteps}
                handleStateSet={onStateSetHandler}
                parentState={state}
                changePicture={changePitcture}
            />,
            <FacilitiesStep3
                activeStep={activeStep}
                handleNext={handleNext}
                handleBack={handleBack}
                stepsLength={totalSteps}
                handleStateSet={onStateSetHandler}
                parentState={state}
            />,
            <FacilitiesStep4
                activeStep={activeStep}
                handleNext={handleNext}
                handleBack={handleBack}
                stepsLength={totalSteps}
                handleStateSet={onStateSetHandler}
                parentState={state}
            />,
            <FacilitiesStep5
                activeStep={activeStep}
                handleNext={close}
                handleBack={handleBack}
                stepsLength={totalSteps}
                handleStateSet={onStateSetHandler}
                parentState={state}
            />,
        ];

        return (<div>
            {selected && (
                <AppDialog
                    open={open}
                    className={classes.root}
                    handleClose={onClose}
                    scroll="body"
                    bgImageUrl={img}
                    bgImageHeight={"100vh"}
                    withBackgroundImage={true}
                    classes={{
                        root: classes.root,
                        container: classes.container,
                    }} >
                    <div className={classes.body}>
                        <div className={classes.stepperContainer}>
                            <div className={classes.stepperBody}>
                                <AppStepperFacilitiesMain
                                    components={steps}
                                    className={classes.stepperLabel}
                                    activeStep={activeStep}
                                />
                            </div>
                        </div>
                    </div>
                </AppDialog>
            )}

            {!selected && (
                <AppDialogBack
                    open={open}
                    className={classes.root}
                    handleBack={handleBack}
                    scroll="body"
                    bgImageUrl={img}
                    bgImageHeight={"100vh"}
                    withBackgroundImage={true}
                    classes={{
                        root: classes.root,
                        container: classes.container,
                    }} >

                    <div className={classes.body}>
                        <div className={classes.stepperContainer}>
                            <div className={classes.stepperBody}>
                                <AppStepperFacilitiesMain
                                    components={steps}
                                    className={classes.stepperLabel}
                                    activeStep={activeStep}
                                />
                            </div>
                        </div>
                    </div>
                </AppDialogBack>
            )}
        </div>
        );
    };

export default FacilitiesStepsDialog;
