import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { ResetPasswordDialog } from "./ResetPasswordDialog";
export interface ResetPasswordParams {
    redirectPath: string;
}

export const ResetPassword = () => {
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const { redirectPath } = useParams<ResetPasswordParams>();
    const handleClose = () => {
        setOpen(false);
        history.push(redirectPath.replaceAll("-", "/"));
    };
    return (
        <ResetPasswordDialog
            open={open}
            handleClose={handleClose}
            redirectUrl={redirectPath}
            redirectPath={redirectPath}
        />
    );
};

export default ResetPassword;
