import { RoutePathInterface } from "globals";
import { appRoutesForContext } from "routing/react-route-paths";

export const RegistrationListRoutePath: RoutePathInterface = {
    path: appRoutesForContext().registrationList().registrationListPath,
    key: [],
};
export const KindergartenRegistrationListRoutePath: RoutePathInterface = {
    path: appRoutesForContext().registrationList()
        .kindergartenRegistrationListPath,
    key: [],
};
export const ChildsRequestsListRoutePath: RoutePathInterface = {
    path: appRoutesForContext().registrationList()
        .childsRequestsListPath,
    key: [],
};
export const RegistrationListRoutePathList: RoutePathInterface[] = [
    RegistrationListRoutePath,
    KindergartenRegistrationListRoutePath,
    ChildsRequestsListRoutePath,

];
;
