import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { AppLabelWrapper } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { AllParentsModal } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        fullWidth: { width: "100%" },
    }),
);
export interface AllParentsExpendedRowSecondContainerProps {
    infoState: AllParentsModal;
}
export const AllParentsExpendedRowSecondContainer: React.FC<AllParentsExpendedRowSecondContainerProps> =
    ({ infoState }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        return (
            <Grid container spacing={2} className={classes.root}>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Phone Number"
                        value={infoState.phoneNumber}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Address"
                        value={infoState.address}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Postal Code"
                        value={infoState.postalCode}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="City"
                        value={infoState.city}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Country"
                        value={infoState.country}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
            </Grid>
        );
    };

export default AllParentsExpendedRowSecondContainer;
