import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { AppPasswordTextField, AppTextField } from "components";
import { EMAIL_VALIDATION_PATTERN, PASSWORD_VALIDATION_PATTERN } from "globals/constants";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Register } from "sdk";

// import React, { useState } from "react";
// import { getRegisterInitialValues, Register } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(5),
        },
    }),
);
export interface RegisterClientProps {
    control: Control<Register>;
    password?: string;
}
export const RegisterClient: React.FC<RegisterClientProps> = ({
    control,
    password = "123",
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={6}>
                <AppTextField
                    placeholder={t("auth.firstName.firstName")}
                    name="FirstName"
                    control={control}
                    rules={{
                        required: t(
                            "auth.firstName.firstNameMissing",
                        ) as string,
                    }}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={6}>
                <AppTextField
                    placeholder={t("auth.email.title")}
                    name="Email"
                    control={control}
                    rules={{
                        required: t("auth.email.Missing") as string,
                        pattern: {
                            value: EMAIL_VALIDATION_PATTERN,
                            message: t("auth.email.emailInvalid") as string,
                        },
                    }}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={6}>
                <AppTextField
                    placeholder={t("auth.lastName.lastName")}
                    name="LastName"
                    control={control}
                    rules={{
                        required: t("auth.lastName.lastNameMissing") as string,
                    }}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={6}>
                <AppPasswordTextField
                    control={control}
                    name="Password"
                    type="password"
                    rules={{
                        required: t("auth.password.missing") as string,
                        pattern: {
                            value: PASSWORD_VALIDATION_PATTERN,
                            message: t(
                                "auth.password.invalid",
                            ) as string,
                        },
                    }}
                    placeholder={t("auth.password.title")}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={6}>
                <AppTextField
                    placeholder={t("auth.companyName.companyName")}
                    name="CompanyName"
                    control={control}
                    rules={{
                        required: t(
                            "auth.companyName.companyNameMissing",
                        ) as string,
                    }}
                    fullWidth={true}
                />
            </Grid>
            <Grid item xs={6}>
                <AppPasswordTextField
                    control={control}
                    name="ConfirmPassword"
                    type="password"
                    rules={{
                        required: t("auth.confirmPassword.missing") as string,
                        validate: (value: string) =>
                            value === password ||
                            (t("auth.confirmPassword.notMatched") as string),
                    }}
                    placeholder={t("auth.confirmPassword.title")}
                    fullWidth={true}
                />
            </Grid>
        </Grid>
    );
};

export default RegisterClient;
