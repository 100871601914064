import { User } from "./../sdk/models/User";
import { CookiesKey } from "globals/Types";
import Cookies from "js-cookie";
import { LoginResponse } from "sdk";
interface CookiesOption {
    expires?: Date;
    path?: string;
    domain?: string;
    secure?: boolean;
}

export const getCookie = (key: string): string | undefined => {
    return Cookies.get(key);
};
export const setCookie = (
    key: string,
    value: any,
    option?: CookiesOption | undefined,
) => {
    Cookies.set(key, value, option);
};
export const removeCookie = (key: string): void => {
    Cookies.remove(key);
};
export const cookieAvailable = (key: CookiesKey): boolean => {
    if (getCookie(key.toString()) !== undefined) {
        return true;
    } else {
        return false;
    }
};

export const createUserCookies = (User: User) => {
    var today = new Date();
    setCookie(CookiesKey.USER_COOKIE, JSON.stringify(User), {
        expires: new Date(today.setDate(today.getDate() + 1)),
    });
    setCookie(CookiesKey.JWT_TOKEN, User.jwtToken, {
        expires: new Date(today.setDate(today.getDate() + 1)),
    });
};

export const createLoginCookies = (Token: string) => {
    var today = new Date();
    setCookie(CookiesKey.JWT_TOKEN, Token, {
        expires: new Date(today.setDate(today.getDate() + 1)),
    });
};
