import {
    createStyles, makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import React from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { RegistrationListExpendedRow } from "screens/RegistrationList";
import { KindergartenRequestsModal } from "sdk";
import KindergartenRequestsExpendedRowFirstContainer from "./KindergartenRequestsExpendedRowFirstContainer";
import KindergartenRequestsExpendedRowSecondContainer from "./KindergartenRequestsExpendedRowSecondContainer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: "50px",
            paddingRight: "20px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
        },
        title: { paddingBottom: theme.spacing(3) },
        body: {
            display: "flex",
            width: "100%",
        },

        saveButton: { marginTop: theme.spacing(5) },
    }),
);
export interface KindergartenRequestsExpendedRowProps
    extends ExpanderComponentProps<KindergartenRequestsModal> {
}
export const KindergartenRequestsExpendedRow: React.FC<KindergartenRequestsExpendedRowProps> =
    ({ data }) => {
        const { t } = useTranslation();
        const classes = useStyles();

        return (
            <div className={classes.root}>
                <div style={{ maxWidth: "1200px" }}>
                    <div className={classes.title}>
                        <Typography variant="h5">
                            Information about request
                        </Typography>
                    </div>
                    <div className={classes.body}>
                        <KindergartenRequestsExpendedRowFirstContainer
                            infoState={data}
                        />

                        <KindergartenRequestsExpendedRowSecondContainer
                            infoState={data}
                        />
                    </div>
                </div>
            </div>
        );
    };
export default RegistrationListExpendedRow;
