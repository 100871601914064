import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import i18n (needs to be bundled ;))
import "./localization/i18next";
import "./index.css";
import * as Sentry from "@sentry/react";
import config from "config";

import { Integrations } from "@sentry/tracing";
import { loadScript } from "globals/Types";

Sentry.init({
    dsn: config.sentry.dsn,
    enabled: config.sentry.enable,
    environment: config.sentry.env,
    integrations: [new Integrations.BrowserTracing()],
    release: config.release,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
if (typeof window !== "undefined") {
    if (!document.querySelector("#google-maps")) {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${config.googleMap.googleMapAutoCompleteApiKey}&libraries=places`,
            document.querySelector("head"),
            "google-maps",
        );
    }
}
setTimeout(() => {
    ReactDOM.render(<App />, document.getElementById("root"));
}, 1000);
