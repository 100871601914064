import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { AppLabelWrapper, AppTextField } from "components";
import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AllKindergartensModal } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            marginRight: theme.spacing(30),

        },
        fullWidth: { width: "100%" },
    }),
);
export interface AllKindergartensExpendedRowFirstContainerProps {
    readonly: boolean;
    control: Control<AllKindergartensModal>;
    infoState: AllKindergartensModal;
}
export const AllKindergartensExpendedRowFirstContainer: React.FC<AllKindergartensExpendedRowFirstContainerProps> =
    ({ readonly, control, infoState }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        return (
            <Grid
                container
                spacing={2}
                justifyContent="center"
                className={classes.root}>

                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.kindergartenId.title")}
                        value={infoState.Id as string}
                        direction="top"
                    >
                        <AppTextField
                            fullWidth
                            control={control}
                            name="Id"
                            />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.companyId.title")}
                        value={infoState.companyID}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="companyID"
                           />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.kindergartenName.title")}
                        value={infoState.kindergartenName}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="kindergartenName"
                           />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.firstName.title")}
                        value={infoState.firstname}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="firstname"
                            />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.lastName.title")}
                        value={infoState.lastname}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="lastname"
                           />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={false}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.phone.title")}
                        value={infoState.phone}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="phone"
                        />
                    </AppLabelWrapper>
                </Grid>
                {/*  <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={true}
                        readOnly={readonly}
                        label={t("superAdmin.kindergartenList.registerPerson.title")}
                        value={infoState.registerPerson as unknown as string}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="registerPerson"
                            rules={{
                                required: t(
                                    "superAdmin.kindergartenList.registerPerson.missing",
                                ) as string,
                            }}
                        />
                    </AppLabelWrapper>
                </Grid> */}
            </Grid>
        );
    };

export default AllKindergartensExpendedRowFirstContainer;
