import { Grid, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { AppPrimaryButtonWithForwardIcon, AppTextField } from "components";
import { EMAIL_VALIDATION_PATTERN } from "globals/constants";
import Cookies from "js-cookie";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { LoginFacebook, LoginGoogle, MultiStepRegistrationData } from "screens";
import {
    findCareHour,
    findGender,
    RegistrationForParentStep1Modal,
    RegistrationForParentStepperCommonProps
} from "sdk";
import { ParentService } from "services/ParentService";
import { CHILDREN_REGISTRATION_MAX_WIDTH, COLOR_PRIMARY } from "theme";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
        },
        fullWidth: { width: "100%" },
        body: {
            width: "600px",
            maxWidth: CHILDREN_REGISTRATION_MAX_WIDTH,
        },
        fieldsContainer: {
            marginTop: theme.spacing(4),
        },
        button: {
            borderRadius: "0px 10px 10px 0px / 0px 10px 10px 10px",
            height: "56px",
            minWidth: "100px",
        },
        iconButtonsContainer: {
            marginBottom: theme.spacing(5),
        },
        emailContainer: {
            display: "flex",
            width: "100%",
            marginBottom: theme.spacing(4),
        },
        orRegisterWithContainer: {
            marginBottom: theme.spacing(4),
            textAlign: "center",
        },
    }),
);

const service = new ParentService();

export interface RegistrationForParentStep1Props
    extends RegistrationForParentStepperCommonProps {
    mainState: MultiStepRegistrationData;
}
export const RegistrationForParentStep1: React.FC<RegistrationForParentStep1Props> =
    ({
        handleNext,
        parentState,
        handleStateSet,
        mainState,

        ...rest
    }) => {
        const classes = useStyle();
        const { t } = useTranslation();
        const { control, handleSubmit } =
            useForm<RegistrationForParentStep1Modal>({
                defaultValues: { Email: parentState.Email },
            });
        const submit = handleSubmit((data) => {
            handleStateSet({
                ...parentState,
                ...(data as RegistrationForParentStep1Modal),
            });
            handleNext();
        });

        const submitData = () => {
            var token = Cookies.get("JwtToken");
            const request = new FormData();

            for (let i = 0; i < mainState.kindergartens.length; i++) {
                request.append(
                    `kindergartens[${i}]`,
                    mainState.kindergartens[i].Id.toString()
                );
            }

            for (let i = 0; i < mainState.childrensInfo.children.length; i++) {
                request.append(
                    `childrenData[${i}].careHour`,
                    findCareHour(mainState.childrensInfo.children[i].CareHours).toString()
                );
                request.append(
                    `childrenData[${i}].childBirthday`,
                    mainState.childrensInfo.children[i].BirthdayOfTheChild.format('YYYY-MM-DDTHH:mm:ss')
                );
                request.append(
                    `childrenData[${i}].childFirstname`,
                    mainState.childrensInfo.children[i].FirstNameChild
                );
                request.append(
                    `childrenData[${i}].childLastname`,
                    mainState.childrensInfo.children[i].LastNameChild
                );
                request.append(
                    `childrenData[${i}].childGender`,
                    findGender(mainState.childrensInfo.children[i].Gender).toString()
                );
                request.append(
                    `childrenData[${i}].supportStart`,
                    mainState.childrensInfo.children[i].SupportStart.format('YYYY-MM-DDTHH:mm:ss')
                );
                request.append(
                    `childrenData[${i}].message`,
                    mainState.childrensInfo.children[i].Message
                );
                const k = mainState.childrensInfo.children[i]
                if (k!.AttachedFile) {
                    request.append(
                        `childrenData[${i}].VoucherFile`,
                        k.AttachedFile[0]
                    );
                }
            }

            service.addChildsRequests(request)
                .then(function (response) {
                    console.log(response);
                    toast.success(t("common.successfullySaved"));
                })
                .catch(function (error) {
                    console.log("API ERROR:");
                    console.log(error);
                })
        };

        return (
            <div className={classes.root}>
                <div className={classes.body}>
                    <form onSubmit={submit} noValidate={true}>
                        <Grid
                            container
                            direction="column"
                            className={clsx(
                                classes.fullWidth,
                                classes.fieldsContainer,
                            )}>
                            <Grid item xs={12} className={classes.fullWidth}>
                                <div className={classes.emailContainer}>
                                    <div className={classes.fullWidth}>
                                        <AppTextField
                                            control={control}
                                            name="Email"
                                            placeholder={t(
                                                "registrationForParent.step1.email.title",
                                            )}
                                            fullWidth={true}
                                            rules={{
                                                required: t(
                                                    "registrationForParent.step1.email.missing",
                                                ) as string,
                                                pattern: {
                                                    value: EMAIL_VALIDATION_PATTERN,
                                                    message: t(
                                                        "registrationForParent.step1.email.invalid",
                                                    ) as string,
                                                },
                                            }}
                                            customBorderRadius={
                                                "10px 0px 0px 10px / 10px 0px 0px 10px"
                                            }
                                            removeShadow={true}
                                        />
                                    </div>
                                    <div>
                                        <AppPrimaryButtonWithForwardIcon
                                            type="submit"
                                            text={t("common.continue")}
                                            variant="contained"
                                            color="primary"
                                            style={{ minWidth: "200px" }}
                                            classes={{ root: classes.button }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.orRegisterWithContainer}>
                                <Typography variant="body1">
                                    {t(
                                        "registrationForParent.orRegistrationWith",
                                    )}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.iconButtonsContainer}>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    spacing={2}>
                                    <Grid item xs={4}>
                                        <LoginGoogle />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <LoginFacebook submitData={submitData} />
                                    </Grid>
                                    {/* <Grid item xs={4}>
                                        <LoginApple />
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                style={{ textAlign: "center" }}
                                className={classes.fullWidth}>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    style={{ marginBottom: "5px" }}>
                                    {t("registrationForParent.ifYouContinue")}
                                </Typography>
                                <Grid
                                    container
                                    style={{ textAlign: "center" }}
                                    justifyContent="center">
                                    <Grid
                                        item
                                        style={{
                                            marginRight: "5px",
                                            color: COLOR_PRIMARY,
                                        }}>
                                        <Typography variant="body1">
                                            {t("registrationForParent.terms")}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ marginRight: "5px" }}>
                                        <Typography
                                            variant="body1"
                                            color="textSecondary">
                                            &
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ color: COLOR_PRIMARY }}>
                                        <Typography variant="body1">
                                            {t("registrationForParent.privacy")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        );
    };

export default RegistrationForParentStep1;
