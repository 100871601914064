export const GERMAN_DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm";
export const ENGLISH_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";
export const GERMAN_DATE_FORMAT = "DD.MM.YYYY";
export const US_DATE_FORMAT = "DD/MM/YYYY";
export const ISO8601_TIME_FORMAT = "HH:mm:ss";
export const TIME_FORMAT_12H = "hh:mm A";
export const DEFAULT_TIME_FORMAT = "HH:mm";

export const FOUR_PRECISION_NUMERIC = "0,0.0000";
export const FORMAT_INTEGER = "0,0";

export const BERLIN_LAT = 52.520008;
export const BERLIN_LNG = 13.404954;
export const DEFAULT_POSTAL_CODE = 10115;

export const CONTACT_MAP_LAT = 52.5186;
export const CONTACT_MAP_LNG = 13.495;
//Validation Patterns

export const EMAIL_VALIDATION_PATTERN =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//Masks
export const PHONE_NUMBER_MASK = "+7 #### #### ####";

export const PASSWORD_VALIDATION_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#[\]$%[\]^&[\]*.,;:{}<>+-/_=~`[]()?|])(?=.{6,})/;
