import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { AppLabelWrapper } from "components";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { AllParentsModal } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            marginRight: theme.spacing(30),

        },
        fullWidth: { width: "100%" },
    }),
);
export interface AllParentsExpendedRowFirstContainerProps {
    infoState: AllParentsModal;
}
export const AllParentsExpendedRowFirstContainer: React.FC<AllParentsExpendedRowFirstContainerProps> =
    ({ infoState }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        return (
            <Grid
                container
                spacing={2}
                justifyContent="center"
                className={classes.root}>

                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Id"
                        value={infoState.id as unknown as string}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="User Id"
                        value={infoState.userId as unknown as string}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="First Name"
                        value={infoState.firstName}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Last Name"
                        value={infoState.lastName}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        label="Date of Birth"
                        value={infoState.dateOfBirth.toString()}
                        direction="top">
                    </AppLabelWrapper>
                </Grid>
            </Grid>
        );
    };

export default AllParentsExpendedRowFirstContainer;
