import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AppDialog } from "components/Dialog";
import { CheckoutForm } from "components/StripeForm";
import { useEffect } from 'react';
import { ClientSecret, StripeSubscribe } from 'sdk';

const stripePromise = loadStripe('pk_test_51L7HJUJTSiiZDbzeZ8xefrgwMBQedwdTihTOHuyHYiaeVnx7oDtZEgQeRAazw5TIcLDSheHp1sHT9UU7MN1MuuEn004cp0jTmr');

export interface AppStripeDialogProps {
  open: boolean;
  onClose: () => void;
  clientSecret: ClientSecret;
  stripeSubscribe: StripeSubscribe;
}

export const AppStripeDialog: React.FC<AppStripeDialogProps> =
  ({
    onClose, open, clientSecret, stripeSubscribe
  }) => {
    useEffect(() => {
      console.log(clientSecret)
      console.log("clientSecret")
    }, [])
    return (
      <AppDialog open={open} handleClose={onClose}>
        <Elements stripe={stripePromise} options={clientSecret}>
          <CheckoutForm stripeSubscribe={stripeSubscribe} />
        </Elements>
      </AppDialog>
    );
  };