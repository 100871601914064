export enum RegistrationType {
    CLIENT = "Parent",
    INSTITUTION_COMPANY = "INSTITUTION_COMPANY",
}

export interface Register {
    FirstName: string;
    Email: string;
    LastName: string;
    Password: string;
    ConfirmPassword: string;
    CompanyName: string;
    Role: RegistrationType;
    ClientConfirmationEmailURI: string;
    langCode: string;
}

export const getRegisterInitialValues = (): Register => {
    return {
        CompanyName: "",
        ConfirmPassword: "",
        Email: "",
        FirstName: "",
        LastName: "",
        Password: "",
        Role: RegistrationType.INSTITUTION_COMPANY,
        ClientConfirmationEmailURI: "http://localhost:3000/registerSuccess/redirectPath=-home",
        langCode: "de",
    };
};

export let DefaultUsers: Register[] = [
    {
        CompanyName: "KitaPlatz Test",
        ConfirmPassword: "123",
        Password: "123",
        Email: "test@kitaplatz.de",
        FirstName: "Test",
        LastName: "User",
        Role: RegistrationType.INSTITUTION_COMPANY,
        ClientConfirmationEmailURI: "http://localhost:3000/registerSuccess/redirectPath=-home",
        langCode: "de",
    },
];

export interface EmailComfirmationURL {
    userId: string;
    code: string;
}

export const getEmailComfirmationURLInitialValues =
    (): EmailComfirmationURL => {
        return {
            userId: "",
            code: "",
        };
    };