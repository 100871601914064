import {
    GERMAN_DATE_FORMAT,
    US_DATE_FORMAT,
    ENGLISH_DATE_TIME_FORMAT,
    GERMAN_DATE_TIME_FORMAT,
} from "globals/constants";
import i18n from "i18next";
import moment from "moment-timezone";
import numeral from "numeral";
import { initReactI18next } from "react-i18next";
import deTranslation from "./de";
import enTranslation from "./en";

i18n.use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        debug: false,
        resources: {
            en: enTranslation,
            de: deTranslation,
        },
        fallbackLng: "de",
        interpolation: {
            escapeValue: false,
            format: function (value, format, lng) {
                if (typeof value == "number") {
                    return numeral(value).format(format);
                } else if (value instanceof moment) {
                    if (format === "dateFormat") {
                        return moment(value).format(
                            lng === "de" ? GERMAN_DATE_FORMAT : US_DATE_FORMAT,
                        );
                    } else if (format === "dateTimeFormat") {
                        return moment(value).format(
                            lng === "de"
                                ? GERMAN_DATE_TIME_FORMAT
                                : ENGLISH_DATE_TIME_FORMAT,
                        );
                    } else {
                        return "";
                    }
                } else {
                    return value;
                }
            },
        },
    });

export default i18n;

// t("dates.dateFormat", {
//     date: value.DateOfBirth,
// })
