import { Grid } from "@material-ui/core";
import moment from "moment-timezone";
import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProfileModel } from "sdk";
import { ProfileContainerHeader } from "./ProfileContainerHeader";
import { ProfileFiledOrTextContainer } from "./ProfileFiledOrTextContainer";
export interface ProfilePersonalInfoProps {
    control?: Control<ProfileModel>;
    isEdit?: boolean;
    value?: ProfileModel;
}

export const ProfilePersonalInfo: React.FC<ProfilePersonalInfoProps> = ({
    control,
    isEdit = false,
    value,
}) => {
    const { t } = useTranslation();

    return (
        <ProfileContainerHeader
            firstTitle={t("profile.personal")}
            secondTitle={t("profile.information")}>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("profile.firstName.title")}
                    fieldType="text"
                    isEdit={isEdit}
                    control={control}
                    value={value?.FirstName}
                    name="FirstName"
                    errorMessage={t("profile.firstName.missing")}
                />
            </Grid>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("profile.lastName.title")}
                    value={value?.LastName}
                    isEdit={isEdit}
                    fieldType="text"
                    control={control}
                    name="LastName"
                    errorMessage={t("profile.lastName.missing")}
                />
            </Grid>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("profile.dateOfBirth.title")}
                    fieldType="date"
                    isEdit={isEdit}
                    value={
                        value
                            ? t("dates.dateFormat", {
                                  date: value.DateOfBirth,
                              })
                            : undefined
                    }
                    control={control}
                    name="DateOfBirth"
                    errorMessage={t("profile.dateOfBirth.missing")}
                />
            </Grid>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("profile.phoneNumber.title")}
                    value={value?.PhoneNumber}
                    fieldType="text"
                    isEdit={isEdit}
                    control={control}
                    name="PhoneNumber"
                    errorMessage={t("profile.phoneNumber.missing")}
                />
            </Grid>
        </ProfileContainerHeader>
    );
};

export default ProfilePersonalInfo;
