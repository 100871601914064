import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import loginBg from "assets/images/bg/login-sign-up-bg.png";
import {
    AppCheckBox,
    AppPrimaryButtonWithForwardIcon,
    AppTextField,
} from "components";
import { AppDialog } from "components/Dialog";
import AppPasswordInputField from "components/Inputs/TextFields/AppPasswordTextField";
import { EMAIL_VALIDATION_PATTERN } from "globals/constants";
import { createLoginCookies, createUserCookies, getCookie } from "globals/cookie";
import { useUserContext } from "providers";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LoginApple, LoginFacebook, LoginGoogle } from "screens";
import { DefaultUsers, Login, LoginResponse, User } from "sdk";
import { LoginDialogHeader } from "./Partials/LoginDialogHeader";
import { AuthService } from "services/AuthService";
import axios from "axios";
import { CookiesKey } from "globals";
import { appRoutesForContext } from "routing/react-route-paths";

const service = new AuthService();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        title: {
            marginBottom: theme.spacing(6),
            textAlign: "center",
        },

        iconButtonsContainer: {
            marginTop: theme.spacing(1),
        },
        loginButtonContainer: {
            marginTop: theme.spacing(1),
        },
        dialogContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
        },
        dialogBody: {
            height: "80vh",

            maxWidth: "400px",
            [theme.breakpoints.up("xll")]: {
                maxWidth: "600px",
            },
        },
    }),
);
export interface LoginDialogProps {
    open: boolean;
    handleClose: () => void;
    redirectPath: string;
}
export const LoginDialog: React.FC<LoginDialogProps> = ({
    handleClose,
    open,
    redirectPath,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { user, setUser } = useUserContext();
    const history = useHistory();
    const [error, setError] = useState<null | string>(null);
    const { control, handleSubmit, watch } = useForm<Login>({
        defaultValues: { Email: "", Password: "", StayLoggedIn: false },
    });
    const watchEmail = watch("Email");
    const watchPassword = watch("Password");

    const submit = handleSubmit((request) => {
        //let user : User;
        service.login(request)
            .then(function (response) {
                console.log(response);
                if (setUser) {
                    setUser({
                        Email: response.data.email,
                        jwtToken: response.data.jwtToken,
                        FirstName: response.data.firstName,
                        LastName: response.data.lastName,
                        Fullname: response.data.fullname,
                        Type: response.data.type,
                        ProfileImageUrl: response.data.profileImageUrl,
                    });
                } else {
                    setError(t("auth.loginError") as string);
                }
            })
            .catch(function (error) {
                console.log(error);
                setError(t("auth.loginError") as string);
            })
    });

    const handlePasswordForgottenClick = () => {
        history.push(
            appRoutesForContext()
                .auth()
                .passwordForgotten.setpasswordForgottenPathParams(redirectPath),
        );
    };

    useEffect(() => {
        console.log("User: " + user);
        if (user) {
            createUserCookies(user);
            history.push(redirectPath.replace("-", "/"));
            //console.log(getCookie(CookiesKey.JWT_TOKEN));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    useEffect(() => {
        if (error) {
            console.log("Error: " + error);
            setError(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchEmail, watchPassword]);
    return (
        <div className={classes.root}>
            <AppDialog
                withBackgroundImage={true}
                open={open}
                closeAfterTransition={true}
                handleClose={handleClose}
                bgImageUrl={loginBg}
                dialogHeaderRightChildren={
                    <LoginDialogHeader
                        closeLoginDialog={handleClose}
                        redirectPath={redirectPath}
                    />
                }
                bgImageHeight={"100vh"}>
                <div className={classes.dialogContainer}>
                    <form noValidate={true} onSubmit={submit}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.dialogBody}>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item className={classes.title}>
                                        <Typography
                                            variant="h4"
                                            color="textPrimary">
                                            {t("auth.loginTitle")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <AppTextField
                                            control={control}
                                            autoComplete="username"
                                            name="Email"
                                            type="email"
                                            rules={{
                                                required: t(
                                                    "auth.email.missing",
                                                ) as string,
                                                pattern: {
                                                    value: EMAIL_VALIDATION_PATTERN,
                                                    message: t(
                                                        "auth.email.invalid",
                                                    ) as string,
                                                },
                                            }}
                                            placeholder={t("auth.email.title")}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AppPasswordInputField
                                            autoComplete="current-password"
                                            control={control}
                                            name="Password"
                                            type="password"
                                            rules={{
                                                required: t(
                                                    "auth.password.missing",
                                                ) as string,
                                            }}
                                            placeholder={t(
                                                "auth.password.title",
                                            )}
                                            fullWidth={true}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}>
                                            <div>
                                                <AppCheckBox
                                                    label={t(
                                                        "auth.stayLoggedIn",
                                                    )}
                                                />
                                            </div>
                                            <div>
                                                 <Typography
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={handlePasswordForgottenClick}>
                                                    {t(
                                                        "auth.passwordForgotten",
                                                    )}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    className={classes.loginButtonContainer}
                                    spacing={5}>
                                    <Grid item>
                                        <AppPrimaryButtonWithForwardIcon
                                            type="submit"
                                            text={t("auth.loginButtonText")}
                                        />
                                    </Grid>
                                    {error && (
                                        <Typography
                                            color="error"
                                            variant="caption">
                                            {error}
                                        </Typography>
                                    )}
                                    <Grid item>
                                        <Typography variant="body1">
                                            {t("auth.OrRegisterWith")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    spacing={2}
                                    className={classes.iconButtonsContainer}>
                                    <Grid item xs={4}>
                                        <LoginGoogle />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <LoginFacebook />
                                    </Grid>
                                    {/* <Grid item xs={4}>
                                        <LoginApple />
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </AppDialog>
        </div>
    );
};

export default LoginDialog;
