import { RoutePathInterface } from "globals";
import { appRoutesForContext } from "routing/react-route-paths";

export const FacilitiesPath: RoutePathInterface = {
    //NewKitaplatzReact02\src\routing\react-route-paths.ts za ovaj public()
    path: appRoutesForContext().facilitiesProfile().facilitiesPath,
    key: [],
};

export const AddPasswordViewPath: RoutePathInterface = {
   path: appRoutesForContext().facilitiesProfile().addPasswordView.addPasswordViewPath,
    key: [],
}

export const AddNewFacilitiesViewPath: RoutePathInterface = {
    path: appRoutesForContext().facilitiesProfile().addNewFacilitiesView.addNewFacilitiesViewPath,
     key: [],
 }

export const FacilitiesRoutePathList: RoutePathInterface[] = [
    FacilitiesPath,
    AddPasswordViewPath,
    AddNewFacilitiesViewPath,
];
