import { appRoutesForContext } from "routing/react-route-paths";
import { RoutePathInterface } from "globals";

export const LoginPath: RoutePathInterface = {
    path: appRoutesForContext().auth().login.loginPath,
    key: [],
};
export const RegisterPath: RoutePathInterface = {
    path: appRoutesForContext().auth().registration.registrationPath,
    key: [],
};
export const PasswordForgottenPath: RoutePathInterface = {
    path: appRoutesForContext().auth().passwordForgotten.passwordForgottenPath,
    key: [],
}
export const ResetPasswordPath: RoutePathInterface = {
    path: appRoutesForContext().auth().resetPassword.resetPasswordPath,
    key: [],
}
export const RegisterSuccessPath: RoutePathInterface = {
    path: appRoutesForContext().auth().registerSuccess.registerSuccessPath,
    key: [],
}
export const RegisterSuccessBeforeEmailPath: RoutePathInterface = {
    path: appRoutesForContext().auth().registerSuccessBeforeEmail.registerSuccessBeforeEmailPath,
    key: [],
}
export const RoutesPathsForAuthentication: RoutePathInterface[] = [
    LoginPath,
    RegisterPath,
    PasswordForgottenPath,
    ResetPasswordPath,
    RegisterSuccessPath,
    RegisterSuccessBeforeEmailPath
];