import { RoutePathInterface } from "globals";
import { appRoutesForContext } from "routing/react-route-paths";

export const AllKindergartensPath: RoutePathInterface = {
    path: appRoutesForContext().superAdmin().allKindergartens,
    key: [],
};

export const AllParentsPath: RoutePathInterface = {
    path: appRoutesForContext().superAdmin().allParents,
    key: [],
};
export const KindergartenRequestsPath: RoutePathInterface = {
    path: appRoutesForContext().superAdmin().kindergartensRequests.kindergartensRequestsPath,
    key: [],
};
export const SuperAdminRoutePathList: RoutePathInterface[] = [
    AllKindergartensPath,
    AllParentsPath,
    KindergartenRequestsPath,
];
