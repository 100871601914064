import { Theme } from "@material-ui/core";
import { LinearProgress, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));
export const CustomTableLoadingOverlay = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <LinearProgress />
        </div>
    );
};

export default CustomTableLoadingOverlay;
