import React from "react";
import { useHistory } from "react-router-dom";
import { appRoutesForContext } from "routing/react-route-paths";
import RegisterSuccessBeforeEmailDialog from "./RegisterSuccessBeforeEmailDialog";

export const RegisterSuccessBeforeEmail = () => {
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const handleClose = () => {
        history.push(
            appRoutesForContext()
                .public()
                .homePath,
        );
    };
    return (
        <RegisterSuccessBeforeEmailDialog
            open={open}
            handleClose={handleClose}
        />
    );
};

export default RegisterSuccessBeforeEmail;
