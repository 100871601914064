import { Typography } from "@material-ui/core";
import { AppTableExpandableIcon } from "components";
import { AppTextMarker, AppTableActionMenu } from "components";

import memoizeOne from "memoize-one";
import { TableColumn } from "react-data-table-component";
import { TFunction } from "react-i18next";
import {
    KindergartenRegistrationListModal,
    KindergartenRegistrationStatus,
} from "sdk/models/KindergartenRegistrationList";
import { COLOR_PINK, COLOR_PRIMARY, COLOR_SECONDARY } from "theme";
import moment, { Moment } from "moment-timezone";

export const KindergartenkindergartenRegistrationListColumns = memoizeOne(
    (
        t: TFunction<"translation">,
        onRowExpandClick: (id: number) => void,
        onDeleteItem: (id: number) => void,
    ): TableColumn<KindergartenRegistrationListModal>[] => {
        return [
            {
                selector: (row) => row.Id,
                sortable: true,
                name: t("kindergartenRegistrationList.registrationId"),
                width: "180px",
            },

            {
                selector: (row) => row.Date,
                sortable: true,
                name: t("kindergartenRegistrationList.date"),
                grow: 1,
                cell: (row) => (
                    <div>
                        {t("dates.dateTimeFormat", {
                            date: moment(row.Date),
                        })}
                    </div>
                ),
            },

            {
                selector: (row) => row.Kindergarten,
                name: t("kindergartenRegistrationList.kindergarten"),
                grow: 1,
            },
            {
                selector: (row) => row.Location,
                name: t("kindergartenRegistrationList.location"),
                grow: 1,
            },
            // {
            //     selector: (row) => row.Location,
            //     name: t("kindergartenRegistrationList.info"),
            //     grow: 1,
            // },
            {
                selector: (row) => row.Status,
                width: "150px",
                sortable: true,

                name: t("kindergartenRegistrationList.status"),
                cell: (row, index, column) => {
                    let color = COLOR_SECONDARY;
                    const value = row.Status;

                    if (value === KindergartenRegistrationStatus.PENDING) {
                        color = COLOR_PRIMARY;
                    } else if (
                        value === KindergartenRegistrationStatus.REJECTED
                    ) {
                        color = COLOR_PINK;
                    }
                    return (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}>
                            <AppTextMarker
                                bgClr={color}
                                padding="3px 13px 3px 13px">
                                <Typography variant="body1">{value}</Typography>
                            </AppTextMarker>
                        </div>
                    );
                },
            },
            {
                width: "70px",
                center: true,
                cell: (row, index, column) => {
                    return <AppTableActionMenu hideEdit={true} id={row.Id as number} onDeleteItem={onDeleteItem} />;
                },
            },
            {
                width: "100px",
                cell: (row, index, column) => (
                    <AppTableExpandableIcon
                        id={row.Id as number}
                        toggle={row.RowExpandedToggle === true}
                        onRowExpandClick={onRowExpandClick}
                    />
                ),
            },
        ];
    },
);
