/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { AppPrimaryButtonWithForwardIcon } from 'components/Buttons';
import { useUserContext } from 'providers';
import { useHistory } from 'react-router-dom';
import { appRoutesForContext } from 'routing/react-route-paths';
import { StripeSubscribe } from 'sdk';
import { AuthService } from 'services/AuthService';
import { ParentService } from 'services/ParentService';
import { BG_GRADIENT } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stripeElement: {
      marginLeft: 200,
      marginRight: 400,
      marginTop: 30,
      border: BG_GRADIENT
    },
    stripeButton: {
      marginLeft: 200,
      marginRight: 200,
      marginTop: 50,
    },

  }),
);
const serviceAuth = new AuthService();
const serviceParent = new ParentService();


export interface CheckoutFormProps {
  stripeSubscribe: StripeSubscribe;
}
export const CheckoutForm: React.FC<CheckoutFormProps> = ({ stripeSubscribe }) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const { user } = useUserContext();
  const history = useHistory();

  const handleSubmit = async (event: any) => {

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      console.log("stripe")
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (elements) {
      const cardElement = elements.getElement(CardElement);
      console.log(cardElement)
      if (cardElement && user) {
        stripe.createToken(cardElement).then(function (response) {
          // Handle result.error or result.token
          if (response.token) {
            let stribeSubscribe: StripeSubscribe = {
              email: user.Email,
              name: user.Fullname,
              token: response.token.id,
            };

            serviceAuth.stripeSubscribe(stribeSubscribe)
              .then(function (response) {
                console.log(response);
                history.push(
                  appRoutesForContext()
                    .public()
                    .homePath
                );
              })
              .catch(function (error) {
                console.log("API ERROR");
                console.log(error);
              })

          } else {
            console.log(response.error)
          }
        })

      } else if (cardElement && !user) {
        stripe.createToken(cardElement).then(function (response) {
          // Handle result.error or result.token
          if (response.token) {
            console.log(response.token.id)
            console.log(user)

            let stribeSubscribe: StripeSubscribe = {
              email: stripeSubscribe.email,
              name: stripeSubscribe.name,
              token: response.token.id,
            };
            console.log(stribeSubscribe)
            serviceAuth.stripeSubscribe(stribeSubscribe)
              .then(function (response) {
                console.log(response);
                history.push(
                  appRoutesForContext()
                    .auth()
                    .registerSuccessBeforeEmail
                    .setRegisterSuccessBeforeEmailPathParams("home"),
                );
              })
              .catch(function (error) {
                console.log("API ERROR");
                console.log(error);

              })

          } else {
            console.log(response.error)
          }
        })
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.stripeElement}>
      <CardElement className={classes.stripeElement} />
      <AppPrimaryButtonWithForwardIcon disabled={!stripe} className={classes.stripeButton}
         type="submit"
         text="Submit"
       />
    </form>
  )
};

