
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { AppTable } from "components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChangeStatus, KindergartenRegistrationStatus, KindergartenRequestModal } from "sdk";
import { FacilitiesService } from "services/FacilitiesService";
import {
    COLOR_LIGTHEST_BLUE, COLOR_WHITE,
    FULL_VIEW_HEIGHT_WITHOUT_HEADER
} from "theme";
import { ListChildrenRequestsColumns } from ".";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: FULL_VIEW_HEIGHT_WITHOUT_HEADER,
            overflow: "auto",
            background: `linear-gradient(180deg, ${COLOR_WHITE} 10%, ${COLOR_LIGTHEST_BLUE} 100%)`,
            display: "flex",
            flexDirection: "column",
            paddingTop: theme.spacing(4),
            alignItems: "center",
            paddingRight: theme.spacing(25),
            paddingLeft: theme.spacing(25),
            [theme.breakpoints.down("lg")]: {
                paddingRight: theme.spacing(15),
                paddingLeft: theme.spacing(15),
            },
        },
        title: {
            //marginBottom: theme.spacing(5),
        },
        divWhite: {
            backgroungColor: "white",
            color: "black",
        },
        divGreen: {
            backgroundColor: "#008000",
            color: "white",

        },
        divBlue: {
            backgroundColor: "#007fff",
            color: "white",
        },
        divPink: {
            backgroundColor: "#d71868",
            color: "white",

        },
    }),
);

const service = new FacilitiesService();

export const ListChildrenRequests = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [state, setState] = useState<KindergartenRequestModal[]>([]);
    const [status, setStatus] = useState<KindergartenRequestModal[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<number>(0);

    const [counterAccepted, setCounterAcceped] = useState<number>(0);
    const [counterPending, setCounterPending] = useState<number>(0);
    const [counterRejected, setCounterRejected] = useState<number>(0);

    const [div1, setDiv1] = useState(classes.divWhite);
    const [div2, setDiv2] = useState(classes.divWhite);
    const [div3, setDiv3] = useState(classes.divWhite);
    const [div4, setDiv4] = useState(classes.divWhite);


    const onClick1 = () => {
        setDiv1(classes.divGreen);
        setDiv2(classes.divWhite);
        setDiv3(classes.divWhite);
        setDiv4(classes.divWhite);
        setStatus(state);
        setSelectedStatus(0);
    }
    const onClick2 = () => {
        setDiv2(classes.divGreen);
        setDiv1(classes.divWhite);
        setDiv3(classes.divWhite);
        setDiv4(classes.divWhite);
        setStatus(state.filter(e => e.Status === KindergartenRegistrationStatus.ACCEPTED));
        setSelectedStatus(1);
    }
    const onClick3 = () => {
        setDiv3(classes.divGreen);
        setDiv2(classes.divWhite);
        setDiv1(classes.divWhite);
        setDiv4(classes.divWhite);
        setStatus(state.filter(e => e.Status === KindergartenRegistrationStatus.PENDING));
        setSelectedStatus(2);

    }
    const onClick4 = () => {
        setDiv4(classes.divGreen);
        setDiv2(classes.divWhite);
        setDiv3(classes.divWhite);
        setDiv1(classes.divWhite);
        setStatus(state.filter(e => e.Status === KindergartenRegistrationStatus.REJECTED));
        setSelectedStatus(3);
    }

    useEffect(() => {
        setDiv1(classes.divGreen);

        service.getChildrenFromSameFacilities()
            .then(function (response) {
                console.log(response);
                const u: KindergartenRequestModal[] = [];
                response.data.data.forEach((element: any) => {
                    let childItem: KindergartenRequestModal = {
                        ChildId: element.id,
                        DateOfCreation: element.date,
                        FirstNameChild: element.childFirstname,
                        LastNameChild: element.childLastname,
                        Age: element.age,
                        Gender: element.gender,
                        SupportStart: element.supportStart,
                        Status: element.status === 1 ? KindergartenRegistrationStatus.ACCEPTED : (element.status === 2 ? KindergartenRegistrationStatus.PENDING : KindergartenRegistrationStatus.REJECTED),
                    };
                    if (!state.some(e => e.ChildId === childItem.ChildId)) {
                        u.push(childItem);
                    }
                });
                let myArray = [...state, ...u];
                setState(myArray);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);

    useEffect(() => {
        if (selectedStatus === 0) {
            setStatus(state);
        }
        if (selectedStatus === 1) {
            setStatus(state.filter(x => x.Status === KindergartenRegistrationStatus.ACCEPTED));
        }
        if (selectedStatus === 2) {
            setStatus(state.filter(x => x.Status === KindergartenRegistrationStatus.PENDING));
        }
        if (selectedStatus === 3) {
            setStatus(state.filter(x => x.Status === KindergartenRegistrationStatus.REJECTED));
        }
    }, [state]);

    useEffect(() => {
        setCounterAcceped(0);
        setCounterPending(0);
        setCounterRejected(0);

        state.map((e) => {
            if (e.Status === KindergartenRegistrationStatus.ACCEPTED) {
                setCounterAcceped((prevCount: number) => prevCount + 1);
            }
            else if (e.Status === KindergartenRegistrationStatus.PENDING) {
                setCounterPending((prevCount: number) => prevCount + 1);
            }
            else if (e.Status === KindergartenRegistrationStatus.REJECTED) {
                setCounterRejected((prevCount: number) => prevCount + 1);
            }
        })
    }, [status]);

    const inputChange = (input: KindergartenRegistrationStatus, id: number) => {
        let changeStatus: ChangeStatus = {
            kindergartenRequestId: id,
            status: input === KindergartenRegistrationStatus.ACCEPTED ? 1 : (input === KindergartenRegistrationStatus.PENDING ? 2 : 3),
        }

        console.log(changeStatus);

        service.changeSatus(changeStatus)
            .then(function (response) {
                console.log(response);
                let newArr: KindergartenRequestModal[] = [];

                state.forEach((x) => {
                    if (x.ChildId === id) {
                        x.Status = input;
                    }
                    newArr.push(x);
                });
                setState(newArr);
            })
            .catch(function (error) {
                console.log("API ERROR:");
                console.log(error);
            })
    }

    return (
        <body style={{
            background: `linear-gradient(180deg, ${COLOR_WHITE} 10%, ${COLOR_LIGTHEST_BLUE} 100%)`,
        }}>
            <div className={classes.root}>
                <Typography className={classes.title} variant="h4">
                    {t("registrationList.title")}
                </Typography>
                <div style={{ height: 45, width: "1200px", margin: 20, textAlign: "center", justifyContent: "center", justifyItems: "center" }}>
                    <div style={{ borderRight: "grey dashed", height: 25, width: 150, display: "inline-block", margin: "auto" }}>
                        <div className={div1} onClick={() => { onClick1() }} style={{ cursor: "pointer", height: 20, width: 40, margin: "auto", fontWeight: "bold" }}>
                            {t("listChildrenRequests.all")}
                        </div>
                    </div>
                    <div style={{ borderRight: "grey dashed", height: 25, width: 150, display: "inline-block" }}>
                        <div className={div2} onClick={onClick2} style={{ cursor: "pointer", height: 20, width: 100, margin: "auto", fontWeight: "bold" }}>
                            {t("listChildrenRequests.Accepted")}
                        </div>
                    </div>
                    <div style={{ borderRight: "grey dashed", height: 25, width: 150, display: "inline-block", margin: "auto" }}>
                        <div className={div3} onClick={onClick3} style={{ cursor: "pointer", height: 20, width: 100, margin: "auto", fontWeight: "bold" }}>
                            {t("listChildrenRequests.Pending")}
                        </div>
                    </div>
                    <div style={{ height: 25, width: 150, display: "inline-block", margin: "auto" }}>
                        <div className={div4} onClick={onClick4} style={{ cursor: "pointer", height: 20, width: 100, margin: "auto", fontWeight: "bold" }}>
                            {t("listChildrenRequests.Rejected")}
                        </div>
                    </div>
                </div>


                <div style={{
                    borderRadius: 10, display: "flex", height: 55, width: "1300px", textAlign: "center", border: "grey dashed", marginBottom: 10,
                    justifyContent: "center",
                    justifyItems: "center",
                    paddingTop: 13,
                }}>
                    <div style={{ display: "inline-block", }}>
                        <div className={classes.divGreen} style={{ height: 28, width: 50, margin: "auto", fontWeight: "bold", fontSize: 20 }}>
                            {counterAccepted}
                        </div>
                    </div>
                    <div style={{ display: "inline-block", }}>
                        <div style={{ height: 20, width: 140, marginTop: 5 }}>
                            {t("listChildrenRequests.Accepted")} {t("listChildrenRequests.children")}
                        </div>
                    </div>
                    <div style={{ display: "inline-block", marginLeft: 150 }}>
                        <div className={classes.divBlue} style={{ height: 28, width: 50, margin: "auto", fontWeight: "bold", fontSize: 20 }}>
                            {counterPending}
                        </div>
                    </div>
                    <div style={{ display: "inline-block" }}>
                        <div style={{ height: 20, width: 140, marginTop: 5, }}>
                            {t("listChildrenRequests.Pending")} {t("listChildrenRequests.children")}
                        </div>
                    </div>
                    <div style={{ display: "inline-block", marginLeft: 150 }}>
                        <div className={classes.divPink} style={{ height: 28, width: 50, margin: "auto", fontWeight: "bold", fontSize: 20 }}>
                            {counterRejected}
                        </div>
                    </div>
                    <div style={{ display: "inline-block" }}>
                        <div style={{ height: 20, width: 140, marginTop: 5 }}>
                            {t("listChildrenRequests.Rejected")} {t("listChildrenRequests.children")}
                        </div>
                    </div>
                </div>

                <div style={{ textAlign: "center", justifyContent: "center", justifyItems: "center", paddingTop: 50, paddingBottom: 200, marginBottom: 200, background: `linear-gradient(180deg, ${COLOR_WHITE} 10%, ${COLOR_LIGTHEST_BLUE} 100%)` }} >
                    <AppTable
                        columns={ListChildrenRequestsColumns(t,
                            inputChange,
                        )}
                        data={status}
                        highlightOnHover
                    />
                </div>
            </div>


        </body>

    );
};

export default ListChildrenRequests;




