import { AppRadioBoxes, AppRadioBoxesProps } from "components";
import { AppControlProps } from "globals";
import React from "react";
import { Controller } from "react-hook-form";
export interface AppControlledRadioButtonProps
    extends AppRadioBoxesProps,
        AppControlProps {}
export const AppControlledRadioButton =
    React.memo<AppControlledRadioButtonProps>(
        ({ name, control, rules, ...rest }) => {
            return (
                <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    render={({ field, fieldState }) => (
                        <AppRadioBoxes
                            error={fieldState.invalid}
                            helperText={
                                fieldState.error && fieldState.error.message
                            }
                            value={field.value}
                            onChange={field.onChange}
                            name={name}
                            {...rest}
                        />
                    )}
                />
            );
        },
    );

export default AppControlledRadioButton;
