import { useUserContext } from "providers";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LoginDialog } from "./LoginDialog";
export interface LoginParams {
    redirectUrl: string;
}
export const Login: React.FC = () => {
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const { redirectUrl } = useParams<LoginParams>();
    const { user, setUser } = useUserContext();
    const [redirectPath, setRedirectPath] = useState("");

    const handleClose = () => {
        setOpen(false);
        history.push(redirectPath.replaceAll("-", "/"));
    };

    useEffect(() => {
        setRedirectPath("-home");
    }, []);

    return (
        <LoginDialog
            open={open}
            handleClose={handleClose}
            redirectPath={
                (user && user.Type === "Kindergarten") ?
                    (redirectPath.replaceAll("-home", "/childsRequestsList")) :
                    (user && user.Type === "SuperAdministrator") ?
                        (redirectPath.replaceAll("-home", "/allKindergartens")) :
                        (redirectPath.replaceAll("-", "/"))
            }
        />
    );
};

export default Login;
