import { AppTableExpandableIcon } from "components";
import memoizeOne from "memoize-one";
import { TableColumn } from "react-data-table-component";
import { TFunction } from "react-i18next";
import { AllParentsModal } from "sdk";

//npm i --save react-select

export const AllParentsColumns = memoizeOne(
    (
        t: TFunction<"translation">,
        onRowExpandClick: (id: number) => void,
    ): TableColumn<AllParentsModal>[] => {

        return [

            {
                selector: (row) => row.firstName,
                name: "First Name",
                minWidth: "330px",
                grow: 1,
            },
            {
                selector: (row) => row.lastName,
                name: "Last Name",
                minWidth: "330px",
                grow: 1,
            },
            {
                width: "80px",
                cell: (row, index, column) => (
                    <AppTableExpandableIcon
                        id={row.Id as number}
                        toggle={row.RowExpandedToggle === true}
                        onRowExpandClick={onRowExpandClick}
                    />
                ),
            },

        ];
    },
);









