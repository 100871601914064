import {
    HomeDeTranslations,
    AuthDeTranslations,
    RegistrationForChildrenDeTranslations,
    CommonDeTranslations,
    RegistrationListDeTranslations,
    ProfileDeTranslations,
    ImprintDeTranslations,
    ContactsDeTranslations,
    RegistrationForParentDeTranslations,
    SuperAdminDeTranslations,
} from "./subGroups";
import { KindergartenDeTranslations, KindergartenEnTranslations } from "./subGroups/Kindergarten";

export const enTranslation = {
    translation: {
        ...ImprintDeTranslations,
        ...HomeDeTranslations,
        ...AuthDeTranslations,
        ...RegistrationForChildrenDeTranslations,
        ...CommonDeTranslations,
        ...RegistrationListDeTranslations,
        ...ProfileDeTranslations,
        ...ContactsDeTranslations,
        ...RegistrationForParentDeTranslations,
        ...KindergartenDeTranslations,
        ...SuperAdminDeTranslations,

        mapSearch: {
            kindergartenNearYou: "Kindergärten in Ihrer Nähe",
            found: "Gefunden",
            kindergarten: "kindergarten",
            registerYourChild: "Melden Sie Ihr Kind in diesem Kindergarten an",
            selectAllResults: "Alle Ergebnisse auswählen",
            unSelectAllResults: "Alle abwählen",
            error: {
                noResultFound:
                    "Adresse ist nicht mit einer Postleitzahl verbunden",
            },
        },
        selectAllResults: {
            title: "Alle Ergebnisse auswählen",
            inYourRange: "In Ihrem Bereich",
            kindergartensHaveBeenFound: "kindergärten gefunden worden sind",
            areFreeToRegistrate: "sind kostenlos zu registrieren",
            goldUser:
                "Für die Registrierung in allen Kindergärten müssen Sie sich als Gold User registrieren",
            ourServiceIs: "Unser Service ist",
            continueWithoutPayment: "Weiter ohne Bezahlung",
            continueWithoutPaymentAlert:
                "Continue without payment will select first 5 kindergartens",
            payWith: "Bezahlen mit",
        },
        footer: {
            about: "ABOUT",
            contact: "Kontakt",
            imprint: "Impressum",
            jobs: "Jobs",
            believedSearch: "BELIEBTE SUCHEN",
            daycareCenters1: "Kitas in Steglitz",
            daycareCenters2: "Kitas in Schöneberg",
        },
        nav: {
            home: "Home",
            Imprint: "Impressum",
            contact: "Kontakt",
            areYouKindergarten: "Sie betreiben einen Kindergarten?",
            menu: {
                childList: "Kinderliste",
                kindergartensList: "Kindergarten-Liste",
                profile: "Profil",
                logout: "Abmelden",
            },
        },
        dates: {
            dateFormat: `{{date,dateFormat}}`,
            dateTimeFormat: `{{date,dateTimeFormat}}`,
        },
        numbers: {},
    },
};
export default enTranslation;
