import {
    createStyles,
    makeStyles,
    Tooltip,
    TooltipProps,
    Typography,
} from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { AppTextField, AppTextFieldProps } from "components";
import { AppControlProps, SimpleOption } from "globals";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface AppSelectProps<
    T extends object = SimpleOption,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
> extends Omit<
            AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
            "renderInput"
        >,
        AppControlProps {
    name?: string;
    selectedvaluekey?: string;
    textfieldprops?: AppTextFieldProps;
    showerrorsintooltip?: string;
    tooltipprops?: Omit<TooltipProps, "children" | "title">;
    defaultValue?: any;
}
const useStyles = makeStyles(() =>
    createStyles({
        input: { paddingLeft: "20px !important" },
    }),
);
export function AppSelect<
    T extends object = SimpleOption,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>(props: AppSelectProps<T, Multiple, DisableClearable, FreeSolo>) {
    const {
        control,
        shouldUnregister,
        rules,
        name,
        textfieldprops,
        selectedvaluekey,
        showerrorsintooltip,
        tooltipprops,
        defaultValue
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div>
            {control ? (
                <Controller
                    name={name ? name : ""}
                    control={control}
                    rules={rules}
                    shouldUnregister={shouldUnregister}
                    defaultValue={defaultValue ?? ""}
                    render={({ field, fieldState }) => {
                        return (
                            <Autocomplete
                                clearText={t("common.clear")}
                                openText={t("common.open")}
                                inputValue={String(field.value)}
                                classes={{ input: classes.input }}
                                onChange={(
                                    event: any,
                                    newValue: any | null,
                                ) => {
                                    if (newValue) {
                                        field.onChange(
                                            selectedvaluekey
                                                ? newValue[selectedvaluekey]
                                                : newValue.value
                                                ? newValue.value
                                                : "",
                                        );
                                    } else {
                                        field.onChange("");
                                    }
                                }}
                                onInputChange={(e, value) => {
                                    if (props.freeSolo) {
                                        field.onChange(value);
                                    }
                                }}
                                noOptionsText={t("common.noOptions")}
                                renderInput={(params: any) => {
                                    if (Boolean(showerrorsintooltip)) {
                                        return (
                                            <Tooltip
                                                title={
                                                    <Typography>
                                                        {fieldState.error
                                                            ? fieldState.error
                                                                  .message
                                                            : ""}
                                                    </Typography>
                                                }
                                                open={fieldState.invalid}
                                                aria-label="error"
                                                {...tooltipprops}>
                                                <div>
                                                    <AppTextField
                                                        {...textfieldprops}
                                                        {...params}
                                                        error={
                                                            fieldState.invalid
                                                        }
                                                    />
                                                </div>
                                            </Tooltip>
                                        );
                                    }

                                    return (
                                        <AppTextField
                                            {...textfieldprops}
                                            {...params}
                                            error={fieldState.invalid}
                                            helperText={
                                                fieldState.error &&
                                                fieldState.error.message
                                            }
                                        />
                                    );
                                }}
                                {...props}
                            />
                        );
                    }}
                />
            ) : (
                <Autocomplete
                    clearText={t("common.clear")}
                    openText={t("common.open")}
                    noOptionsText={t("common.noOptions")}
                    renderInput={(params: any) => {
                        return (
                            <AppTextField
                                {...params}
                                {...textfieldprops}
                                shouldUnregister={shouldUnregister}
                            />
                        );
                    }}
                    {...props}
                />
            )}
        </div>
    );
}

export default AppSelect;
