import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { AppButton, AppTable } from "components";
import { handleRowExpand } from "globals/general-helper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    ChildIdAndKindergarten,
    getInitialChildIdAndKindergarten,
    getInitialMenuItem,
    KindergartenRegistrationListModal,
    KindergartenRegistrationStatus,
    MenuChildListItem,
    MenuStatusListItem,
    MenuStatusListItemData
} from "sdk/models";
import { ParentService } from "services/ParentService";
import {
    COLOR_BOX_SHADOW,
    COLOR_LIGTHEST_BLUE,
    COLOR_PRIMARY,
    COLOR_WHITE,
    FULL_VIEW_HEIGHT_WITHOUT_HEADER
} from "theme";
import {
    KindergartenkindergartenRegistrationListColumns,
    KindergartenListExpendedRow
} from "./Partials";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: FULL_VIEW_HEIGHT_WITHOUT_HEADER,
            background: `linear-gradient(180deg, ${COLOR_WHITE} 10%, ${COLOR_LIGTHEST_BLUE} 100%)`,
            display: "flex",
            overflow: "auto",
            flexDirection: "column",
            paddingTop: theme.spacing(8),
            alignItems: "center",
            paddingRight: theme.spacing(25),
            paddingLeft: theme.spacing(25),
            [theme.breakpoints.down("lg")]: {
                paddingRight: theme.spacing(15),
                paddingLeft: theme.spacing(15),
            },
        },
        title: {
            marginBottom: theme.spacing(5),
        },
    }),
);
const conditionalRowStyles = [
    {
        when: (row: KindergartenRegistrationListModal) =>
            row.RowExpandedToggle === true,
        style: {
            backgroundColor: COLOR_WHITE,
            color: COLOR_PRIMARY,
            boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
            borderRadius: "10px",
        },
    },
];

const service = new ParentService();
export const KindergartenRegistrationList = () => {
    const classes = useStyles();
    let history = useHistory();
    const { t } = useTranslation();

    const [state, setState] = useState<KindergartenRegistrationListModal[]>([]);
    const [stateSelected, setStateSelected] = useState<KindergartenRegistrationListModal[]>([]);
    const [menu, setMenu] = useState<MenuChildListItem[]>([getInitialMenuItem()]);
    const [menuSelected, setMenuSelected] = useState<MenuChildListItem>();
    const [statusFilter, setstatusFilter] = useState<MenuStatusListItem[]>(MenuStatusListItemData);
    const [statusFilterSelected, setStatusFilterSelected] = useState<MenuStatusListItem>();

    const onRowExpandClick = (id: number) => {
        setState([...handleRowExpand(id, state)]);
    };

    const onDeleteItem = (id: number) => {
        service.deleteKindergartenRequests(id)
            .then(function (response) {
                setState([
                    ...state.filter((x) => {
                        return x.Id !== id
                    }),
                ]);
            })
            .catch(function (error) {
                console.log("API ERROR:");
                console.log(error);
            })
    };

    useEffect(() => {
        service.getKindergartenRequests()
            .then(function (response) {
                console.log(response);
                let x: KindergartenRegistrationListModal[] = [];
                let menuItems: MenuChildListItem[] = [];
                response.data.children.forEach((element: any) => {
                    let menuItem: MenuChildListItem = {
                        Id: element.id,
                        Firstname: element.firstname,
                        Lastname: element.lastname,
                        Selected: false
                    }
                    if (!menu.some(e => e.Id === menuItem.Id)) {
                        menuItems.push(menuItem);
                    }
                    let menuArray = [...menu, ...menuItems];
                    setMenu(menuArray);
                    element.kindergartenRequests.forEach((element2: any) => {
                        let requestItem: KindergartenRegistrationListModal = {
                            Id: element2.id,
                            Date: element2.date,
                            Kindergarten: element2.kindergartenName,
                            Location: element2.kindergartenAddress + ", " + element2.kindergartenCity,
                            Info: element2.info,
                            Status: element2.status === 1 ? KindergartenRegistrationStatus.ACCEPTED : (element2.status === 2 ? KindergartenRegistrationStatus.PENDING : KindergartenRegistrationStatus.REJECTED),
                            Name: element2.kindergartenName,
                            Email: element2.kindergartenEmail,
                            City: element2.kindergartenCity,
                            PhoneNumber: element2.kindergartenPhone,
                            Address: element2.kindergartenAddress,
                            IndexNumber: element2.kindergartenCompanyID,
                            ChildId: element2.childId,
                            KindergartenId: element2.kindergartenId,
                        };
                        if (!state.some(e => e.Id === requestItem.Id)) {
                            x.push(requestItem);
                        }
                    });

                    setMenuSelected(menuArray[0]);
                    setStatusFilterSelected(statusFilter[0]);
                });
                let myArray = [...state, ...x];
                setState(myArray);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);

    const selectitem = (data: MenuChildListItem) => {
        console.log(data);
        setMenuSelected(data);
    };

    const selectStatus = (data: MenuStatusListItem) => {
        console.log(data);
        setStatusFilterSelected(data);
    };

    useEffect(() => {
        setStateSelected(
            state.filter(
                (x) => menuSelected?.Id === 0 ? x : x.ChildId === menuSelected?.Id,
            )
        );
    }, [menuSelected]);

    useEffect(() => {
        if (statusFilterSelected?.Id === 1) {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x.Status === KindergartenRegistrationStatus.PENDING : x.ChildId === menuSelected?.Id && x.Status === KindergartenRegistrationStatus.PENDING,
                )
            );
        } else if (statusFilterSelected?.Id === 2) {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x.Status === KindergartenRegistrationStatus.ACCEPTED : x.ChildId === menuSelected?.Id && x.Status === KindergartenRegistrationStatus.ACCEPTED,
                )
            );
        } else if (statusFilterSelected?.Id === 3) {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x.Status === KindergartenRegistrationStatus.REJECTED : x.ChildId === menuSelected?.Id && x.Status === KindergartenRegistrationStatus.REJECTED,
                )
            );
        } else {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x : x.ChildId === menuSelected?.Id,
                )
            );
        }
        // setStateSelected(
        //     state.filter(
        //         (x) => menuSelected?.Id === 0 ? x : x.ChildId === menuSelected?.Id,
        //     )
        // );
    }, [statusFilterSelected]);

    useEffect(() => {
        if (statusFilterSelected?.Id === 1) {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x.Status === KindergartenRegistrationStatus.PENDING : x.ChildId === menuSelected?.Id && x.Status === KindergartenRegistrationStatus.PENDING,
                )
            );
        } else if (statusFilterSelected?.Id === 2) {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x.Status === KindergartenRegistrationStatus.ACCEPTED : x.ChildId === menuSelected?.Id && x.Status === KindergartenRegistrationStatus.ACCEPTED,
                )
            );
        } else if (statusFilterSelected?.Id === 3) {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x.Status === KindergartenRegistrationStatus.REJECTED : x.ChildId === menuSelected?.Id && x.Status === KindergartenRegistrationStatus.REJECTED,
                )
            );
        } else {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x : x.ChildId === menuSelected?.Id,
                )
            );
        }
        // setStateSelected(
        //     state.filter(
        //         (x) => menuSelected?.Id === 0 ? x : x.ChildId === menuSelected?.Id,
        //     )
        // );      
    }, [menuSelected]);

    useEffect(() => {
        if (statusFilterSelected?.Id === 1) {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x.Status === KindergartenRegistrationStatus.PENDING : x.ChildId === menuSelected?.Id && x.Status === KindergartenRegistrationStatus.PENDING,
                )
            );
        } else if (statusFilterSelected?.Id === 2) {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x.Status === KindergartenRegistrationStatus.ACCEPTED : x.ChildId === menuSelected?.Id && x.Status === KindergartenRegistrationStatus.ACCEPTED,
                )
            );
        } else if (statusFilterSelected?.Id === 3) {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x.Status === KindergartenRegistrationStatus.REJECTED : x.ChildId === menuSelected?.Id && x.Status === KindergartenRegistrationStatus.REJECTED,
                )
            );
        } else {
            setStateSelected(
                state.filter(
                    (x) => menuSelected?.Id === 0 ? x : x.ChildId === menuSelected?.Id,
                )
            );
        }
        // setStateSelected(
        //     state.filter(
        //         (x) => menuSelected?.Id === 0 ? x : x.ChildId === menuSelected?.Id,
        //     )
        // );
    }, [state]);

    const addNewKindergartensforChild = () => {
        history.push({
            pathname: "/home/mapSearch",
            state: stateSelected,
        });
    }

    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h4">
                {t("kindergartenRegistrationList.title")}
            </Typography>
            <div style={{ paddingBottom: "15px" }}>
                {statusFilter.map((statusFilter, index) => {
                    if (statusFilter === statusFilterSelected) {
                        if (index === 0) {
                            return (
                                <React.Fragment key={statusFilter.Id}><a style={{ color: "white", backgroundColor: "rgb(76, 175, 80)", margin: "10px", padding: "5px", cursor: "pointer" }} onClick={() => { selectStatus(statusFilter); }}>{statusFilter.Status}</a></React.Fragment>
                            )
                        } else {
                            return (
                                <React.Fragment key={statusFilter.Id}>| <a style={{ color: "white", backgroundColor: "rgb(76, 175, 80)", margin: "10px", padding: "5px", cursor: "pointer" }} onClick={() => { selectStatus(statusFilter); }}>{statusFilter.Status}</a></React.Fragment>
                            )
                        }
                    }
                    else {
                        if (index === 0) {
                            return (
                                <React.Fragment key={statusFilter.Id}><a style={{ margin: "10px", padding: "10px", cursor: "pointer" }} onClick={() => { selectStatus(statusFilter); }}>{statusFilter.Status}</a></React.Fragment>
                            )
                        } else {
                            return (
                                <React.Fragment key={statusFilter.Id}>| <a style={{ margin: "10px", padding: "10px", cursor: "pointer" }} onClick={() => { selectStatus(statusFilter); }}>{statusFilter.Status}</a></React.Fragment>
                            )
                        }
                    }
                })}
            </div>
            <div style={{ paddingBottom: "15px" }}>
                {menu.map((menu, index) => {
                    if (menu === menuSelected) {
                        if (index === 0) {
                            return (
                                <React.Fragment key={menu.Id}><a style={{ color: "white", backgroundColor: "rgb(76, 175, 80)", margin: "10px", padding: "5px", cursor: "pointer" }} onClick={() => { selectitem(menu); }}>{menu.Firstname}</a></React.Fragment>
                            )
                        } else {
                            return (
                                <React.Fragment key={menu.Id}>| <a style={{ color: "white", backgroundColor: "rgb(76, 175, 80)", margin: "10px", padding: "5px", cursor: "pointer" }} onClick={() => { selectitem(menu); }}>{menu.Firstname}</a></React.Fragment>
                            )
                        }
                    }
                    else {
                        if (index === 0) {
                            return (
                                <React.Fragment key={menu.Id}><a style={{ margin: "10px", padding: "10px", cursor: "pointer" }} onClick={() => { selectitem(menu); }}>{menu.Firstname}</a></React.Fragment>
                            )
                        } else {
                            return (
                                <React.Fragment key={menu.Id}>| <a style={{ margin: "10px", padding: "10px", cursor: "pointer" }} onClick={() => { selectitem(menu); }}>{menu.Firstname}</a></React.Fragment>
                            )
                        }
                    }
                })}
            </div>
            <Grid
                style={{ marginBottom: 30, textAlign: "end", justifyContent: "end", justifyItems: "end" }}
                container
                alignItems="flex-end"
                justifyContent="space-between">
                {menuSelected && menuSelected.Id !== 0 && (
                    <Grid item>
                        <AppButton
                            text={"Add Kindergarten"}
                            variant="outlined"
                            color="primary"
                            onClick={addNewKindergartensforChild}
                        />
                    </Grid>
                )}

            </Grid>

            <AppTable
                expandableRows
                columns={KindergartenkindergartenRegistrationListColumns(
                    t,
                    onRowExpandClick,
                    onDeleteItem
                )}
                data={stateSelected}
                expandableRowExpanded={(row) => row.RowExpandedToggle === true}
                conditionalRowStyles={conditionalRowStyles}
                expandOnRowClicked
                onRowExpandToggled={(
                    expanded: boolean,
                    row: KindergartenRegistrationListModal,
                ) => onRowExpandClick(row.Id as number)}
                expandableRowsComponent={(t) => (
                    <KindergartenListExpendedRow data={t.data} />
                )}
            />
        </div>
    );
};

export default KindergartenRegistrationList;
