import { Avatar, Grid, IconButton, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import { COLOR_PRIMARY } from "theme";
import { UserAvatarActionMenuList } from "./HeaderUserAvatarActionMenuList";

export interface UserAvatarProps {
    profileImageUrl: string;
    fullName: string;
    handleLogout: () => void;
    registrationType: string;
}
const useStyles = makeStyles(() =>
    createStyles({
        textColor: {
            color: COLOR_PRIMARY,
        },
        avatar: {
            width: "25px",
            height: "25px",
        },
        root: {
            display: "flex",
            justifyItems: "flex-end",
            width: "100%",
            cursor: "pointer",
        },
    }),
);
const UserAvatar = React.memo<UserAvatarProps>(
    ({ fullName, handleLogout, registrationType, profileImageUrl }) => {
        const classes = useStyles();

        return (
            <UserAvatarActionMenuList
                profileImageUrl={profileImageUrl}
                fullName={fullName}
                registrationType={registrationType}
                handleLogout={handleLogout}
                render={(handleClick) => (
                    <div className={classes.root} onClick={handleClick}>
                        <Grid
                            container
                            spacing={1}
                            justifyContent="flex-end"
                            alignItems="center">
                            <Grid item>
                                <Avatar className={classes.avatar} src={profileImageUrl} />
                            </Grid>
                            <Grid item>
                                <Typography
                                    className={classes.textColor}
                                    variant="body1">
                                    {fullName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <div>
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span">
                                <ArrowDropDownIcon />
                            </IconButton>
                        </div>
                    </div>
                )}
            />
        );
    },
);

export default UserAvatar;
