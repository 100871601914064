import { Control, RegisterOptions } from "react-hook-form";
export enum Gender {
    MALE = "1",
    FEMALE = "2",
}
export enum Locale {
    DE = "de",
    EN = "en",
}
export enum CareHours {
    FOUR_TO_FIVE = "1",
    FIVE_TO_SEVEN = "2",
    SEVEN_T0_NINE = "3",
    NINE_TO_TWELVE = "4",
}
export enum TypePerson {
    OWNER = "1",
    MANAGER = "0",
}
export interface SimpleOption {
    label?: string | number;
    value: string | number;
}

export interface AppControlProps {
    shouldUnregister?: boolean;
    control?: Control<any>;
    rules?: RegisterOptions;
}
export interface PaginationInfo {
    PageNo?: number;
    PageSize?: number;
}
// to load a <script>
export function loadScript(
    src: string,
    position: HTMLElement | null,
    id: string,
) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    // script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}
export enum CookiesKey {
    USER_COOKIE = "UserCookie",
    JWT_TOKEN = "JwtToken",
    USER_CLAIMS = "UserClaims",
}
export interface ExpandableRow {
    Id: string | number;
    RowExpandedToggle?: boolean;
}
export type svgType = React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }
>;
