export const SuperAdminEnTranslations = {
    superAdmin: {
        kindergartenList: {
            title1: "Kindergartens",
            title2: "Information about the kindergartens",
            rowsPerPage: "Rows per page",
            of: "of",
            end: "End",
            email: {
                title: "E-Mail Address",
                missing: "E-Mail Addresse is missing",
                invalid: "E-Mail Addresse is invalid",
            },
            kindergartenId: {
                title: "Kindergarten Id",
                missing: "Kindergarten Id is missing",
            },
            companyId: {
                title: "Company Id",
                missing: "Company Id is missing",
            },
            kindergartenName: {
                title: "Kindergarten Name ",
                missing: "Kindergarten Name is missing",
            },
            address: {
                title: "Address",
                missing: "Address is missing",
            },
            phone: {
                title: "Phone",
                missing: "Phone is missing",
            },
            registerPerson: {
                title: "Register Person",
                missing: "Register Person is missing",
            },
            firstName: {
                title: "First Name",
                missing: "First Name is missing",
            },
            lastName: {
                title: "Last Name",
                missing: "Last Name is missing",
            },
            country: {
                title: "Country ",
                missing: "Country  is missing",
            },
            city: {
                title: "City",
                missing: "City is missing",
            },
            postalCode: {
                title: "Postal Code",
                missing: "Postal Code is missing",
            },
            longitude: {
                title: "Longitude",
                missing: "Longitude is missing",
            },
            latitude: {
                title: "Latitude",
                missing: "Latitude is missing",
            },

        },
        column: {
            kindergartenRequest: {
                Reviewed: "Reviewed",
                Pending: "Pending",
                all: "All",
            },
            requests: "Requests",
            registered: "Registered",
            status: {
                status: "Status",
                yes: "Yes",
                no: "No",
            },
        },
        button: {
            addKindergarten: "Add Kindergarten",
        },
        search:{
            input: "Kindergarten or Address",
            button: "Search",
        },        
    },
};
export default SuperAdminEnTranslations;
