import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { AppButton, AppPrimaryButtonWithForwardIcon } from "components";
import React, { useState } from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { RegistrationListExpendedRow } from "screens/RegistrationList";
import { AllKindergartensModal} from "sdk";
import AllKindergartensExpendedRowFirstContainer from "./AllKindergartensExpendedRowFirstContainer";
import AllKindergartensExpendedRowSecondContainer from "./AllKindergartensExpendedRowSecondContainer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: "50px",
            paddingRight: "20px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
        },
        title: { paddingBottom: theme.spacing(3) },
        body: {
            display: "flex",
            width: "100%",
        },

        saveButton: { marginTop: theme.spacing(5)},
    }),
);
export interface AllKindergartensExpendedRowProps
    extends ExpanderComponentProps<AllKindergartensModal> {
    onSubmit: (value: AllKindergartensModal) => void;
    requests: ()=> void
}
export const AllKindergartensExpendedRow: React.FC<AllKindergartensExpendedRowProps> =
    ({ data, onSubmit, requests }) => {
        const { t } = useTranslation();
        const classes = useStyles();
        const { control, handleSubmit } = useForm<AllKindergartensModal>(
            {
                defaultValues: {
                    ...data,
                },
            },
        );
        const values = true;
        const [readOnly, setReadOnly] = useState(values);

        const submit = handleSubmit((data) => {
            onSubmit({
                ...(data as AllKindergartensModal),
            });
            toast.success(t("common.successfullySaved"));
            setReadOnly(true);
        });
        const onEditClick = () => {
            setReadOnly(false);
        };
        return (
            <div className={classes.root}>
                <div style={{ maxWidth: "1200px" }}>
                    <form noValidate={true} onSubmit={submit}>
                        <div className={classes.title}>
                            <Typography variant="h5">
                                {t("superAdmin.kindergartenList.title2")}
                            </Typography>
                        </div>
                        <div className={classes.body}>
                            <AllKindergartensExpendedRowFirstContainer
                                infoState={data}
                                control={control}
                                readonly={readOnly}
                            />
                            <AllKindergartensExpendedRowSecondContainer
                                control={control}
                                readonly={readOnly}
                                infoState={data}
                            />
                        </div>
                        <Grid
							container
							justifyContent="center"
							className={classes.saveButton}>
                            <AppPrimaryButtonWithForwardIcon
                                    type={readOnly ? "button" : "submit"}
                                    style={{ minWidth: "187px" }}
                                    onClick={() => readOnly && onEditClick()}
                                    text={t(
                                        `common.${readOnly ? "edit" : "save"}`,
                                    )}
                                    />							
							
                            <AppPrimaryButtonWithForwardIcon
                                    type={"submit"}
                                    style={{ minWidth: "187px" , marginLeft: 40}}
                                    onClick={requests}
                                    text="Requests"
                                />
						</Grid>          
                    </form>
                </div>
            </div>
        );
    };
export default RegistrationListExpendedRow;
