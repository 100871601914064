import {
    FormHelperText,
    Grid,
    GridProps,
    GridSpacing,
    RadioGroup,
    RadioGroupProps,
    StandardProps,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import {
    CustomRadioButton,
    CustomRadioButtonProps,
} from "components/Inputs/RadioButtons";
import { StyleClassKey } from "globals";
import React from "react";
const styles = () =>
    createStyles({
        root: {},
    });

type AppRadioBoxesClassKey = StyleClassKey<typeof styles>;
export interface AppRadioBoxesProps
    extends StandardProps<RadioGroupProps, AppRadioBoxesClassKey> {
    checkBoxes: CustomRadioButtonProps[];
    name: string;
    defaultValue?: string;
    error?: boolean;
    helperText?: string;
    gridProps?: GridProps;
    columnSpacing?: GridSpacing;
}

const useStyles = makeStyles(styles);

export const AppRadioBoxes = React.memo<AppRadioBoxesProps>(
    ({
        checkBoxes,
        defaultValue,
        columnSpacing,
        error,
        helperText,
        gridProps,
        ...rest
    }) => {
        const classes = useStyles(rest);
        return (
            <div className={classes.root}>
                <RadioGroup {...rest}>
                    <Grid container spacing={columnSpacing}>
                        {checkBoxes.map((checkbox, index) => (
                            <Grid item {...gridProps} key={index}>
                                <CustomRadioButton {...checkbox} />
                            </Grid>
                        ))}
                    </Grid>
                    {helperText && (
                        <FormHelperText error={error}>
                            {helperText}
                        </FormHelperText>
                    )}
                </RadioGroup>
            </div>
        );
    },
);

export default AppRadioBoxes;
