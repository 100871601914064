import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import {
    AppControlledDatePicker,
    AppControlledRadioButton,
    AppLabelWrapper,
    AppTextField,
} from "components";
import { Gender } from "globals/Types";
import moment from "moment-timezone";
import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RegistrationListModal } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            marginRight: theme.spacing(30),
        },
        fullWidth: { width: "100%" },
    }),
);
export interface RegistrationListExpendedRowFirstContainerProps {
    readonly: boolean;
    control: Control<RegistrationListModal>;
    parentState: RegistrationListModal;
}
export const RegistrationListExpendedRowFirstContainer: React.FC<RegistrationListExpendedRowFirstContainerProps> =
    ({ control, readonly, parentState }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        return (
            <Grid container spacing={2} className={classes.root}>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        required={true}
                        readOnly={readonly}
                        label={t(
                            "registrationForChildren.step1.firstNameChild.title",
                        )}
                        value={parentState.FirstNameChild}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            name="FirstNameChild"
                            rules={{
                                required: t(
                                    "registrationForChildren.step1.firstNameChild.missing",
                                ) as string,
                            }}
                        />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        readOnly={readonly}
                        value={parentState.LastNameChild}
                        required={true}
                        label={t(
                            "registrationForChildren.step1.lastNameChild.title",
                        )}
                        direction="top">
                        <AppTextField
                            fullWidth
                            control={control}
                            rules={{
                                required: t(
                                    "registrationForChildren.step1.lastNameChild.missing",
                                ) as string,
                            }}
                            name="LastNameChild"
                        />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        label={t("registrationForChildren.step1.gender.title")}
                        direction="top"
                        readOnly={readonly}
                        value={t(
                            `common.genderTable.${parentState.Gender?.toString()}`,
                        )}
                        required>
                        <AppControlledRadioButton
                            defaultValue={parentState.Gender?.toString()}
                            name="Gender"
                            control={control}
                            rules={{
                                required: t(
                                    "registrationForChildren.step1.gender.missing",
                                ) as string,
                            }}
                            gridProps={{ xs: 6 }}
                            checkBoxes={[
                                {
                                    value: Gender.MALE,
                                    label: t("common.gender.MALE"),
                                },
                                {
                                    value: Gender.FEMALE,
                                    label: t("common.gender.FEMALE"),
                                },
                            ]}
                        />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        direction="top"
                        readOnly={readonly}
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        value={t("dates.dateFormat", {
                            date: moment(parentState.BirthdayOfTheChild),
                        })}
                        label={t(
                            "registrationForChildren.step1.birthdayOfTheChild.title",
                        )}
                        required>
                        <AppControlledDatePicker
                            fullWidth
                            shouldUnregister={true}
                            name="BirthdayOfTheChild"
                            control={control}
                            rules={{
                                required: t(
                                    "registrationForChildren.step1.birthdayOfTheChild.missing",
                                ) as string,
                            }}
                        />
                    </AppLabelWrapper>
                </Grid>
            </Grid>
        );
    };

export default RegistrationListExpendedRowFirstContainer;
