import { Grid, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import { AppSelect } from "components/Inputs/ComboBox";
import { AppControlProps } from "globals";
import React from "react";
import { useTranslation } from "react-i18next";
import { Suggestion } from "use-places-autocomplete";
export interface MapAutoCompleteFieldProps extends AppControlProps {
    data: Suggestion[];
    iconLocationClass: string;
    name: string;
    loading: boolean;
    defaultValue?: any;
}
export const MapAutoCompleteField: React.FC<MapAutoCompleteFieldProps> = ({
    data,
    iconLocationClass,
    control,
    name,
    rules,
    loading,
    defaultValue
}) => {
    const { t } = useTranslation();

    return (
        <AppSelect
            freeSolo={true}
            textfieldprops={{
                removeShadow: true,
                customBorderRadius: "0px",
                placeholder: t("home.address"),
            }}
            name={name}
            control={control}
            defaultValue={{description: defaultValue}}
            loading={loading}
            rules={rules}
            showerrorsintooltip={"true"}
            getOptionLabel={(option) => option.description as string}
            selectedvaluekey="description"
            options={data as Suggestion[]}
            tooltipprops={{ arrow: true }}
            renderOption={(option) => {
                const matches =
                    option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [
                        match.offset,
                        match.offset + match.length,
                    ]),
                );

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={iconLocationClass} />
                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}>
                                    {part.text}
                                </span>
                            ))}
                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};

export default MapAutoCompleteField;
