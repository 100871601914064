import { Grid } from "@material-ui/core";
import moment from "moment-timezone";
import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FacilitiesProfileModel} from "sdk";
import { ProfileContainerHeader } from "./ProfileContainerHeader";
import { ProfileFiledOrTextContainer } from "./ProfileFiledOrTextContainer";
export interface FacilitiesProfilePersonalInfoProps {
    control?: Control<FacilitiesProfileModel>;
    isEdit?: boolean;
    value?: FacilitiesProfileModel;
}

export const FacilitiesProfilePersonalInfo: React.FC<FacilitiesProfilePersonalInfoProps> = ({
    control,
    isEdit = false,
    value,
}) => {
    const { t } = useTranslation();

    return (
        <ProfileContainerHeader
            firstTitle={t("companyProfile.company")}
            secondTitle={t("profile.information")}>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("companyProfile.companyName.title")}
                    fieldType="text"
                    isEdit={isEdit}
                    control={control}
                    value={value?.Name}
                    name="Name"
                    errorMessage={t("companyProfile.companyName.missing")}
                />
            </Grid>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("profile.firstName.title")}
                    fieldType="text"
                    isEdit={isEdit}
                    control={control}
                    value={value?.FirstName}
                    name="FirstName"
                    errorMessage={t("profile.firstName.missing")}
                />
            </Grid>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("profile.lastName.title")}
                    value={value?.LastName}
                    isEdit={isEdit}
                    fieldType="text"
                    control={control}
                    name="LastName"
                    errorMessage={t("profile.lastName.missing")}
                />
            </Grid>
            <Grid item>
                <ProfileFiledOrTextContainer
                    title={t("companyProfile.email.title")}
                    value={value?.Email}
                    fieldType="text"
                    isEdit={isEdit}
                    control={control}
                    name="Email"
                    errorMessage={t("companyProfile.email.missing")}
                />
            </Grid>
        </ProfileContainerHeader>
    );
};

export default FacilitiesProfilePersonalInfo;
