import {
    HomeEnTranslations,
    AuthEnTranslations,
    RegistrationForChildrenEnTranslations,
    CommonEeTranslations,
    RegistrationListEnTranslations,
    ProfileEnTranslations,
    ImprintEnTranslations,
    ContactsEnTranslations,
    RegistrationForParentEnTranslations,
    SuperAdminEnTranslations,
} from "./subGroups";
import { KindergartenEnTranslations } from "./subGroups/Kindergarten";
export const deTranslation = {
    translation: {
        ...HomeEnTranslations,
        ...AuthEnTranslations,
        ...RegistrationForChildrenEnTranslations,
        ...CommonEeTranslations,
        ...RegistrationListEnTranslations,
        ...ProfileEnTranslations,
        ...ImprintEnTranslations,
        ...ContactsEnTranslations,
        ...RegistrationForParentEnTranslations,
        ...KindergartenEnTranslations,
        ...SuperAdminEnTranslations,
        mapSearch: {
            kindergartenNearYou: "Kindergartens near you",
            found: "Found",
            kindergarten: "Kindergarten",
            registerYourChild: "Register your child in this kindergarten",
            selectAllResults: "Select all results",
            unSelectAllResults: "Unselect all results",
            error: {
                noResultFound: "Address is not associated with a zip code",
            },
        },
        selectAllResults: {
            title: "Select All Result",
            inYourRange: "In your range",
            kindergartensHaveBeenFound: "kindergartens have been found",
            areFreeToRegistrate: "are free to registrate",
            goldUser:
                "For registrating to all kinddergarten you need to registrate to be Gold User",
            ourServiceIs: "Our Service is",
            continueWithoutPayment: "Continue without payment",
            payWith: "Pay with",
            continueWithoutPaymentAlert:
                "Continue without payment will select first 5 kindergartens",
        },
        footer: {
            about: "ABOUT",
            contact: "Contact",
            imprint: "Imprint",
            jobs: "Jobs",
            believedSearch: "BELIEVED SEARCH",
            daycareCenters1: "Daycare centers in Steglitz",
            daycareCenters2: "Daycare centers in Schöneberg",
        },
        nav: {
            home: "Home",
            contact: "Contact",
            Imprint: "Imprint",
            areYouKindergarten: "Are you a kindergarten?",
            menu: {
                childList: "Child List",
                kindergartensList: "Kindergartens List",
                profile: "Profile",
                logout: "Log out",
            },
        },
        dates: {
            dateFormat: `{{date,dateFormat}}`,
            dateTimeFormat: `{{date,dateTimeFormat}}`,
        },
    },
};
export default deTranslation;
