export const HomeEnTranslations = {
    home: {
        home: "Home",
        yourKitaplatz: "kitaplatz.",
        stressFree: "Stress-free & Transparent",
        findingPlace:
            "Finding a suitable daycare place has been a frustrating process for parents so far.",
        weAreChangingThat: "We are changing that.",
        zipCode: "ZipCode",
        address: "Street - House number",
        compileKitaWishlist: "01.Compile Kita wish list",
        applyDirectlyOnline: "02.Apply directly online",
        ViewApplicationStatus: "03.View application status",
        overviewTitle: "Overview of all daycare centers and Compile wish list",
        overviewBody1:
            "We search all registered daycare centers for you. Only a few details about you and your child provide suitable daycare center suggestions.",
        overviewBody2:
            "You can filter daycare centers according to your preferences (e.g. size, concept, distance, etc.). In addition, you will receive current information about the daycare centers of your choice (e.g. open house).",
        createApplicationTitle:
            "Create application once & send to n selected daycare centers",
        createApplicationBody1:
            "Instead of filling out and submitting individual application forms for each daycare center, from now on you only need to create your application documents once.",
        createApplicationBody2:
            "We send your documents to the daycare centers you have selected. Completely automated.",
        applicationStatus: "Application status View centrally",
        applicationStatusBody1:
            "After your applications have been sent, you can see if daycare centers have decided to hire you or if you are on waiting lists.",
        applicationStatusBody2:
            "Even if you don't get a place: As soon as a daycare center has a place available again, we will automatically send a reminder to the daycare center with your documents.",
        compareToTheAlternative: "Compared to the alternative",
        compareToBody1: "There is a lack of alternatives.",
        compareToBody2:
            "Normally, one is allowed to torture oneself through the lengthy and manual process of finding a daycare center.",
        centralized: "Centralized",
        centralizedBody:
            "On Kitaplatz.de you can view and manage your applications to all the Kitas of your manage them.",
        lowEffort: "Low effort",
        lowEffortBody:
            "Searching for daycare centers, compiling a wish list and applying directly online has never been easier.",
        history: "Histories",
        historyBody:
            "Since everything is centralized, it is very easy for you, to track when and where you applied.",
        feedback: "Feedback",
        feedbackBody:
            "The Kitaplatz.de online application process makes it easy for daycare centers to give you quick feedback.",
        weCantConjure: "We can't conjure up daycare places.",
        everyThingElseDose: "Everything else does.",
        weCantConjureBody:
            "Even if you don't get a place: As soon as a daycare center has a place available again, we will automatically send a reminder to the daycare with your documents.",
        areaSearch: "Area search"
    },
};
export default HomeEnTranslations;
