import { RoutePathInterface } from "globals";
import { appRoutesForContext } from "routing/react-route-paths";

export const HomePath: RoutePathInterface = {
    path: appRoutesForContext().public().homePath,
    key: [],
};
export const ContactPath: RoutePathInterface = {
    path: appRoutesForContext().public().contactUsPath,
    key: [],
};
export const ImprintPath: RoutePathInterface = {
    path: appRoutesForContext().public().imprintPath,
    key: [],
};
export const PublicRoutePathList: RoutePathInterface[] = [
    HomePath,
    ContactPath,
    ImprintPath,
];
