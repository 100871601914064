import { RouteInterface } from "globals";
import { HomeLayout } from "layouts";
import { AllKindergartens } from "../AllKindergartens";
import { AllParents } from "../AllParents";
import { KindergartenRequests } from "../KindergartenRequests";
import { AllKindergartensPath, AllParentsPath, KindergartenRequestsPath } from "./superAdmin-routes-path";


export const SuperAdminRouteList: RouteInterface[] = [
    {
        component: AllKindergartens,
        exact: true,
        layout: HomeLayout,
        path: AllKindergartensPath.path,
        uniqueKey: AllKindergartensPath.key,
    },
    {
        component: AllParents,
        exact: true,
        layout: HomeLayout,
        path: AllParentsPath.path,
        uniqueKey: AllParentsPath.key,
    },
    {
        component: KindergartenRequests,
        exact: true,
        layout: HomeLayout,
        path: KindergartenRequestsPath.path,
        uniqueKey: KindergartenRequestsPath.key,
    },
];
