//Colors

export const COLOR_PRIMARY = "#2196f3";
export const COLOR_PINK = "#ff0083";
export const COLOR_SECONDARY = "#4caf50";
export const COLOR_DARK_BLUE = "#2196f3";
export const COLOR_PRIMARY_DARK = "#169eff";
export const COLOR_PURPLE_LIGHT = "#9900cc";
//using for secondary color for back buttons
export const COLOR_GRAY_WITH_LIGHT_BLUE = "#d8eafc";
//using is map search side bar gradient bg
export const COLOR_LIGTHEST_BLUE = "#eaf4fd";
export const COLOR_Light_BLUE = "#c7e4fc";
export const COLOR_TEXT_DARK = "#2c3e50";
export const COLOR_WHITE = "#ffffff";
export const COLOR_LIGHT_GREEN = "#31dc8b";
export const COLOR_PURPLE = "#3d3a56";
export const COLOR_GRAY = "#637587";
export const COLOR_BOX_SHADOW = "rgba(0,0,0,0.1)";
export const COLOR_LIGHT_BLUE_TABLE_HEADER_BG = "#f3fafe";
export const COLOR_PURPLE_LIGHT_FOOTER = "#aba8c1";
export const COLOR_GRAY_900 = "#7697ae";
export const BREAK_POINT_XL = 2560;

//Height and width and designs

export const HEIGHT_OF_HEADER = "63.98px";
export const HEIGHT_OF_FOOTER = "200px";

export const FULL_HEIGHT_OF_DIALOG_WITHOUT_FOOTER_AND_HEADER = `calc(100vh - 263.98px)`;
export const MIN_HEIGHT_OF_CONTACTS = `calc(100vh - 663.98px)`;
export const FULL_HEIGHT_OF_DIALOG_WITHOUT_FOOTER = `calc(100vh - ${HEIGHT_OF_FOOTER})`;
export const HEIGHT_OF_DIALOG_HEADER = "64px";
export const FULL_HEIGHT_OF_DIALOG_WITHOUT_HEADER = `calc(100vh - ${HEIGHT_OF_DIALOG_HEADER})`;
export const FULL_VIEW_HEIGHT_WITHOUT_HEADER = `calc(100vh - ${HEIGHT_OF_HEADER})`;
export const FULL_HEIGHT_OF_MAP_SEARCH_SIDEBAR_SCREEN = `calc(100vh - 213.98px)`;
export const FULL_HEIGHT_OF_MAP_KINDERGARTEN_LIST = `calc(100vh - 540px)`;
export const MEDIA_QUERY_XL = `@media (min-width:${BREAK_POINT_XL}px)`;
export const BOX_SHADOW = `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`;
export const BG_GRADIENT = `linear-gradient(180deg, ${COLOR_WHITE} 30%, ${COLOR_LIGTHEST_BLUE} 50%)`;
export const CHILDREN_REGISTRATION_MAX_WIDTH = "700px";

export const DASHBOARD_FOOTER_LEFT_RIGHT_PADDING = "0 20% 0 20%";
