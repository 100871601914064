export interface DataMapSearch {
    data: KindergartenMapDataList[];
}

export interface KindergartenMapDataList {
    Address: string;
    Name: string;
    lng: number;
    lat: number;
    Id: number;
    PostalCode: number;
    IsSelected?: boolean;
}

export const getMapSearchInitialValue =
    (): KindergartenMapDataList => {
        return {
            Address: "",
            Name: "",
            lng: 0,
            lat: 0,
            Id: 0,
            PostalCode: 0,
        };
    };

export const KindergartenTestingList: KindergartenMapDataList[] = [
    {
        Id: 1,
        Address: "Berliner Straße 2 23627 Groß Grönau Schleswig-Holstein",
        lat: 53.8215894785127,
        lng: 10.726866485300738,
        PostalCode: 23627,
        Name: "Ev. Kita Grß Grönau",
    },
    {
        Id: 2,
        Address: "Berliner Straße 1 24852 Eggebek Schleswig-Holstein",
        lat: 54.61749573965454,
        lng: 9.36867286812311,
        PostalCode: 24852,
        Name: "Kita Beek-Spatzen",
    },
    {
        Id: 3,
        Address: "Berliner Straße 58 25813 HusumSchleswig-Holstein",
        lat: 54.48939838617998,
        lng: 9.05237801600343,
        PostalCode: 25813,

        Name: "Kita Beek-Spatzen",
    },
    {
        Id: 4,
        Address:
            "Integrative Kita BärenhöhleDRK Kita Husum Berliner Straße 58 25813 Husum Schleswig-Holstein",
        lat: 54.48939838617998,
        lng: 9.05237801600343,
        PostalCode: 21465,

        Name: "Ev. Kita Kinderarche",
    },

    {
        Id: 5,
        Address:
            "Kindertagesstätte Uns Lütten Berliner Straße 52 1465 ReinbekSchleswig-Holstein",
        lat: 53.49660554956439,
        lng: 10.258254827621625,

        PostalCode: 1465,
        Name: "Kita Lillesand",
    },
    {
        Id: 6,
        Address: "Berliner Straße 20 24782 Büdelsdorf Schleswig-Holstein",
        lat: 54.31710257527468,
        lng: 9.687402671819733,
        PostalCode: 24782,
        Name: "Johanniter-Kita Pavillon",
    },
    {
        Id: 7,
        Address: "Berliner Damm 14 25479 EllerauSchleswig-Holstein",
        lat: 53.75227397972249,
        lng: 10.25095,
        PostalCode: 25479,
        Name: "Kinderland Lurup",
    },

    {
        Id: 9,
        Address:
            "Eichendorffstraße 17 10115 Berlin Berlin / Mitte (Tiergarten, Wedding)",
        lat: 52.5317178154166,
        lng: 13.387334179860359,
        PostalCode: 10115,
        Name: "Kita Kinderinsel e.V.",
    },
    {
        Id: 10,
        Address:
            "Gartenstraße 107 10115 Berlin Berlin / Mitte (Tiergarten, Wedding)",
        lat: 52.51534506452944,
        PostalCode: 10115,
        lng: 13.349577168021106,
        Name: "Kita Gartenstraße 107",
    },

    {
        Id: 11,
        Address:
            "Habersaathstraße 13 10115 Berlin Berlin / Mitte (Tiergarten, Wedding)",
        lat: 52.53132807962378,
        lng: 13.375066871780314,
        PostalCode: 10115,

        Name: "Kita Kinderakademie",
    },
    {
        Id: 12,
        Address:
            "Invalidenstraße 103a 10115 Berlin Berlin / Mitte (Tiergarten, Wedding)",
        lat: 52.529709548377355,
        PostalCode: 10115,
        lng: 13.381270371780145,

        Name: "Kindergarten Charité Mitte",
    },
    {
        Id: 13,
        Address:
            "Große Hamburger Straße 28 10115 Berlin Berlin / Mitte (Tiergarten, Wedding)",
        lat: 52.51517935327949,
        lng: 13.349986040829506,
        PostalCode: 10115,
        Name: "Ev. Kita Sophien",
    },
    {
        Id: 14,
        Address:
            "Elisabethkirchstraße 21 10115 Berlin Berlin / Mitte (Tiergarten, Wedding)",
        lat: 52.54468604012852,
        lng: 13.396033605917804,

        PostalCode: 10115,
        Name: "Ev. Kita St. Elisabeth",
    },
];

export interface MapSearchParams {
    address?: string;
    lng?: string;
    lat?: string;
    zipCode?: string;
}

export interface MapSearchRequest {
    lng: number | null;
    lat: number | null;
    address?: string | null;
    zipCode?: number | null;
    distance?: number | null;
}
