import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { AppLabelWrapper } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { KindergartenRegistrationListModal } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        fullWidth: { width: "100%" },
    }),
);
export interface KindergartenListExpendedRowFirstContainerProps {
    parentState: KindergartenRegistrationListModal;
}
export const KindergartenListExpendedRowFirstContainer: React.FC<KindergartenListExpendedRowFirstContainerProps> =
    ({ parentState }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        return (
            <Grid
                container
                spacing={2}
                justifyContent="center"
                className={classes.root}>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        readOnly
                        color={"textSecondary"}
                        label={t("kindergartenRegistrationList.childRow.name")}
                        value={parentState.Name}
                        direction="top"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={"textSecondary"}
                        readOnly
                        value={parentState.City}
                        label={t("kindergartenRegistrationList.childRow.city")}
                        direction="top"
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        variant="button"
                        color={"textSecondary"}
                        readOnly
                        label={t(
                            "kindergartenRegistrationList.childRow.address",
                        )}
                        direction="top"
                        value={parentState.Address}
                    />
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        direction="top"
                        variant="button"
                        color={"textSecondary"}
                        readOnly
                        value={parentState.IndexNumber.toString()}
                        label={t(
                            "kindergartenRegistrationList.childRow.indexNumber",
                        )}
                    />
                </Grid>
            </Grid>
        );
    };

export default KindergartenListExpendedRowFirstContainer;
