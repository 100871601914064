import moment, { Moment } from "moment-timezone";
export interface ProfileModel {
    Id?: number;
    FirstName: string;
    LastName: string;
    Country: string;
    DateOfBirth: Moment;
    PostalCode: string;
    Address: string;
    PhoneNumber: string;
    City: string;
    ProfilePicture?: string;
}

export interface ProfileUpdateRequest {
    FirstName: string;
    LastName: string;
    DateOfBirth: Moment;
    PostalCode: string;
    Country: string;
    Address: string;
    PhoneNumber: string;
    City: string;
    ImageFile?: File;
}

export interface FacilitiesProfileModel {
    UserId: number;
    Name: string;
    FirstName: string;
    LastName: string;
    Email: string;

    Country: string;
    Address: string;
    City: string;
    PostalCode: string;
    ProfilePicture?: string;

}

export interface FacilitiesProfileUpdateRequest {
    kindergartenName: string;
    firstName: string;
    lastName: string;
    email: string;

    country: string;
    address: string;
    city: string;
    postalCode: string;
}

export interface FacilitiesProfileImageUpdateRequest {
    imageFile?: File[];
}

export let DefaultProfiles: ProfileModel[] = [
    {
        Address: "Hauptstraße, 112",
        FirstName: "John",
        City: "Berlin",
        PostalCode: "12345",
        PhoneNumber: "+700 000 00 00",
        Country: "Germany",
        DateOfBirth: moment().add(5, "days"),
        LastName: "Deo",
        Id: 1,
    },
];
