import { Button, Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { removeCookie } from "globals/cookie";
import { CookiesKey } from "globals/Types";
import UserAvatar from "layouts/Header/HeaderUserAvatar";
import { useUserContext } from "providers";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { appRoutesForContext } from "routing/react-route-paths";
import {
    COLOR_PRIMARY,
    COLOR_TEXT_DARK,
    COLOR_WHITE,
    HEIGHT_OF_HEADER
} from "theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            height: HEIGHT_OF_HEADER,
            background: COLOR_WHITE,
        },
        appHeader: {
            boxShadow: "0px 0px",
        },
        appToolBar: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: theme.spacing(1),

            marginRight: theme.spacing(20),
            marginLeft: theme.spacing(20),
            [theme.breakpoints.down("md")]: {
                marginRight: theme.spacing(5),
                marginLeft: theme.spacing(5),
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        navLink: {
            textDecoration: "none",
            color: COLOR_TEXT_DARK,
            "&:focus": {
                color: COLOR_PRIMARY,
            },
        },
    }),
);

export const AppHeader = () => {
    const classes = useStyles();
    const { user, setUser, lng, setLanguage } = useUserContext();
    let history = useHistory();
    const { t } = useTranslation();

    const location = useLocation();

    const onLoginButtonClick = () => {
        history.replace(
            appRoutesForContext()
                .auth()
                .login.setLoginPathParams(location.pathname),
        );
    };
    const handleChangeLanguage = () => {
        setLanguage && setLanguage(lng === "de" ? "en" : "de");
    };
    const handleLogout = () => {
        if (setUser) {
            setUser(null);
        }
        removeCookie(CookiesKey.USER_COOKIE);
        removeCookie(CookiesKey.JWT_TOKEN);
        history.push(appRoutesForContext().public().homePath);
    };
    return (
        <>
            <div className={classes.root}>
                <AppBar
                    position="static"
                    color="transparent"
                    className={classes.appHeader}>
                    <Toolbar className={classes.appToolBar}>
                        <Grid container spacing={3}>
                            {(user && user.Type !== "Parent") ? (
                                <Grid item>
                                </Grid>
                            ) : (
                                <Grid item>
                                    <NavLink
                                        activeStyle={{ color: COLOR_PRIMARY }}
                                        className={classes.navLink}
                                        to={
                                            appRoutesForContext().public().homePath
                                        }>
                                        <Typography variant="body1">
                                            {t("nav.home")}
                                        </Typography>
                                    </NavLink>
                                </Grid>
                            )}
                            {/*  <Grid item>
                                <NavLink
                                    exact
                                    activeStyle={{ color: COLOR_PRIMARY }}
                                    className={classes.navLink}
                                    to={
                                        appRoutesForContext().public()
                                            .contactUsPath
                                    }>
                                    <Typography variant="body1">
                                        {t("nav.contact")}
                                    </Typography>
                                </NavLink>
                            </Grid> */}
                            {/* <Grid item>
                                <NavLink
                                    activeStyle={{ color: COLOR_PRIMARY }}
                                    className={classes.navLink}
                                    exact
                                    to={
                                        appRoutesForContext().public()
                                            .imprintPath
                                    }>
                                    <Typography variant="body1">
                                        {t("nav.Imprint")}
                                    </Typography>
                                </NavLink>
                            </Grid> */}
                            {user ? (
                                <Grid item>
                                </Grid>
                            ) : (
                                <Grid item>
                                    <NavLink
                                        activeStyle={{ color: COLOR_PRIMARY }}
                                        className={classes.navLink}
                                        exact
                                        to={
                                            appRoutesForContext().facilitiesProfile().facilitiesPath
                                        }>
                                        <Typography variant="body1">

                                            {t("nav.areYouKindergarten")}


                                        </Typography>
                                    </NavLink>
                                </Grid>
                            )}

                        </Grid>
                        {user ? (
                            <Grid
                                container
                                justifyContent="flex-end"
                                spacing={2}>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleChangeLanguage}
                                        style={{ borderRadius: "0px" }}
                                        size="large">
                                        <Typography variant="button">
                                            {lng.toLocaleUpperCase()}
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <UserAvatar
                                        profileImageUrl={user.ProfileImageUrl}
                                        handleLogout={handleLogout}
                                        fullName={
                                            user?.Fullname ? user?.Fullname : ""
                                        }
                                        registrationType={user.Type}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                container
                                justifyContent="flex-end"
                                spacing={1}>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleChangeLanguage}
                                        style={{ borderRadius: "0px" }}
                                        size="large">
                                        <Typography variant="button">
                                            {lng.toLocaleUpperCase()}
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ borderRadius: "0px" }}
                                        size="large"
                                        onClick={onLoginButtonClick}>
                                        <Typography variant="button">
                                            {t("auth.login")}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Toolbar>
                </AppBar>
            </div>
        </>
    );
};
export default AppHeader;
