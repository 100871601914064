import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ReactComponent as CountryHouse } from "assets/images/icons/country-house.svg";
import { ReactComponent as FileCheck } from "assets/images/icons/file-check.svg";
import { ReactComponent as Location } from "assets/images/icons/location.svg";
import { ReactComponent as MessageCheck } from "assets/images/icons/message-check.svg";
import { AppContainerWithStripeBorder, AppDetailsWithIcon } from "components";
import AppButton from "components/Buttons/AppButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { appRoutesForContext } from "routing/react-route-paths";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            paddingTop: "50px",
            paddingLeft: "50px",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            maxHeight: "1200px",

            [theme.breakpoints.up("lg")]: {
                paddingBottom: "100px",
            },
            [theme.breakpoints.up("xl")]: {
                paddingBottom: "40px",
                paddingTop: "100px",
            },
            [theme.breakpoints.up("xll")]: {
                paddingBottom: "0px",
                paddingTop: "150px",
            },
        },
        findDaycareSection: {
            backgroundColor: "#fafdff",
            marginTop: "40px",
            [theme.breakpoints.up("lg")]: {
                marginTop: "150px",
            },
        },
        findDaycareContent: {
            textAlign: "center",
            //mobile
            paddingTop: theme.spacing(3),
        },
        findDaycareContentBody: {
            paddingRight: theme.spacing(18),
            paddingLeft: theme.spacing(18),

            textAlign: "center",
        },
        searchButtonContainer: {
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        findNewContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",

            [theme.breakpoints.up("xl")]: {
                paddingTop: "80px",
                paddingBottom: "80px",
            },
            [theme.breakpoints.up("xll")]: {
                paddingTop: "120px",
                paddingBottom: "120px",
            },
        },
        icon: { width: "30px", height: "35px" },
    }),
);
export const HomeSection3 = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isHd = useMediaQuery(theme.breakpoints.up("md"));
    const isFullHd = useMediaQuery(theme.breakpoints.up("lg"));
    const isQhd = useMediaQuery(theme.breakpoints.up("xl"));
    const is4k = useMediaQuery(theme.breakpoints.up("xll"));

    const heightOfBoarderContainer = is4k
        ? "590px"
        : isQhd
        ? "500px"
        : isFullHd
        ? "450px"
        : isHd
        ? "350px"
        : "350px";
    let history = useHistory();
    return (
        <div className={classes.root}>
            <Grid container spacing={6}>
                <Grid item xs={3}>
                    <AppDetailsWithIcon
                        iconUrl={<CountryHouse className={classes.icon} />}
                        title={t("home.centralized")}
                        text={t("home.centralizedBody")}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AppDetailsWithIcon
                        iconUrl={<FileCheck className={classes.icon} />}
                        title={t("home.lowEffort")}
                        text={t("home.lowEffortBody")}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AppDetailsWithIcon
                        iconUrl={<Location className={classes.icon} />}
                        title={t("home.history")}
                        text={t("home.historyBody")}
                    />
                </Grid>
                <Grid item xs={3}>
                    <AppDetailsWithIcon
                        iconUrl={<MessageCheck className={classes.icon} />}
                        title={t("home.feedback")}
                        text={t("home.feedbackBody")}
                    />
                </Grid>
            </Grid>
            <AppContainerWithStripeBorder
                containerWidth="90%"
                containerHeight={heightOfBoarderContainer}
                borderRadius={is4k ? 13 : 10}
                strokeWidth={is4k ? 3 : 2}
                className={classes.findDaycareSection}>
                <div className={classes.findNewContainer}>
                    <div className={classes.findDaycareContent}>
                        <Typography variant="h4">
                            {t("home.weCantConjure")}
                        </Typography>
                        <div className={classes.findDaycareContentBody}>
                            <div
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "15px",
                                    color: theme.palette.success.main,
                                }}>
                                <Typography variant="h4">
                                    {t("home.everyThingElseDose")}
                                </Typography>
                            </div>
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ lineHeight: 1.8 }}>
                                {t("home.weCantConjureBody")}
                            </Typography>
                        </div>
                    </div>

                    <div className={classes.searchButtonContainer}>
                        <AppButton
                            text={t("common.findDaycareCentersNearMe")}
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowForwardIcon />}
                            onClick={() =>
                                history.replace(
                                    appRoutesForContext().mapSearch()
                                        .mapSearchPath,
                                )
                            }
                        />
                    </div>
                </div>
            </AppContainerWithStripeBorder>
        </div>
    );
};

export default HomeSection3;
