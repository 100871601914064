import { RouteInterface } from "globals";
import { EmptyLayout, HomeLayout } from "layouts";
import { AddNewFacilitiesView } from "../AddNewFacilities";
import { AddPasswordView } from "../AddPasswordView";
import { Facilities } from "../FacilitiesSection";
import {  AddNewFacilitiesViewPath, AddPasswordViewPath, FacilitiesPath } from "./facilities-routes-path";

export const FacilitiesRouteList: RouteInterface[] = [
    {
        component: Facilities,
        exact: true,
        layout: HomeLayout,
        path: FacilitiesPath.path,
        uniqueKey: FacilitiesPath.key,
    },
    {
        component: AddPasswordView,
        exact: true,
        layout: EmptyLayout,
        path: AddPasswordViewPath.path,
        uniqueKey: AddPasswordViewPath.key,
    },
    {
        component: AddNewFacilitiesView,
        exact: true,
        layout: EmptyLayout,
        path: AddNewFacilitiesViewPath.path,
        uniqueKey: AddNewFacilitiesViewPath.key,
    },

];
