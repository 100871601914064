import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import ImprintBg from "assets/images/bg/imprint-bg.png";
import { AppContainerWithBackground } from "components";
import { AppFooter } from "layouts";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    FULL_HEIGHT_OF_DIALOG_WITHOUT_FOOTER_AND_HEADER,
    FULL_VIEW_HEIGHT_WITHOUT_HEADER,
} from "theme";
import { ImprintFirstContainer, ImprintSecondContainer } from "./Partials";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: FULL_VIEW_HEIGHT_WITHOUT_HEADER,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "50px",
        },
        body: {
            marginTop: theme.spacing(2),
            width: "100%",
            maxWidth: "1100px",
        },

        headerContainer: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(8),
        },
        locationMaker: { width: "20px", height: "20px" },
        mapSection: {
            minHeight: "400px",
            maxHeight: "400px",
            height: "400px",
            overflow: "auto",
            width: "100%",
        },
        counter: {
            fontSize: "1.5rem",
        },
    }),
);
export const Imprint = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <AppContainerWithBackground
                style={{
                    height: "1200px",
                    minHeight: FULL_HEIGHT_OF_DIALOG_WITHOUT_FOOTER_AND_HEADER,
                }}
                bgUrl={ImprintBg}>
                <div className={classes.root}>
                    <div className={classes.body}>
                        <Grid
                            container
                            alignItems="center"
                            direction="column"
                            className={classes.headerContainer}>
                            <Grid item>
                                <Typography variant="h4">
                                    {t("imprint.title")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <ImprintFirstContainer
                                    height="70px"
                                    width="70px"
                                    textProps={{
                                        color: "textSecondary",
                                        className: classes.counter,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <ImprintSecondContainer
                                    height="70px"
                                    width="70px"
                                    textProps={{
                                        color: "textSecondary",
                                        className: classes.counter,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </AppContainerWithBackground>
            <AppFooter></AppFooter>
        </>
    );
};

export default Imprint;
