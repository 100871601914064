import {
    Button,
    ButtonProps,
    createStyles,
    makeStyles,
    StandardProps,
    Theme,
    Typography,
    withStyles,
} from "@material-ui/core";
import { StyleClassKey } from "globals";
import React from "react";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            borderRadius: theme.spacing(1.2),
        },
    });

type AppButtonClassKey = StyleClassKey<typeof styles>;
const useStyles = makeStyles(styles);
export interface AppButtonProps
    extends StandardProps<ButtonProps, AppButtonClassKey> {
    text?: string;
    customBackgroundColor?: string;
    customTextColor?: string;
    customHoverBackgroundColor?: string;
    customButtonActiveBackgroundColor?: string;
    children?: React.ReactNode;
}

export const AppButton = React.memo<AppButtonProps>(
    ({
        text,
        customBackgroundColor,
        customButtonActiveBackgroundColor,
        customHoverBackgroundColor,
        customTextColor,
        children,
        ...rest
    }) => {
        const classes = useStyles(rest);

        const CustomButton = withStyles({
            root: {
                color: customTextColor,
                height: "56px",
                backgroundColor: customBackgroundColor,
                "&:hover": {
                    backgroundColor: customHoverBackgroundColor,
                },
            },
        })(Button);

        return (
            <CustomButton className={classes.root} {...rest}>
                {text && <Typography variant="button">{text}</Typography>}
                {children && children}
            </CustomButton>
        );
    },
);

export default AppButton;
