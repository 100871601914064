export const KindergartenDeTranslations = {
    kindergarten: {
        step1: {
            title: "Willkommen auf dem Kitaplatz!",
            findYourFacility: "Finden Sie Ihren Kindergarten",
            text: "Willkommen !!! Mit “kitaplatz.de” können Sie zentral Ihre Anmeldungen verwalten. Zusätzlich erhalten Sie einen schnellen und übersichtlichen Überblick eine mögliche Platzvergabe in Ihrer Einrichtung.",
        },
        step2: {
            title: "Registrieren Sie ihre Einrichtung auf KitaPlatz",
            text: "Suchen Sie Ihre Einrichtung über die Adresseingabe. Sollte Ihre Einrichtung noch nicht vergemerkt sein, fügen Sie einfach Ihren Kindergarten hinzu.",
            search: {
                posctcode: "Postleitzahl",
                facilityName: "Kindergarten Name",
                search: "Suche",
            },
            table: {
                found: "Gefunden",
                results: "Resultate",
                noRecords: "Es gibt keine Datensätze zum Anzeigen",
                toastSuccess: "Schon registriert",
            },
            addNewKindergarten: "Kindergarten hinzufügen",
        },
        step3: {
            title: "Sie sind noch nicht registriert",
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.DE",
            owner: "Sie sind Besitzer",
            manager: "Sie sind Manager",
        },
        step4: {
            title: "Registrierung",
            text1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harumDE",
            fullName: {
                placeHolder: "Voller Name",
                missing: "Voller Name fehlt",
            },
            email: {
                placeHolder: "E-Mail",
                missing: "E-Mail fehlt",
            },
            phone: {
                placeHolder: "Telefon",
                missing: "Telefon fehlt",
            },
            registration: "Registrierung",
            text2: "Mit absenden des Formulars akzeptiere ich die",
            text2Blue: "Datenschutzzbestimmung",
        },
        step5: {
            title1: "Sie haben sich erfolgreich",
            title2Green: "registriert!",
            text: "Checken Sie Ihre E-Mail für die Kontoaktivierung.",
            goToHome: "Gehe zu Home!!",
        },
    },
    addNewKindergarten: {
        title1: "Kindergarten hinzufügen",
        title2: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi",
        firstName: {
            title: "Vorname",
            missing: "Vorname fehlt",
        },
        lastName: {
            title: "Nachname",
            missing: "Nachname fehlt",
        },
        kindergartenName: {
            title: "Kindergarten Name",
            missing: "Kindergarten Name fehlt",
        },
        postalCode: {
            title: "Postleitzahl",
            missing: "Postleitzahl fehlt",
        },
        city: {
            title: "Stadt",
            missing: "Stadt fehlt",
        },
        phone: {
            title: "Telefon",
            missing: "Telefon fehlt",
        },
        companyId: {
            title: "Firma Id",
            missing: "Die Firmen Id fehlt",
        },
        country: {
            title: "Land",
            missing: "Land fehlt",
        },
        email: {
            title: "E-Mail Adresse",
            missing: "E-Mail Adresse fehlt",
            invalid: "E-Mail Adresse ist ungültig",
        },
        address: {
            title: "Adresse",
            missing: "Adresse fehlt",
        },
        registerPerson: {
            title: {
                owner: "Eigentümer",
                manager: "Manager",
            },
            required: "Unternehmen Rolle fehlt",
        },
        submit: "Bearbeiten",
        toastSuccess: "Neuer Kindergarten wurde angelegt!!",
        toastError: "Adresse ist nicht mit Postleitzahl verbunden!!",
    },
    addPasswordView: {
        toastSuccess: "Sie sind registriert!",
    }
};
export default KindergartenDeTranslations;
