import { Typography, TypographyProps } from "@material-ui/core";
import React, { CSSProperties } from "react";
import { COLOR_Light_BLUE } from "theme";

export interface AppCircleWithCounterProps extends CSSProperties {
    text: string;
    textProps?: TypographyProps;
}
export const AppCircleWithCounter: React.FC<AppCircleWithCounterProps> = ({
    text,
    textProps,
    ...props
}) => {
    return (
        <div
            style={{
                background: COLOR_Light_BLUE,
                width: "80px",
                textAlign: "center",
                borderRadius: "50%",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ...props,
            }}>
            <Typography variant="h6" {...textProps}>
                {text}
            </Typography>
        </div>
    );
};

export default AppCircleWithCounter;
