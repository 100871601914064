import axios from "axios";
import Cookies from "js-cookie";
import { apiRoutesForContext } from "routing/react-api-route-paths";
import { AddNewFacilitiesViewForm, ChangeStatus, ConfirmationAndSetPasswordFacilities, FacilitiesProfileUpdateRequest, MultiStepRegistrationFacilities, SearchFacilitiesRequest } from "sdk";
import { BaseService } from "services/BaseService";

const tmp = apiRoutesForContext();
export class FacilitiesService extends BaseService {
    public authRoutes: typeof tmp;

    constructor() {
        super();
        this.authRoutes = apiRoutesForContext();
    }

    getKindergartensFacilitiesByZipAndName(data: SearchFacilitiesRequest): Promise<any> {
        console.log(data)
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.map().getKindergartens() + "?takeAll=false&zip=" + data.zip + "&KindergartenName=" + data.name + "&showUnregisteredOnly=true&pageSize=20&page=1",
            data: data
        })
    }

    registerFacilities(data: MultiStepRegistrationFacilities): Promise<any> {
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.facilities().registerFacilities(),
            data: data
        })
    }

    getChildrenFromSameFacilities(): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.facilities().getChildrenFromSameFacilities(),
        })
    }
    changeSatus(data: ChangeStatus): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.facilities().changeStatus(),
            data: data
        })
    }
    getFacilitiesProfile(): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.facilities().getFacilitiesProfile(),
        })
    }
    updateFacilitiesProfile(data: FacilitiesProfileUpdateRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "put",
            data: data,
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.facilities().updateFacilitiesProfile(),
        })
    }
    updateFacilitiesProfileImage(data: FormData): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            data: data,
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${token}` },
            url: this.routes.facilities().updateFacilitiesProfileImage(),
        })
    }
    confirmationAndSetPassword(data: ConfirmationAndSetPasswordFacilities): Promise<any> {
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.facilities().confirmationAndSetPassword(),
            data: data
        })
    }
    addNewFacilitiesView(data: AddNewFacilitiesViewForm): Promise<any> {
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.facilities().addNewFacilitiesView(),
            data: data
        })
    }
}
