import { AppStepperFooterProps } from "components";
import { CareHours, Gender } from "globals/Types";
import moment, { Moment } from "moment";

export interface RegistrationForChildren {
    children: RegistrationForChildrenStep1[];
}

export interface RegistrationForChildrenStep1 {
    FirstNameChild: string;
    LastNameChild: string;
    Gender: Gender | null;
    BirthdayOfTheChild: Moment;
    SupportStart: Moment;
    CareHours: CareHours | null;
    Message: string;
    AttachedFile?
    : File[] | null;
}

export interface RegistrationForChildrenStepperCommonProps
    extends AppStepperFooterProps {
    handleNext: () => void;
    handleStateSet: (value: RegistrationForChildren) => void;
    parentState: RegistrationForChildren;
    handleFormCompleted: (data: RegistrationForChildren) => void;
}

export const getRegistrationForChildrenInitialValue =
    (): RegistrationForChildren => {
        return {
            children: [getInitialChild()],
        };
    };

export const getInitialChild = (): RegistrationForChildrenStep1 => {
    return {
        BirthdayOfTheChild: moment(),
        CareHours: null,
        FirstNameChild: "",
        Gender: null,
        LastNameChild: "",
        SupportStart: moment(),
        Message: "",
        AttachedFile: null,
    };
};
