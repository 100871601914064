import {
    createStyles,
    Dialog,
    DialogProps,
    makeStyles,
    Slide,
    StandardProps,
    Theme,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { AppContainerWithBackground } from "components";
import { AppDialogHeader } from "components/Dialog";
import { StyleClassKey } from "globals";
import React from "react";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const styles = (theme: Theme) =>
    createStyles({
        root: {},
        container: {
            width: "100%",
            overflow: "auto",
        },
        header: { padding: "0 10%  0 10%" },
    });

type AppDialogClassKey = StyleClassKey<typeof styles>;
const useStyles = makeStyles(styles);
export interface AppDialogProps
    extends StandardProps<DialogProps, AppDialogClassKey> {
    withBackgroundImage?: boolean;
    bgImageUrl?: string;
    bgImageHeight?: string;
    handleClose: () => void;
    dialogHeaderRightChildren?: React.ReactNode;
}

export const AppDialog = React.memo<AppDialogProps>(
    ({
        children,
        withBackgroundImage,
        bgImageHeight = "100vh",
        bgImageUrl,
        dialogHeaderRightChildren,
        handleClose,
        ...rest
    }) => {
        const classes = useStyles(rest);
        return (
            <Dialog
                fullScreen
                TransitionComponent={Transition}
                onClose={handleClose}
                {...rest}>
                {withBackgroundImage ? (
                    <AppContainerWithBackground
                        bgUrl={bgImageUrl ? bgImageUrl : ""}
                        height={bgImageHeight}>
                        <div className={classes.root}>
                            <AppDialogHeader
                                className={classes.header}
                                handleClose={handleClose}>
                                {dialogHeaderRightChildren}
                            </AppDialogHeader>
                            <div className={classes.container}>{children}</div>
                        </div>
                    </AppContainerWithBackground>
                ) : (
                    <div className={classes.root}>
                        <AppDialogHeader
                            className={classes.header}
                            handleClose={handleClose}>
                            {dialogHeaderRightChildren}
                        </AppDialogHeader>
                        <div className={classes.container}>{children}</div>
                    </div>
                )}
            </Dialog>
        );
    },
);
